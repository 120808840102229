import { tokens, networks } from "./NetworkInfo";

const networkTokenMap = {
  "polygon": "MATIC",
  "avalanche": "AVAX",
  "bnbchain": "BNB",
  "ethereum": "ETH",
  "arbitrum": "ARB",
  "linea": "ETH",
  "ethereum": "USDT"
  // ... Add other network-token mappings
};

const tokenNetworkMap = tokens.reduce((acc, token) => {
  const network = networks.find(n => n.chainId === token.chainId);
  if (network) {
    acc[token.symbol] = network.name;
  }
  return acc;
}, {});

export const updateNetworkForToken = (tokenSymbol, setSelectedNetwork) => {
  const networkName = tokenNetworkMap[tokenSymbol];
  if (networkName) {
    setSelectedNetwork(networkName);
  }
};

export const updateTokenForNetwork = (networkName, setSelectedToken) => {
  const tokenSymbol = networkTokenMap[networkName];
  if (tokenSymbol) {
    setSelectedToken(tokenSymbol);
  }
};

export default { updateNetworkForToken, updateTokenForNetwork }