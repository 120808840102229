import { useEffect, useState } from 'react';

const useFetchXGTData = () => {
  const [xgtData, setXgtData] = useState({
    price: 'Loading...',
    marketCap: 'Loading...',
    circulatingSupply: 'Loading...',
  });

  const fetchData = async () => {
    const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=xion-finance&order=market_cap_asc,volume_asc&per_page=100&page=1&sparkline=false&price_change_percentage=1h&locale=en';
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      if (data && data.length > 0) {
        const coinData = data[0]; // Assuming the first item is the desired data
        const result = {
          price: `$${parseFloat(coinData.current_price).toFixed(5)}`, // Changed to 5 decimal places
          marketCap: `$${parseFloat(coinData.market_cap).toLocaleString()}`,
          circulatingSupply: `${parseInt(coinData.circulating_supply).toLocaleString()}`,
          timestamp: new Date().getTime() // Store the current timestamp
        };

        // Cache the fetched result in local storage
        localStorage.setItem('xgtData', JSON.stringify(result));

        return result;
      } else {
        return null; // If no data, do not update anything
      }
    } catch (error) {
      console.error("Fetch error: ", error);
      return null; // In case of error, do not update anything
    }
  };

  useEffect(() => {
    let mounted = true;

    const fetchIfNeeded = async () => {
      const cachedData = localStorage.getItem('xgtData');
      const now = new Date().getTime();

      if (cachedData) {
        const { timestamp, ...data } = JSON.parse(cachedData);
        // Check if the cached data is older than 1 hour
        if (now - timestamp < 3600000) { // 3600000 ms = 1 hour
          if (mounted) {
            setXgtData(data);
            return; // Use cached data without fetching
          }
        }
      }

      // If no valid cached data, fetch new data
      const initialData = await fetchData();
      if (mounted && initialData) {
        setXgtData(initialData);
      }
    };

    fetchIfNeeded();

    return () => {
      mounted = false; // Avoids setting state on unmounted component
    };
  }, []);

  return xgtData;
};

export default useFetchXGTData;
