import React from "react";
import "./styles.css";

function NFTFailedPopup({ onClose }) {
  let message = "REFRESH & TRY AGAIN";

  // Define your support URL here
  const supportUrl = "https://t.me/xionfinancedefi";

  return (
    <div className="nftfdpopup">
      <div className="div">
        <div className="contact-details">
          <div className="transaction-needs">{message}</div>
          <div className="failed-reason"> We encountered an error which could be caused by a congested network or low gas fee.
            <br /> <br />
            Check your wallet to see more details about the error or <a href={supportUrl} target="_blank" rel="noopener noreferrer">contact support</a>.
          </div>

        </div>
        <img className="group" alt="Group" src="/img/failedtick.gif" />
      </div>
    </div>
  );
}

export default NFTFailedPopup;