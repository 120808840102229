import React, { useState, useEffect } from "react";
import TokenBox from '../../components/TokenBox';
import "../../screens/Desktop/Desktop.css";
import { useChainId } from '@thirdweb-dev/react';

// Token images
const tokenImages = {
  ETH: "/img/ethlogo.png",
  MATIC: "/img/poly.svg",
  BNB: "/img/bnblogo.png",
  ARB: "/img/arbitrum.png",
  AVAX: "/img/avallogo.png",
  OP: "/img/oplogo.png",
  USDT: "/img/usdtlogo.png",
 // axlETH: "/img/ethlogo.png",
 // WBTC: "img/wbtc.png",
};

function TokenComponent(props) {
  const { initialSelectedToken, onTokenChange, tokenBoxData, tokens, onNetworkChange } = props;
  const chainId = useChainId();
  const [selectedToken, setSelectedToken] = useState(initialSelectedToken || "MATIC");
  const [isTokenBoxOpen, setIsTokenBoxOpen] = useState(false);
  const tokenImage = tokenImages[selectedToken];
  const [selectedTokenAddress, setSelectedTokenAddress] = useState(''); // State to store selected token address

  // Mapping chain IDs to their native tokens
  const chainIdToToken = {
    1: ["ETH"],
    137: ["MATIC"], // Polygon
    43114: ["AVAX"], // Avalanche
    56: ["BNB"], // BNB Chain
    10: ["OP"], // Optimism
    42161: ["ARB"], // Arbitrum
    59144: ["ETH"],
    // Add other chain IDs and their tokens here
  };

  useEffect(() => {
    // Adjust the effect to handle multiple tokens
    const nativeTokens = chainIdToToken[chainId] || [];
    if (nativeTokens.length && !nativeTokens.includes(selectedToken)) {
      // Example strategy: select the first token in the array by default
      const defaultToken = nativeTokens[0];
      setSelectedToken(defaultToken);
      const network = getNetworkForToken(defaultToken);
      if (network && typeof onNetworkChange === 'function') {
        onNetworkChange(network);
      }
    }
  }, [chainId, selectedToken, onNetworkChange]);



  const handleTokenBoxClose = () => {
    setIsTokenBoxOpen(false);
  };

  const handleTokenComponentClick = () => {
    setIsTokenBoxOpen(!isTokenBoxOpen);
  };

  const handleTokenSelect = (tokenSymbol, tokenAddress) => {
    setSelectedToken(tokenSymbol, tokenAddress);
    if (typeof onTokenChange === 'function') {
      onTokenChange(tokenSymbol, tokenAddress);
      console.log("TokenComponent Selected Token Address:", tokenAddress);
    }
    // New logic to update the token address in the ancestor component
    if (typeof props.onTokenAddressChange === 'function') {
      props.onTokenAddressChange(tokenAddress);
    }
    // Update the network based on the selected token
    const network = getNetworkForToken(tokenSymbol);
    if (network && typeof onNetworkChange === 'function') {
      onNetworkChange(network);
    }
    setIsTokenBoxOpen(false);
  };


  const getNetworkForToken = (tokenSymbol) => {
    if (tokenSymbol === "ETH") {
      return "ethereum";
    } else if (tokenSymbol === "MATIC") {
      return "polygon";
    } else if (tokenSymbol === "BNB") {
      return "bnbchain";
    } else if (tokenSymbol === "AVAX") {
      return "avalanche";
    } else if (tokenSymbol === "ARB") {
      return "arbitrum";
    } else if (tokenSymbol === "OP") {
      return "optimism";
    } else if (tokenSymbol === "axlETH") {
      return "linea";
    }

    // Add other token-to-network mappings as needed
    return null;
  };

  return (
    <>
      {isTokenBoxOpen && (
        <TokenBox
          {...tokenBoxData}
          onClose={handleTokenBoxClose}
          onTokenChange={handleTokenSelect}
          selectedToken={selectedToken}
          tokens={tokens} // Pass the fetched and transformed tokens as a prop
          tokenAddress={selectedTokenAddress}
        />
      )}
      <div className="frame-3403" onClick={handleTokenComponentClick}>
        <img className="path-529" src={tokenImage} alt={selectedToken} />
        <div className="token titilliumweb-bold-havelock-blue-14px">{selectedToken}</div>
        <img className="path-530" src="/img/path-530-2@2x.png" alt="Path 530" />
      </div>
    </>
  );
}

export default TokenComponent;