import React, { useState, useEffect } from "react";
import "./MenuBox.css";

function MenuBox({ className, setActiveBox, activeBoxProp }) {
  const [activeTab, setActiveTab] = useState('SWAP+BRIDGE');

  useEffect(() => {
    // Update the internal activeTab state when activeBoxProp changes
    if (activeBoxProp) {
      setActiveTab(activeBoxProp.toUpperCase());
    }
  }, [activeBoxProp]);

  const handleClick = (tabName) => {
    console.log(`User clicked on ${tabName}`);
    setActiveTab(tabName);
    setActiveBox(tabName.toLowerCase());
  }

  return (
    <div className={`menu-box ${className || ""}`}>
      <div className="frame-15">
        <div className="group-259" onClick={() => handleClick('SWAP+BRIDGE')}>
          <div className={`overlap-group-3 ${activeTab === 'SWAP+BRIDGE' ? 'w-sb-line' : ''}`}>
            <div className={`swapbridge bebasneuepro-bold-cerulean-29px ${activeTab === 'SWAP+BRIDGE' ? 'active' : 'inactive'}`}>SWAP+BRIDGE</div>
          </div>
        </div>
        <div className="group-259" onClick={() => handleClick('BUY')}>
          <div className={`overlap-group-3 ${activeTab === 'BUY' ? 'w-sb-line' : ''}`}>
            <div className={`buy bebasneuepro-bold-cerulean-29px ${activeTab === 'BUY' ? 'active' : 'inactive'}`}>BUY</div>
          </div>
        </div>
        <div className="group-259" onClick={() => handleClick('WITHDRAW')}>
          <div className={`overlap-group-3 ${activeTab === 'WITHDRAW' ? 'w-sb-line' : ''}`}>
            <div className={`withdraw bebasneuepro-bold-cerulean-29px ${activeTab === 'WITHDRAW' ? 'active' : 'inactive'}`}>WITHDRAW</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuBox;
