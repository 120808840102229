import { _ as _defineProperty } from './defineProperty-d7c057bf.browser.esm.js';
import { Contract, providers, utils } from 'ethers';
import EventEmitter from 'eventemitter3';
import { isNativeToken, fetchCurrencyValue, normalizePriceValue, getDefaultGasOverrides, NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { i as isTwUrl } from './url-a45219bd.browser.esm.js';

const ERC20WithDecimalsAbi = [{
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "owner",
    type: "address"
  }, {
    indexed: true,
    internalType: "address",
    name: "spender",
    type: "address"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "value",
    type: "uint256"
  }],
  name: "Approval",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "from",
    type: "address"
  }, {
    indexed: true,
    internalType: "address",
    name: "to",
    type: "address"
  }, {
    indexed: false,
    internalType: "uint256",
    name: "value",
    type: "uint256"
  }],
  name: "Transfer",
  type: "event"
}, {
  inputs: [{
    internalType: "address",
    name: "owner",
    type: "address"
  }, {
    internalType: "address",
    name: "spender",
    type: "address"
  }],
  name: "allowance",
  outputs: [{
    internalType: "uint256",
    name: "",
    type: "uint256"
  }],
  stateMutability: "view",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "spender",
    type: "address"
  }, {
    internalType: "uint256",
    name: "value",
    type: "uint256"
  }],
  name: "approve",
  outputs: [{
    internalType: "bool",
    name: "",
    type: "bool"
  }],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "who",
    type: "address"
  }],
  name: "balanceOf",
  outputs: [{
    internalType: "uint256",
    name: "",
    type: "uint256"
  }],
  stateMutability: "view",
  type: "function"
}, {
  inputs: [],
  name: "totalSupply",
  outputs: [{
    internalType: "uint256",
    name: "",
    type: "uint256"
  }],
  stateMutability: "view",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "to",
    type: "address"
  }, {
    internalType: "uint256",
    name: "value",
    type: "uint256"
  }],
  name: "transfer",
  outputs: [{
    internalType: "bool",
    name: "",
    type: "bool"
  }],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "from",
    type: "address"
  }, {
    internalType: "address",
    name: "to",
    type: "address"
  }, {
    internalType: "uint256",
    name: "value",
    type: "uint256"
  }],
  name: "transferFrom",
  outputs: [{
    internalType: "bool",
    name: "",
    type: "bool"
  }],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [],
  name: "decimals",
  outputs: [{
    internalType: "uint8",
    name: "",
    type: "uint8"
  }],
  stateMutability: "view",
  type: "function"
}];

function createErc20(provider, currencyAddress) {
  return new Contract(currencyAddress, ERC20WithDecimalsAbi, provider);
}

var pkg = {
	name: "@thirdweb-dev/wallets",
	version: "2.4.15",
	main: "dist/thirdweb-dev-wallets.cjs.js",
	module: "dist/thirdweb-dev-wallets.esm.js",
	browser: {
		"./dist/thirdweb-dev-wallets.esm.js": "./dist/thirdweb-dev-wallets.browser.esm.js"
	},
	exports: {
		".": {
			module: {
				browser: "./dist/thirdweb-dev-wallets.browser.esm.js",
				"default": "./dist/thirdweb-dev-wallets.esm.js"
			},
			"default": "./dist/thirdweb-dev-wallets.cjs.js"
		},
		"./evm": {
			module: {
				browser: "./evm/dist/thirdweb-dev-wallets-evm.browser.esm.js",
				"default": "./evm/dist/thirdweb-dev-wallets-evm.esm.js"
			},
			"default": "./evm/dist/thirdweb-dev-wallets-evm.cjs.js"
		},
		"./evm/wallets/okx": {
			module: {
				browser: "./evm/wallets/okx/dist/thirdweb-dev-wallets-evm-wallets-okx.browser.esm.js",
				"default": "./evm/wallets/okx/dist/thirdweb-dev-wallets-evm-wallets-okx.esm.js"
			},
			"default": "./evm/wallets/okx/dist/thirdweb-dev-wallets-evm-wallets-okx.cjs.js"
		},
		"./evm/wallets/base": {
			module: {
				browser: "./evm/wallets/base/dist/thirdweb-dev-wallets-evm-wallets-base.browser.esm.js",
				"default": "./evm/wallets/base/dist/thirdweb-dev-wallets-evm-wallets-base.esm.js"
			},
			"default": "./evm/wallets/base/dist/thirdweb-dev-wallets-evm-wallets-base.cjs.js"
		},
		"./evm/wallets/safe": {
			module: {
				browser: "./evm/wallets/safe/dist/thirdweb-dev-wallets-evm-wallets-safe.browser.esm.js",
				"default": "./evm/wallets/safe/dist/thirdweb-dev-wallets-evm-wallets-safe.esm.js"
			},
			"default": "./evm/wallets/safe/dist/thirdweb-dev-wallets-evm-wallets-safe.cjs.js"
		},
		"./evm/wallets/async": {
			module: {
				browser: "./evm/wallets/async/dist/thirdweb-dev-wallets-evm-wallets-async.browser.esm.js",
				"default": "./evm/wallets/async/dist/thirdweb-dev-wallets-evm-wallets-async.esm.js"
			},
			"default": "./evm/wallets/async/dist/thirdweb-dev-wallets-evm-wallets-async.cjs.js"
		},
		"./evm/wallets/frame": {
			module: {
				browser: "./evm/wallets/frame/dist/thirdweb-dev-wallets-evm-wallets-frame.browser.esm.js",
				"default": "./evm/wallets/frame/dist/thirdweb-dev-wallets-evm-wallets-frame.esm.js"
			},
			"default": "./evm/wallets/frame/dist/thirdweb-dev-wallets-evm-wallets-frame.cjs.js"
		},
		"./evm/wallets/magic": {
			module: {
				browser: "./evm/wallets/magic/dist/thirdweb-dev-wallets-evm-wallets-magic.browser.esm.js",
				"default": "./evm/wallets/magic/dist/thirdweb-dev-wallets-evm-wallets-magic.esm.js"
			},
			"default": "./evm/wallets/magic/dist/thirdweb-dev-wallets-evm-wallets-magic.cjs.js"
		},
		"./evm/wallets/rabby": {
			module: {
				browser: "./evm/wallets/rabby/dist/thirdweb-dev-wallets-evm-wallets-rabby.browser.esm.js",
				"default": "./evm/wallets/rabby/dist/thirdweb-dev-wallets-evm-wallets-rabby.esm.js"
			},
			"default": "./evm/wallets/rabby/dist/thirdweb-dev-wallets-evm-wallets-rabby.cjs.js"
		},
		"./evm/wallets/trust": {
			module: {
				browser: "./evm/wallets/trust/dist/thirdweb-dev-wallets-evm-wallets-trust.browser.esm.js",
				"default": "./evm/wallets/trust/dist/thirdweb-dev-wallets-evm-wallets-trust.esm.js"
			},
			"default": "./evm/wallets/trust/dist/thirdweb-dev-wallets-evm-wallets-trust.cjs.js"
		},
		"./evm/wallets/xdefi": {
			module: {
				browser: "./evm/wallets/xdefi/dist/thirdweb-dev-wallets-evm-wallets-xdefi.browser.esm.js",
				"default": "./evm/wallets/xdefi/dist/thirdweb-dev-wallets-evm-wallets-xdefi.esm.js"
			},
			"default": "./evm/wallets/xdefi/dist/thirdweb-dev-wallets-evm-wallets-xdefi.cjs.js"
		},
		"./evm/wallets/blocto": {
			module: {
				browser: "./evm/wallets/blocto/dist/thirdweb-dev-wallets-evm-wallets-blocto.browser.esm.js",
				"default": "./evm/wallets/blocto/dist/thirdweb-dev-wallets-evm-wallets-blocto.esm.js"
			},
			"default": "./evm/wallets/blocto/dist/thirdweb-dev-wallets-evm-wallets-blocto.cjs.js"
		},
		"./evm/wallets/coin98": {
			module: {
				browser: "./evm/wallets/coin98/dist/thirdweb-dev-wallets-evm-wallets-coin98.browser.esm.js",
				"default": "./evm/wallets/coin98/dist/thirdweb-dev-wallets-evm-wallets-coin98.esm.js"
			},
			"default": "./evm/wallets/coin98/dist/thirdweb-dev-wallets-evm-wallets-coin98.cjs.js"
		},
		"./evm/wallets/engine": {
			module: {
				browser: "./evm/wallets/engine/dist/thirdweb-dev-wallets-evm-wallets-engine.browser.esm.js",
				"default": "./evm/wallets/engine/dist/thirdweb-dev-wallets-evm-wallets-engine.esm.js"
			},
			"default": "./evm/wallets/engine/dist/thirdweb-dev-wallets-evm-wallets-engine.cjs.js"
		},
		"./evm/wallets/ethers": {
			module: {
				browser: "./evm/wallets/ethers/dist/thirdweb-dev-wallets-evm-wallets-ethers.browser.esm.js",
				"default": "./evm/wallets/ethers/dist/thirdweb-dev-wallets-evm-wallets-ethers.esm.js"
			},
			"default": "./evm/wallets/ethers/dist/thirdweb-dev-wallets-evm-wallets-ethers.cjs.js"
		},
		"./evm/wallets/onekey": {
			module: {
				browser: "./evm/wallets/onekey/dist/thirdweb-dev-wallets-evm-wallets-onekey.browser.esm.js",
				"default": "./evm/wallets/onekey/dist/thirdweb-dev-wallets-evm-wallets-onekey.esm.js"
			},
			"default": "./evm/wallets/onekey/dist/thirdweb-dev-wallets-evm-wallets-onekey.cjs.js"
		},
		"./evm/wallets/signer": {
			module: {
				browser: "./evm/wallets/signer/dist/thirdweb-dev-wallets-evm-wallets-signer.browser.esm.js",
				"default": "./evm/wallets/signer/dist/thirdweb-dev-wallets-evm-wallets-signer.esm.js"
			},
			"default": "./evm/wallets/signer/dist/thirdweb-dev-wallets-evm-wallets-signer.cjs.js"
		},
		"./evm/wallets/zerion": {
			module: {
				browser: "./evm/wallets/zerion/dist/thirdweb-dev-wallets-evm-wallets-zerion.browser.esm.js",
				"default": "./evm/wallets/zerion/dist/thirdweb-dev-wallets-evm-wallets-zerion.esm.js"
			},
			"default": "./evm/wallets/zerion/dist/thirdweb-dev-wallets-evm-wallets-zerion.cjs.js"
		},
		"./evm/wallets/aws-kms": {
			module: {
				browser: "./evm/wallets/aws-kms/dist/thirdweb-dev-wallets-evm-wallets-aws-kms.browser.esm.js",
				"default": "./evm/wallets/aws-kms/dist/thirdweb-dev-wallets-evm-wallets-aws-kms.esm.js"
			},
			"default": "./evm/wallets/aws-kms/dist/thirdweb-dev-wallets-evm-wallets-aws-kms.cjs.js"
		},
		"./evm/wallets/gcp-kms": {
			module: {
				browser: "./evm/wallets/gcp-kms/dist/thirdweb-dev-wallets-evm-wallets-gcp-kms.browser.esm.js",
				"default": "./evm/wallets/gcp-kms/dist/thirdweb-dev-wallets-evm-wallets-gcp-kms.esm.js"
			},
			"default": "./evm/wallets/gcp-kms/dist/thirdweb-dev-wallets-evm-wallets-gcp-kms.cjs.js"
		},
		"./evm/wallets/phantom": {
			module: {
				browser: "./evm/wallets/phantom/dist/thirdweb-dev-wallets-evm-wallets-phantom.browser.esm.js",
				"default": "./evm/wallets/phantom/dist/thirdweb-dev-wallets-evm-wallets-phantom.esm.js"
			},
			"default": "./evm/wallets/phantom/dist/thirdweb-dev-wallets-evm-wallets-phantom.cjs.js"
		},
		"./evm/wallets/abstract": {
			module: {
				browser: "./evm/wallets/abstract/dist/thirdweb-dev-wallets-evm-wallets-abstract.browser.esm.js",
				"default": "./evm/wallets/abstract/dist/thirdweb-dev-wallets-evm-wallets-abstract.esm.js"
			},
			"default": "./evm/wallets/abstract/dist/thirdweb-dev-wallets-evm-wallets-abstract.cjs.js"
		},
		"./evm/wallets/injected": {
			module: {
				browser: "./evm/wallets/injected/dist/thirdweb-dev-wallets-evm-wallets-injected.browser.esm.js",
				"default": "./evm/wallets/injected/dist/thirdweb-dev-wallets-evm-wallets-injected.esm.js"
			},
			"default": "./evm/wallets/injected/dist/thirdweb-dev-wallets-evm-wallets-injected.cjs.js"
		},
		"./evm/wallets/metamask": {
			module: {
				browser: "./evm/wallets/metamask/dist/thirdweb-dev-wallets-evm-wallets-metamask.browser.esm.js",
				"default": "./evm/wallets/metamask/dist/thirdweb-dev-wallets-evm-wallets-metamask.esm.js"
			},
			"default": "./evm/wallets/metamask/dist/thirdweb-dev-wallets-evm-wallets-metamask.cjs.js"
		},
		"./evm/wallets/magic-eden": {
			module: {
				browser: "./evm/wallets/magic-eden/dist/thirdweb-dev-wallets-evm-wallets-magic-eden.browser.esm.js",
				"default": "./evm/wallets/magic-eden/dist/thirdweb-dev-wallets-evm-wallets-magic-eden.esm.js"
			},
			"default": "./evm/wallets/magic-eden/dist/thirdweb-dev-wallets-evm-wallets-magic-eden.cjs.js"
		},
		"./evm/wallets/core-wallet": {
			module: {
				browser: "./evm/wallets/core-wallet/dist/thirdweb-dev-wallets-evm-wallets-core-wallet.browser.esm.js",
				"default": "./evm/wallets/core-wallet/dist/thirdweb-dev-wallets-evm-wallets-core-wallet.esm.js"
			},
			"default": "./evm/wallets/core-wallet/dist/thirdweb-dev-wallets-evm-wallets-core-wallet.cjs.js"
		},
		"./evm/wallets/private-key": {
			module: {
				browser: "./evm/wallets/private-key/dist/thirdweb-dev-wallets-evm-wallets-private-key.browser.esm.js",
				"default": "./evm/wallets/private-key/dist/thirdweb-dev-wallets-evm-wallets-private-key.esm.js"
			},
			"default": "./evm/wallets/private-key/dist/thirdweb-dev-wallets-evm-wallets-private-key.cjs.js"
		},
		"./evm/connectors/okx": {
			module: {
				browser: "./evm/connectors/okx/dist/thirdweb-dev-wallets-evm-connectors-okx.browser.esm.js",
				"default": "./evm/connectors/okx/dist/thirdweb-dev-wallets-evm-connectors-okx.esm.js"
			},
			"default": "./evm/connectors/okx/dist/thirdweb-dev-wallets-evm-connectors-okx.cjs.js"
		},
		"./evm/wallets/local-wallet": {
			module: {
				browser: "./evm/wallets/local-wallet/dist/thirdweb-dev-wallets-evm-wallets-local-wallet.browser.esm.js",
				"default": "./evm/wallets/local-wallet/dist/thirdweb-dev-wallets-evm-wallets-local-wallet.esm.js"
			},
			"default": "./evm/wallets/local-wallet/dist/thirdweb-dev-wallets-evm-wallets-local-wallet.cjs.js"
		},
		"./evm/wallets/paper-wallet": {
			module: {
				browser: "./evm/wallets/paper-wallet/dist/thirdweb-dev-wallets-evm-wallets-paper-wallet.browser.esm.js",
				"default": "./evm/wallets/paper-wallet/dist/thirdweb-dev-wallets-evm-wallets-paper-wallet.esm.js"
			},
			"default": "./evm/wallets/paper-wallet/dist/thirdweb-dev-wallets-evm-wallets-paper-wallet.cjs.js"
		},
		"./evm/wallets/smart-wallet": {
			module: {
				browser: "./evm/wallets/smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-smart-wallet.browser.esm.js",
				"default": "./evm/wallets/smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-smart-wallet.esm.js"
			},
			"default": "./evm/wallets/smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-smart-wallet.cjs.js"
		},
		"./evm/connectors/safe": {
			module: {
				browser: "./evm/connectors/safe/dist/thirdweb-dev-wallets-evm-connectors-safe.browser.esm.js",
				"default": "./evm/connectors/safe/dist/thirdweb-dev-wallets-evm-connectors-safe.esm.js"
			},
			"default": "./evm/connectors/safe/dist/thirdweb-dev-wallets-evm-connectors-safe.cjs.js"
		},
		"./evm/connectors/frame": {
			module: {
				browser: "./evm/connectors/frame/dist/thirdweb-dev-wallets-evm-connectors-frame.browser.esm.js",
				"default": "./evm/connectors/frame/dist/thirdweb-dev-wallets-evm-connectors-frame.esm.js"
			},
			"default": "./evm/connectors/frame/dist/thirdweb-dev-wallets-evm-connectors-frame.cjs.js"
		},
		"./evm/connectors/magic": {
			module: {
				browser: "./evm/connectors/magic/dist/thirdweb-dev-wallets-evm-connectors-magic.browser.esm.js",
				"default": "./evm/connectors/magic/dist/thirdweb-dev-wallets-evm-connectors-magic.esm.js"
			},
			"default": "./evm/connectors/magic/dist/thirdweb-dev-wallets-evm-connectors-magic.cjs.js"
		},
		"./evm/connectors/paper": {
			module: {
				browser: "./evm/connectors/paper/dist/thirdweb-dev-wallets-evm-connectors-paper.browser.esm.js",
				"default": "./evm/connectors/paper/dist/thirdweb-dev-wallets-evm-connectors-paper.esm.js"
			},
			"default": "./evm/connectors/paper/dist/thirdweb-dev-wallets-evm-connectors-paper.cjs.js"
		},
		"./evm/connectors/rabby": {
			module: {
				browser: "./evm/connectors/rabby/dist/thirdweb-dev-wallets-evm-connectors-rabby.browser.esm.js",
				"default": "./evm/connectors/rabby/dist/thirdweb-dev-wallets-evm-connectors-rabby.esm.js"
			},
			"default": "./evm/connectors/rabby/dist/thirdweb-dev-wallets-evm-connectors-rabby.cjs.js"
		},
		"./evm/connectors/trust": {
			module: {
				browser: "./evm/connectors/trust/dist/thirdweb-dev-wallets-evm-connectors-trust.browser.esm.js",
				"default": "./evm/connectors/trust/dist/thirdweb-dev-wallets-evm-connectors-trust.esm.js"
			},
			"default": "./evm/connectors/trust/dist/thirdweb-dev-wallets-evm-connectors-trust.cjs.js"
		},
		"./evm/connectors/xdefi": {
			module: {
				browser: "./evm/connectors/xdefi/dist/thirdweb-dev-wallets-evm-connectors-xdefi.browser.esm.js",
				"default": "./evm/connectors/xdefi/dist/thirdweb-dev-wallets-evm-connectors-xdefi.esm.js"
			},
			"default": "./evm/connectors/xdefi/dist/thirdweb-dev-wallets-evm-connectors-xdefi.cjs.js"
		},
		"./evm/wallets/rainbow-wallet": {
			module: {
				browser: "./evm/wallets/rainbow-wallet/dist/thirdweb-dev-wallets-evm-wallets-rainbow-wallet.browser.esm.js",
				"default": "./evm/wallets/rainbow-wallet/dist/thirdweb-dev-wallets-evm-wallets-rainbow-wallet.esm.js"
			},
			"default": "./evm/wallets/rainbow-wallet/dist/thirdweb-dev-wallets-evm-wallets-rainbow-wallet.cjs.js"
		},
		"./evm/wallets/wallet-connect": {
			module: {
				browser: "./evm/wallets/wallet-connect/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect.browser.esm.js",
				"default": "./evm/wallets/wallet-connect/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect.esm.js"
			},
			"default": "./evm/wallets/wallet-connect/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect.cjs.js"
		},
		"./evm/connectors/blocto": {
			module: {
				browser: "./evm/connectors/blocto/dist/thirdweb-dev-wallets-evm-connectors-blocto.browser.esm.js",
				"default": "./evm/connectors/blocto/dist/thirdweb-dev-wallets-evm-connectors-blocto.esm.js"
			},
			"default": "./evm/connectors/blocto/dist/thirdweb-dev-wallets-evm-connectors-blocto.cjs.js"
		},
		"./evm/connectors/coin98": {
			module: {
				browser: "./evm/connectors/coin98/dist/thirdweb-dev-wallets-evm-connectors-coin98.browser.esm.js",
				"default": "./evm/connectors/coin98/dist/thirdweb-dev-wallets-evm-connectors-coin98.esm.js"
			},
			"default": "./evm/connectors/coin98/dist/thirdweb-dev-wallets-evm-connectors-coin98.cjs.js"
		},
		"./evm/connectors/onekey": {
			module: {
				browser: "./evm/connectors/onekey/dist/thirdweb-dev-wallets-evm-connectors-onekey.browser.esm.js",
				"default": "./evm/connectors/onekey/dist/thirdweb-dev-wallets-evm-connectors-onekey.esm.js"
			},
			"default": "./evm/connectors/onekey/dist/thirdweb-dev-wallets-evm-connectors-onekey.cjs.js"
		},
		"./evm/connectors/signer": {
			module: {
				browser: "./evm/connectors/signer/dist/thirdweb-dev-wallets-evm-connectors-signer.browser.esm.js",
				"default": "./evm/connectors/signer/dist/thirdweb-dev-wallets-evm-connectors-signer.esm.js"
			},
			"default": "./evm/connectors/signer/dist/thirdweb-dev-wallets-evm-connectors-signer.cjs.js"
		},
		"./evm/connectors/zerion": {
			module: {
				browser: "./evm/connectors/zerion/dist/thirdweb-dev-wallets-evm-connectors-zerion.browser.esm.js",
				"default": "./evm/connectors/zerion/dist/thirdweb-dev-wallets-evm-connectors-zerion.esm.js"
			},
			"default": "./evm/connectors/zerion/dist/thirdweb-dev-wallets-evm-connectors-zerion.cjs.js"
		},
		"./evm/wallets/coinbase-wallet": {
			module: {
				browser: "./evm/wallets/coinbase-wallet/dist/thirdweb-dev-wallets-evm-wallets-coinbase-wallet.browser.esm.js",
				"default": "./evm/wallets/coinbase-wallet/dist/thirdweb-dev-wallets-evm-wallets-coinbase-wallet.esm.js"
			},
			"default": "./evm/wallets/coinbase-wallet/dist/thirdweb-dev-wallets-evm-wallets-coinbase-wallet.cjs.js"
		},
		"./evm/wallets/embedded-wallet": {
			module: {
				browser: "./evm/wallets/embedded-wallet/dist/thirdweb-dev-wallets-evm-wallets-embedded-wallet.browser.esm.js",
				"default": "./evm/wallets/embedded-wallet/dist/thirdweb-dev-wallets-evm-wallets-embedded-wallet.esm.js"
			},
			"default": "./evm/wallets/embedded-wallet/dist/thirdweb-dev-wallets-evm-wallets-embedded-wallet.cjs.js"
		},
		"./evm/connectors/phantom": {
			module: {
				browser: "./evm/connectors/phantom/dist/thirdweb-dev-wallets-evm-connectors-phantom.browser.esm.js",
				"default": "./evm/connectors/phantom/dist/thirdweb-dev-wallets-evm-connectors-phantom.esm.js"
			},
			"default": "./evm/connectors/phantom/dist/thirdweb-dev-wallets-evm-connectors-phantom.cjs.js"
		},
		"./evm/connectors/rainbow": {
			module: {
				browser: "./evm/connectors/rainbow/dist/thirdweb-dev-wallets-evm-connectors-rainbow.browser.esm.js",
				"default": "./evm/connectors/rainbow/dist/thirdweb-dev-wallets-evm-connectors-rainbow.esm.js"
			},
			"default": "./evm/connectors/rainbow/dist/thirdweb-dev-wallets-evm-connectors-rainbow.cjs.js"
		},
		"./evm/connectors/injected": {
			module: {
				browser: "./evm/connectors/injected/dist/thirdweb-dev-wallets-evm-connectors-injected.browser.esm.js",
				"default": "./evm/connectors/injected/dist/thirdweb-dev-wallets-evm-connectors-injected.esm.js"
			},
			"default": "./evm/connectors/injected/dist/thirdweb-dev-wallets-evm-connectors-injected.cjs.js"
		},
		"./evm/connectors/metamask": {
			module: {
				browser: "./evm/connectors/metamask/dist/thirdweb-dev-wallets-evm-connectors-metamask.browser.esm.js",
				"default": "./evm/connectors/metamask/dist/thirdweb-dev-wallets-evm-connectors-metamask.esm.js"
			},
			"default": "./evm/connectors/metamask/dist/thirdweb-dev-wallets-evm-connectors-metamask.cjs.js"
		},
		"./evm/wallets/local-wallet-node": {
			module: {
				browser: "./evm/wallets/local-wallet-node/dist/thirdweb-dev-wallets-evm-wallets-local-wallet-node.browser.esm.js",
				"default": "./evm/wallets/local-wallet-node/dist/thirdweb-dev-wallets-evm-wallets-local-wallet-node.esm.js"
			},
			"default": "./evm/wallets/local-wallet-node/dist/thirdweb-dev-wallets-evm-wallets-local-wallet-node.cjs.js"
		},
		"./evm/wallets/wallet-connect-v1": {
			module: {
				browser: "./evm/wallets/wallet-connect-v1/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect-v1.browser.esm.js",
				"default": "./evm/wallets/wallet-connect-v1/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect-v1.esm.js"
			},
			"default": "./evm/wallets/wallet-connect-v1/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect-v1.cjs.js"
		},
		"./evm/wallets/crypto-defi-wallet": {
			module: {
				browser: "./evm/wallets/crypto-defi-wallet/dist/thirdweb-dev-wallets-evm-wallets-crypto-defi-wallet.browser.esm.js",
				"default": "./evm/wallets/crypto-defi-wallet/dist/thirdweb-dev-wallets-evm-wallets-crypto-defi-wallet.esm.js"
			},
			"default": "./evm/wallets/crypto-defi-wallet/dist/thirdweb-dev-wallets-evm-wallets-crypto-defi-wallet.cjs.js"
		},
		"./evm/connectors/magic-eden": {
			module: {
				browser: "./evm/connectors/magic-eden/dist/thirdweb-dev-wallets-evm-connectors-magic-eden.browser.esm.js",
				"default": "./evm/connectors/magic-eden/dist/thirdweb-dev-wallets-evm-connectors-magic-eden.esm.js"
			},
			"default": "./evm/connectors/magic-eden/dist/thirdweb-dev-wallets-evm-connectors-magic-eden.cjs.js"
		},
		"./evm/wallets/aws-secrets-manager": {
			module: {
				browser: "./evm/wallets/aws-secrets-manager/dist/thirdweb-dev-wallets-evm-wallets-aws-secrets-manager.browser.esm.js",
				"default": "./evm/wallets/aws-secrets-manager/dist/thirdweb-dev-wallets-evm-wallets-aws-secrets-manager.esm.js"
			},
			"default": "./evm/wallets/aws-secrets-manager/dist/thirdweb-dev-wallets-evm-wallets-aws-secrets-manager.cjs.js"
		},
		"./evm/connectors/core-wallet": {
			module: {
				browser: "./evm/connectors/core-wallet/dist/thirdweb-dev-wallets-evm-connectors-core-wallet.browser.esm.js",
				"default": "./evm/connectors/core-wallet/dist/thirdweb-dev-wallets-evm-connectors-core-wallet.esm.js"
			},
			"default": "./evm/connectors/core-wallet/dist/thirdweb-dev-wallets-evm-connectors-core-wallet.cjs.js"
		},
		"./evm/connectors/local-wallet": {
			module: {
				browser: "./evm/connectors/local-wallet/dist/thirdweb-dev-wallets-evm-connectors-local-wallet.browser.esm.js",
				"default": "./evm/connectors/local-wallet/dist/thirdweb-dev-wallets-evm-connectors-local-wallet.esm.js"
			},
			"default": "./evm/connectors/local-wallet/dist/thirdweb-dev-wallets-evm-connectors-local-wallet.cjs.js"
		},
		"./evm/connectors/smart-wallet": {
			module: {
				browser: "./evm/connectors/smart-wallet/dist/thirdweb-dev-wallets-evm-connectors-smart-wallet.browser.esm.js",
				"default": "./evm/connectors/smart-wallet/dist/thirdweb-dev-wallets-evm-connectors-smart-wallet.esm.js"
			},
			"default": "./evm/connectors/smart-wallet/dist/thirdweb-dev-wallets-evm-connectors-smart-wallet.cjs.js"
		},
		"./evm/connectors/wallet-connect": {
			module: {
				browser: "./evm/connectors/wallet-connect/dist/thirdweb-dev-wallets-evm-connectors-wallet-connect.browser.esm.js",
				"default": "./evm/connectors/wallet-connect/dist/thirdweb-dev-wallets-evm-connectors-wallet-connect.esm.js"
			},
			"default": "./evm/connectors/wallet-connect/dist/thirdweb-dev-wallets-evm-connectors-wallet-connect.cjs.js"
		},
		"./evm/connectors/coinbase-wallet": {
			module: {
				browser: "./evm/connectors/coinbase-wallet/dist/thirdweb-dev-wallets-evm-connectors-coinbase-wallet.browser.esm.js",
				"default": "./evm/connectors/coinbase-wallet/dist/thirdweb-dev-wallets-evm-connectors-coinbase-wallet.esm.js"
			},
			"default": "./evm/connectors/coinbase-wallet/dist/thirdweb-dev-wallets-evm-connectors-coinbase-wallet.cjs.js"
		},
		"./evm/connectors/embedded-wallet": {
			module: {
				browser: "./evm/connectors/embedded-wallet/dist/thirdweb-dev-wallets-evm-connectors-embedded-wallet.browser.esm.js",
				"default": "./evm/connectors/embedded-wallet/dist/thirdweb-dev-wallets-evm-connectors-embedded-wallet.esm.js"
			},
			"default": "./evm/connectors/embedded-wallet/dist/thirdweb-dev-wallets-evm-connectors-embedded-wallet.cjs.js"
		},
		"./evm/wallets/token-bound-smart-wallet": {
			module: {
				browser: "./evm/wallets/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-token-bound-smart-wallet.browser.esm.js",
				"default": "./evm/wallets/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-token-bound-smart-wallet.esm.js"
			},
			"default": "./evm/wallets/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-token-bound-smart-wallet.cjs.js"
		},
		"./evm/connectors/wallet-connect-v1": {
			module: {
				browser: "./evm/connectors/wallet-connect-v1/dist/thirdweb-dev-wallets-evm-connectors-wallet-connect-v1.browser.esm.js",
				"default": "./evm/connectors/wallet-connect-v1/dist/thirdweb-dev-wallets-evm-connectors-wallet-connect-v1.esm.js"
			},
			"default": "./evm/connectors/wallet-connect-v1/dist/thirdweb-dev-wallets-evm-connectors-wallet-connect-v1.cjs.js"
		},
		"./evm/connectors/crypto-defi-wallet": {
			module: {
				browser: "./evm/connectors/crypto-defi-wallet/dist/thirdweb-dev-wallets-evm-connectors-crypto-defi-wallet.browser.esm.js",
				"default": "./evm/connectors/crypto-defi-wallet/dist/thirdweb-dev-wallets-evm-connectors-crypto-defi-wallet.esm.js"
			},
			"default": "./evm/connectors/crypto-defi-wallet/dist/thirdweb-dev-wallets-evm-connectors-crypto-defi-wallet.cjs.js"
		},
		"./evm/connectors/token-bound-smart-wallet": {
			module: {
				browser: "./evm/connectors/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-connectors-token-bound-smart-wallet.browser.esm.js",
				"default": "./evm/connectors/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-connectors-token-bound-smart-wallet.esm.js"
			},
			"default": "./evm/connectors/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-connectors-token-bound-smart-wallet.cjs.js"
		},
		"./evm/connectors/embedded-wallet/implementations": {
			module: {
				browser: "./evm/connectors/embedded-wallet/implementations/dist/thirdweb-dev-wallets-evm-connectors-embedded-wallet-implementations.browser.esm.js",
				"default": "./evm/connectors/embedded-wallet/implementations/dist/thirdweb-dev-wallets-evm-connectors-embedded-wallet-implementations.esm.js"
			},
			"default": "./evm/connectors/embedded-wallet/implementations/dist/thirdweb-dev-wallets-evm-connectors-embedded-wallet-implementations.cjs.js"
		},
		"./package.json": "./package.json"
	},
	types: "dist/thirdweb-dev-wallets.cjs.d.ts",
	repository: "https://github.com/thirdweb-dev/js/tree/main/packages/wallets",
	license: "Apache-2.0",
	bugs: {
		url: "https://github.com/thirdweb-dev/js/issues"
	},
	author: "thirdweb eng <eng@thirdweb.com>",
	files: [
		"dist/",
		"evm/"
	],
	preconstruct: {
		entrypoints: [
			"index.ts",
			"evm/index.ts",
			"evm/connectors/*/index.ts",
			"evm/connectors/embedded-wallet/implementations/index.ts",
			"evm/wallets/**"
		],
		exports: {
			envConditions: [
				"browser"
			]
		}
	},
	sideEffects: false,
	dependencies: {
		"@account-abstraction/contracts": "^0.5.0",
		"@blocto/sdk": "^0.5.4",
		"@coinbase/wallet-sdk": "^3.7.1",
		"@google-cloud/kms": "3.0.1",
		"@magic-ext/connect": "^6.7.2",
		"@magic-ext/oauth": "^7.6.2",
		"@magic-sdk/provider": "^13.6.2",
		"@metamask/eth-sig-util": "^4.0.0",
		"@paperxyz/embedded-wallet-service-sdk": "^1.2.5",
		"@paperxyz/sdk-common-utilities": "^0.1.0",
		"@safe-global/safe-core-sdk": "^3.3.4",
		"@safe-global/safe-ethers-adapters": "0.1.0-alpha.17",
		"@safe-global/safe-ethers-lib": "^1.9.4",
		"@thirdweb-dev/chains": "workspace:*",
		"@thirdweb-dev/contracts-js": "workspace:*",
		"@thirdweb-dev/crypto": "workspace:*",
		"@thirdweb-dev/sdk": "workspace:*",
		"@walletconnect/core": "^2.9.1",
		"@walletconnect/ethereum-provider": "^2.9.1",
		"@walletconnect/jsonrpc-utils": "^1.0.8",
		"@walletconnect/modal": "^2.6.1",
		"@walletconnect/types": "^2.9.1",
		"@walletconnect/utils": "^2.10.2",
		"@walletconnect/web3wallet": "^1.8.7",
		"asn1.js": "5.4.1",
		"bn.js": "5.2.0",
		buffer: "^6.0.3",
		"eth-provider": "^0.13.6",
		"ethereumjs-util": "^7.1.3",
		eventemitter3: "^5.0.1",
		"key-encoder": "2.0.3",
		"magic-sdk": "^13.6.2",
		"web3-core": "1.5.2"
	},
	peerDependencies: {
		"@aws-sdk/client-secrets-manager": "^3.256.0",
		"@noble/ed25519": "^1.7.1",
		bs58: "^5.0.0",
		ethers: "^5.7.2",
		"ethers-aws-kms-signer": "^1.3.2",
		tweetnacl: "^1.0.3"
	},
	peerDependenciesMeta: {
		tweetnacl: {
			optional: true
		},
		"@aws-sdk/client-secrets-manager": {
			optional: true
		},
		"ethers-aws-kms-signer": {
			optional: true
		},
		"@noble/ed25519": {
			optional: true
		},
		bs58: {
			optional: true
		},
		ethers: {
			optional: true
		}
	},
	devDependencies: {
		"@aws-sdk/client-secrets-manager": "^3.378.0",
		"@babel/plugin-transform-class-properties": "^7.23.3",
		"@babel/plugin-transform-flow-strip-types": "^7.23.3",
		"@babel/plugin-transform-private-methods": "^7.23.3",
		"@microsoft/api-extractor": "^7.36.3",
		"@noble/ed25519": "^1.7.1",
		"@nomiclabs/hardhat-ethers": "^2.2.3",
		"@preconstruct/cli": "2.7.0",
		"@thirdweb-dev/tsconfig": "workspace:*",
		"@types/bn.js": "^5.1.1",
		"@types/jest": "^29.5.3",
		abitype: "^0.2.5",
		"babel-plugin-transform-inline-environment-variables": "^0.4.4",
		bs58: "^5.0.0",
		"cross-env": "^7.0.3",
		"eslint-config-thirdweb": "workspace:*",
		"eslint-plugin-better-tree-shaking": "0.0.4",
		"eslint-plugin-tsdoc": "^0.2.16",
		"ethereum-provider": "^0.7.7",
		ethers: "^5.7.2",
		"ethers-aws-kms-signer": "^1.3.2",
		hardhat: "^2.17.0",
		jest: "^29.6.2",
		rimraf: "^3.0.2",
		"ts-jest": "^29.1.1",
		tweetnacl: "^1.0.3",
		"typedoc-gen": "workspace:*",
		typescript: "^5.3.3"
	},
	scripts: {
		format: "prettier --write 'src/**/*'",
		lint: "eslint src/ && bunx publint --strict --level warning",
		fix: "eslint src/ --fix",
		clean: "rm -rf dist/",
		build: "tsc && cross-env THIRDWEB_EWS_SDK_VERSION=$npm_package_version preconstruct build",
		"preconstruct:fix": "preconstruct fix",
		push: "yalc push",
		typedoc: "node scripts/typedoc.mjs",
		test: "jest"
	},
	engines: {
		node: ">=18"
	},
	jest: {
		testTimeout: 30000
	}
};

const operatingSystemRules = [["iOS", /iP(hone|od|ad)/], ["Android OS", /Android/], ["BlackBerry OS", /BlackBerry|BB10/], ["Windows Mobile", /IEMobile/], ["Amazon OS", /Kindle/], ["Windows 3.11", /Win16/], ["Windows 95", /(Windows 95)|(Win95)|(Windows_95)/], ["Windows 98", /(Windows 98)|(Win98)/], ["Windows 2000", /(Windows NT 5.0)|(Windows 2000)/], ["Windows XP", /(Windows NT 5.1)|(Windows XP)/], ["Windows Server 2003", /(Windows NT 5.2)/], ["Windows Vista", /(Windows NT 6.0)/], ["Windows 7", /(Windows NT 6.1)/], ["Windows 8", /(Windows NT 6.2)/], ["Windows 8.1", /(Windows NT 6.3)/], ["Windows 10", /(Windows NT 10.0)/], ["Windows ME", /Windows ME/], ["Windows CE", /Windows CE|WinCE|Microsoft Pocket Internet Explorer/], ["Open BSD", /OpenBSD/], ["Sun OS", /SunOS/], ["Chrome OS", /CrOS/], ["Linux", /(Linux)|(X11)/], ["Mac OS", /(Mac_PowerPC)|(Macintosh)/], ["QNX", /QNX/], ["BeOS", /BeOS/], ["OS/2", /OS\/2/]];
function detectOS(ua) {
  for (let ii = 0, count = operatingSystemRules.length; ii < count; ii++) {
    const result = operatingSystemRules[ii];
    if (!result) {
      continue;
    }
    const [os, regex] = result;
    const match = regex.exec(ua);
    if (match) {
      return os;
    }
  }
  return null;
}

function getOperatingSystem() {
  if (typeof navigator !== "undefined" && navigator.product === "ReactNative") {
    return "";
  } else {
    const userAgent = navigator.userAgent;
    return detectOS(userAgent) || "";
  }
}

// const pkg: {
//   version: string;
//   name: string;
//   // this is on purpose because we can't import package.json as a module as it is outside rootDir
//   // eslint-disable-next-line @typescript-eslint/no-var-requires, better-tree-shaking/no-top-level-side-effects
// } = require("../../../package.json");

function setAnalyticsHeaders(headers) {
  const globals = getAnalyticsGlobals();
  headers["x-sdk-version"] = globals.x_sdk_version;
  headers["x-sdk-name"] = globals.x_sdk_name;
  headers["x-sdk-platform"] = globals.x_sdk_platform;
  headers["x-sdk-os"] = globals.x_sdk_os;
}
function getAnalyticsHeaders() {
  const globals = getAnalyticsGlobals();
  return {
    "x-sdk-version": globals.x_sdk_version,
    "x-sdk-os": globals.x_sdk_os,
    "x-sdk-name": globals.x_sdk_name,
    "x-sdk-platform": globals.x_sdk_platform,
    "x-bundle-id": globals.app_bundle_id
  };
}
function getAnalyticsGlobals() {
  if (typeof globalThis === "undefined") {
    return {
      x_sdk_name: pkg.name,
      x_sdk_platform: getPlatform(),
      x_sdk_version: pkg.version,
      x_sdk_os: getOperatingSystem(),
      app_bundle_id: undefined
    };
  }
  if (globalThis.X_SDK_NAME === undefined) {
    globalThis.X_SDK_NAME = pkg.name;
    globalThis.X_SDK_PLATFORM = getPlatform();
    globalThis.X_SDK_VERSION = pkg.version;
    globalThis.X_SDK_OS = getOperatingSystem();
    globalThis.APP_BUNDLE_ID = undefined;
  }
  return {
    x_sdk_name: globalThis.X_SDK_NAME,
    x_sdk_platform: globalThis.X_SDK_PLATFORM,
    x_sdk_version: globalThis.X_SDK_VERSION,
    x_sdk_os: globalThis.X_SDK_OS,
    app_bundle_id: globalThis.APP_BUNDLE_ID || "" // if not RN, this will be empty
  };
}
function getPlatform() {
  return typeof navigator !== "undefined" && navigator.product === "ReactNative" ? "mobile" : "browser" ;
}

// TODO improve this
function chainIdToThirdwebRpc(chainId, clientId) {
  return `https://${chainId}.rpc.thirdweb.com${clientId ? `/${clientId}` : ""}${typeof globalThis !== "undefined" && "APP_BUNDLE_ID" in globalThis ? `?bundleId=${globalThis.APP_BUNDLE_ID}` : ""}`;
}
const EIP1271_ABI = ["function isValidSignature(bytes32 _hash, bytes _signature) public view returns (bytes4)"];
const EIP1271_MAGICVALUE = "0x1626ba7e";
async function checkContractWalletSignature(message, signature, address, chainId, clientId, secretKey) {
  // TODO: remove below `skipFetchSetup` logic when ethers.js v6 support arrives
  let _skipFetchSetup = false;
  if (typeof globalThis !== "undefined" && "TW_SKIP_FETCH_SETUP" in globalThis && typeof globalThis.TW_SKIP_FETCH_SETUP === "boolean") {
    _skipFetchSetup = globalThis.TW_SKIP_FETCH_SETUP;
  }
  const rpcUrl = chainIdToThirdwebRpc(chainId, clientId);
  const headers = {};
  if (isTwUrl(rpcUrl)) {
    const bundleId = typeof globalThis !== "undefined" && "APP_BUNDLE_ID" in globalThis ? globalThis.APP_BUNDLE_ID : undefined;
    if (secretKey) {
      headers["x-secret-key"] = secretKey;
    } else if (clientId) {
      headers["x-client-id"] = clientId;
      if (bundleId) {
        headers["x-bundle-id"] = bundleId;
      }
    }

    // Dashboard token
    if (typeof globalThis !== "undefined" && "TW_AUTH_TOKEN" in globalThis && typeof globalThis.TW_AUTH_TOKEN === "string") {
      headers["authorization"] = `Bearer ${globalThis.TW_AUTH_TOKEN}`;
    }

    // CLI token
    if (typeof globalThis !== "undefined" && "TW_CLI_AUTH_TOKEN" in globalThis && typeof globalThis.TW_CLI_AUTH_TOKEN === "string") {
      headers["authorization"] = `Bearer ${globalThis.TW_CLI_AUTH_TOKEN}`;
      headers["x-authorize-wallet"] = "true";
    }
    setAnalyticsHeaders(headers);
  }
  const provider = new providers.StaticJsonRpcProvider({
    url: chainIdToThirdwebRpc(chainId),
    skipFetchSetup: _skipFetchSetup,
    headers
  }, chainId);
  const walletContract = new Contract(address, EIP1271_ABI, provider);
  try {
    const res = await walletContract.isValidSignature(utils.hashMessage(message), signature);
    return res === EIP1271_MAGICVALUE;
  } catch {
    return false;
  }
}
/**
 * The base class for any wallet in the Wallet SDK, including backend wallets. It contains the functionality common to all wallets.
 *
 * This wallet is not meant to be used directly, but instead be extended to [build your own wallet](https://portal.thirdweb.com/wallet-sdk/v2/build)
 *
 * @abstractWallet
 */
class AbstractWallet extends EventEmitter {
  constructor() {
    super(...arguments);
    /**
     * @internal
     */
    _defineProperty(this, "type", "evm");
  }
  /**
   * Returns an [ethers Signer](https://docs.ethers.org/v5/api/signer/) object of the connected wallet
   */
  /**
   * Returns the account address of the connected wallet
   */
  async getAddress() {
    const signer = await this.getSigner();
    return signer.getAddress();
  }

  /**
   * Returns the balance of the connected wallet for the specified token address. If no token address is specified, it returns the balance of the native token
   *
   * @param tokenAddress - The contract address of the token
   */
  async getBalance() {
    let tokenAddress = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : NATIVE_TOKEN_ADDRESS;
    const signer = await this.getSigner();
    const address = await this.getAddress();
    if (!signer.provider) {
      throw new Error("Please connect a provider");
    }
    let balance;
    if (isNativeToken(tokenAddress)) {
      balance = await signer.provider.getBalance(address);
    } else {
      const erc20 = createErc20(signer, tokenAddress);
      balance = await erc20.balanceOf(address);
    }

    // Note: assumes that the native currency decimals is 18, which isn't always correct
    return await fetchCurrencyValue(signer.provider, tokenAddress, balance);
  }

  /**
   * Returns the chain id of the network that the wallet is connected to
   */
  async getChainId() {
    const signer = await this.getSigner();
    return signer.getChainId();
  }

  /**
   * Transfers some amount of tokens to the specified address
   * @param to - The address to transfer the amount to
   * @param amount - The amount to transfer
   * @param currencyAddress - The contract address of the token to transfer. If not specified, it defaults to the native token
   * @returns The transaction result
   */
  async transfer(to, amount) {
    let currencyAddress = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : NATIVE_TOKEN_ADDRESS;
    const signer = await this.getSigner();
    const from = await this.getAddress();
    if (!signer.provider) {
      throw new Error("Please connect a provider");
    }
    const value = await normalizePriceValue(signer.provider, amount, currencyAddress);
    if (isNativeToken(currencyAddress)) {
      const gas = getDefaultGasOverrides(signer.provider);
      const tx = await signer.sendTransaction({
        from,
        to,
        value,
        ...gas
      });
      return {
        receipt: await tx.wait()
      };
    } else {
      const erc20 = createErc20(signer, currencyAddress);
      const tx = await erc20.transfer(to, value);
      return {
        receipt: await tx.wait()
      };
    }
  }

  /**
   * Sign a message with the connected wallet and return the signature
   * @param message - The message to sign
   * @returns - The signature
   */
  async signMessage(message) {
    const signer = await this.getSigner();
    return await signer.signMessage(message);
  }

  /**
   * Verify the signature of a message. It returns `true` if the signature is valid, `false` otherwise
   * @param message - The message to verify
   * @param signature - The signature to verify
   * @param address - The address to verify the signature against
   * @param chainId - The chain id of the network to verify the signature against, If not specified, it defaults to 1 ( Ethereum mainnet )
   */
  async verifySignature(message, signature, address, chainId, clientId, secretKey) {
    try {
      const messageHash = utils.hashMessage(message);
      const messageHashBytes = utils.arrayify(messageHash);
      const recoveredAddress = utils.recoverAddress(messageHashBytes, signature);
      if (recoveredAddress === address) {
        return true;
      }
    } catch {
      // no-op
    }

    // Check if the address is a smart contract wallet
    if (chainId !== undefined) {
      try {
        const isValid = await checkContractWalletSignature(message, signature, address, chainId || 1, clientId, secretKey);
        return isValid;
      } catch {
        // no-op
      }
    }
    return false;
  }
}

export { AbstractWallet as A, checkContractWalletSignature as a, chainIdToThirdwebRpc as c, getAnalyticsHeaders as g, setAnalyticsHeaders as s };
