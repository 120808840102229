import { _ as _defineProperty } from '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import { SmartWallet } from '../../smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-smart-wallet.browser.esm.js';
import { w as walletIds } from '../../../../dist/walletIds-99db3206.browser.esm.js';
import { a as ERC6551_REGISTRY } from '../../../../dist/constants-5a6f7f26.browser.esm.js';
import '../../../../dist/base-06242fcf.browser.esm.js';
import '@thirdweb-dev/chains';
import '../../../../dist/abstract-6d64547d.browser.esm.js';
import 'ethers';
import 'eventemitter3';
import '@thirdweb-dev/sdk';
import '../../../../dist/url-a45219bd.browser.esm.js';
import '@account-abstraction/contracts';

/**
 * A smart wallet controlled by the holder of a particular NFT.
 */

/**
 * @wallet
 */
class TokenBoundSmartWallet extends SmartWallet {
  get walletName() {
    return "Token Bound Smart Wallet";
  }
  constructor(options) {
    super({
      ...options,
      factoryAddress: options.registryAddress || ERC6551_REGISTRY
    });
    this.tbaOptions = options;
  }
  async getConnector() {
    if (!this.tbaConnector) {
      const {
        TokenBoundSmartWalletConnector
      } = await import('../../../connectors/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-connectors-token-bound-smart-wallet.browser.esm.js');
      this.tbaConnector = new TokenBoundSmartWalletConnector(this.tbaOptions);
    }
    return this.tbaConnector;
  }
}
_defineProperty(TokenBoundSmartWallet, "meta", {
  name: "Token Bound Smart Wallet",
  iconURL: "ipfs://QmeAJVqn17aDNQhjEU3kcWVZCFBrfta8LzaDGkS8Egdiyk/smart-wallet.svg"
});
_defineProperty(TokenBoundSmartWallet, "id", walletIds.tokenBoundSmartWallet);

export { TokenBoundSmartWallet };
