import React from "react";
import { useState } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import "./IPad.css";
import { BurgerMenu } from "../../../components/Burger/BurgerMenu";
import Footer from "../../../components/Footer";
import { useHistory } from 'react-router-dom'; // Import useHistory

function Error404I(props) {
  const {
    logo,
    line34,
    copyright,
  } = props;

  const history = useHistory();

  const handleMenuOptionSelect = (boxName) => {
    history.push('/', { selectedBox: boxName });
};

  const handleGoBackClick = () => {
    window.history.back(); // Go back to the previous page
  };


  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  return (
    <div className="error-i">
    <div className="error-404 screen" style={{ backgroundImage: `url("img/BlackBG.png")` }}> 
      <div className="frame-3416-2">
        <div className="nav-3">
        <a href="/">
              <img className="logo-3" src={logo} alt="LOGO" />
            </a>
            <img className="img-wrapper" alt="Burger" src="/img/Burger.png" onClick={handleBurgerMenuClick} />
          <div className="my-custom-wrapper">
            <div className="my-custom-class">
              <ConnectWallet btnTitle="Connect Wallet" theme={"light"} />
            </div>
          </div>
      <BurgerMenu isOpen={isBurgerMenuOpen} onClose={handleBurgerMenuClick} />
</div>

<div className="error-404-content">
<div className="go-back-2" onClick={handleGoBackClick}>
              <div className="go-back-2-text">Take me back!!!</div>
            </div>
        <p className="text-wrapper">The page you are looking for seems to have been lost.</p>
        <div className="overlap-group">
          <div className="div">404 ERROR</div>
          <div className="ABOUT-US">aah damn!!!</div>
          <img className="asset" alt="Asset" src="/img/asset-30-4x-8-2.png" />
        </div>
      <img className="wepik-export" alt="Wepik export" src="/img/wepik-export-20231008151734w7ut-1.png" />
      <img className="img" alt="Wepik export" src="/img/wepik-export-20231008151734w7ut-2.png" />
      <img className="asset-x" alt="Asset" src="/img/asset-30-4x-8-2.png" />
    </div>

    <Footer
                onMenuOptionSelect={handleMenuOptionSelect} />
      </div>
    </div>
    </div>
  );
}

export default Error404I;
