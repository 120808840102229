import React from "react";
import "./styles.css";
import { Link } from "react-router-dom"; // Import Link
import useFetchXGTData from "../../../FetchThirdWeb/fetchXGTData"; // Adjust the path as needed
import React, { useRef } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory

export const MobileFooter = ({
  scrollToRef,
  menuBoxRef,
  setActiveBoxFromFooter,
  onMenuOptionSelect,
}) => {
  const history = useHistory();
  const xgtData = useFetchXGTData();
  const navigateAndScroll = (boxName) => {
    if (onMenuOptionSelect) {
      onMenuOptionSelect(boxName); // Use the provided function for navigation
    } else {
      // Default behavior (as previously implemented)
      setActiveBoxFromFooter(boxName);
      if (window.location.pathname !== "/") {
        history.push("/");
      } else {
        scrollToRef(menuBoxRef);
      }
    }
  };

  const navigateToPage = (path) => {
    history.push(path);
  };

  return (
    <div className="mobile-footer-section">
      <img className="pinkline" src="/img/line-34-2.png" alt="Pink Line" />
      <div className="frame">
        <div className="group">
          <img src="/img/XFlogoMobile.svg" className="LOGO-instance" />
          <div className="div-12">
            <div className="text-wrapper-29">
              V3 - {xgtData ? xgtData.price : "Loading..."}
            </div>
            <div className="overlap-group-wrapper-65">
              <div className="overlap-group-65">
                <div className="rectangle-130" />
                <a
                  href="https://kyberswap.com/swap/polygon/usdt-to-xgtv3"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-wrapper-2"
                >
                  Buy XGT
                </a>
                <img
                  className="next-next-arrow"
                  alt="Next Arrow"
                  src="/img/next-next-arrow-right-keyboard.png"
                />
              </div>
            </div>
            <img className="imgfooter" alt="XGT Logo" src="/img/XGT.png" />
          </div>
        </div>
        <div className="sections">
          <div className="div-2">
            <div className="text-wrapper-32">PRODUCTS</div>
            <div
              onClick={() => navigateAndScroll("swap+bridge")}
              className="text-wrapper-46"
            >
              Swap & Bridge
            </div>
            <div
              onClick={() => navigateAndScroll("buy")}
              className="text-wrapper-46"
            >
              Buy Crypto With Card
            </div>
            <div
              onClick={() => navigateAndScroll("withdraw")}
              className="text-wrapper-46"
            >
              Withdraw Crypto To FIAT
            </div>
            <div
              onClick={() => navigateToPage("/nft-xion-astronauts")}
              className="text-wrapper-46"
            >
              NFT Astronauts
            </div>
          </div>
          <div className="div-2">
            <div className="text-wrapper-32">EXPLORERS</div>
            <a
              href="https://www.coingecko.com/en/coins/xion-finance"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              CoinGecko
            </a>
            <a
              href="https://coinmarketcap.com/currencies/xion-finance/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              CoinMarketCap
            </a>
            <a
              href="https://polygonscan.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              PolygonScan
            </a>
            <a
              href="https://bscscan.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              BSCScan
            </a>
            <a
              href="https://blockscout.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              Blockscout
            </a>
            <a
              href="https://etherscan.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              Etherscan
            </a>
          </div>
          <div className="div-2">
            <div className="text-wrapper-32">RESOURCES</div>
            <a
              href="https://t.me/xionfinancedefi"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              Join The Community
            </a>
            <Link to="/privacy-policy" className="text-wrapper-46">
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" className="text-wrapper-46">
              Terms & Conditions
            </Link>
            <a
              href="/sitemap.xml"
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrapper-46"
            >
              Sitemap
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
