import React, { useState, useCallback } from "react";
import "./Frame3396.css";
import "../Frame3395";

function Frame3396(props) {
  const [emailInput, setEmailInput] = useState("");
  const [buttonText, setButtonText] = useState("SUBMIT");

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleSubmit = useCallback(() => {
    const formData = {
      email: emailInput,
    };

    const serverlessFunctionURL = "/api/sendToZapier";

    fetch(serverlessFunctionURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setButtonText("Submitted");
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log("There was a problem with the fetch operation:", error.message);
      });
  }, [emailInput]);

  return (
      <div className="frame-3395-2">
        <input
          className="eg-bethe1sttoknow-2 roboto-normal-shark-19-2px"
          name="egbethe1sttoknow"
          placeholder='e.g bethe1st@toknow'
          type={props.inputType}
          value={emailInput}
          onChange={handleInputChange}
        />
      <div className={`frame-3396-1 ${props.buttonClassName || ""}`}>
        <div className="submit-1 titilliumweb-normal-white-19-2px" onClick={handleSubmit}>
          {buttonText}
        </div>
      </div>
    </div>
  );
}

export default Frame3396;
