import { A as AbstractWallet } from '../../../../dist/abstract-6d64547d.browser.esm.js';
import '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import 'ethers';
import 'eventemitter3';
import '@thirdweb-dev/sdk';
import '../../../../dist/url-a45219bd.browser.esm.js';
import '@thirdweb-dev/chains';

/**
 * Wallet interface to connect using [ethers.js](https://github.com/ethers-io/ethers.js/) signer
 *
 * @example
 * ```javascript
 * import { EthersWallet } from "@thirdweb-dev/wallets";
 * import { Wallet } from "ethers";
 *
 * // can be any ethers.js signer
 * const signer = Wallet.createRandom();
 * const wallet = new EthersWallet(signer);
 *
 * await wallet.connect();
 * ```
 *
 * @wallet
 */
class EthersWallet extends AbstractWallet {
  /**
   * Create instance of `EthersWallet`
   * @param signer - ethers.js signer object
   */
  constructor(signer) {
    super();
    this._signer = signer;
  }

  /**
   * Returns [ethers signer](https://docs.ethers.org/v5/api/signer/) object used by the wallet
   */
  async getSigner() {
    return this._signer;
  }
}

export { EthersWallet };
