import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Desktop from "./src/screens/Desktop";
import Mobile from "./src/screens/Mobile";
import IPad from "./src/screens/IPad";
import useMediaQuery from "./hooks/useMediaQuery";
import LoadingScreen from "./src/screens/Loaders";
import NFTD from "./src/screens/NFTPage/NFT-D";
import NFTI from "./src/screens/NFTPage/NFT-I";
import NFTM from "./src/screens/NFTPage/NFT-M";
import TermsAndConditionsD from "./src/screens/Terms/Desktop";
import TermsAndConditionsI from "./src/screens/Terms/IPad";
import TermsAndConditionsM from "./src/screens/Terms/Mobile";
import PrivacyPolicyD from "./src/screens/Privacy/Desktop"
import PrivacyPolicyI from "./src/screens/Privacy/IPad"
import PrivacyPolicyM from "./src/screens/Privacy/Mobile"
import Error404D from "./src/screens/404Error/Desktop";
import Error404I from "./src/screens/404Error/IPad";
import Error404M from "./src/screens/404Error/Mobile";
import {
    ThirdwebProvider,
    ConnectWallet,
    metamaskWallet,
    coinbaseWallet,
    walletConnect,
    trustWallet,
    zerionWallet,
    rainbowWallet,
    magicLink,
} from "@thirdweb-dev/react";

// Import your components here

export const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadingTimer = setTimeout(() => {
            setIsLoading(false);
        }, 2500);

        return () => clearTimeout(loadingTimer);
    }, []);

    const isDesktop = useMediaQuery("(min-width: 1440px)");
    const isTablet = useMediaQuery("(min-width: 768px)");
    const isMobile = useMediaQuery("(min-width: 358px)");
    const [activeNetwork, setActiveNetwork] = useState('');

    if (isLoading) {
        return <LoadingScreen />;
    }
    return (
        <ThirdwebProvider
        activeChain={activeNetwork}
        modalSize="wide"
        isDarkMode="true"
        clientId="eb50a00590d715d34ebfb2e4470d3b53"
        supportedWallets={[
            metamaskWallet(),
            coinbaseWallet(),
            walletConnect(),
            trustWallet(),
            zerionWallet(),
            rainbowWallet(),
            magicLink({
                apiKey: 'pk_live_0FF17A616018E59A',
                oauthOptions: {
                    providers: [
                        "google",
                        "facebook",
                        "twitter",
                        "apple",
                    ],
                },
            }),
        ]}
    >
            <Router>
                <Switch>
                    {isDesktop && (
                        <Route path="/:path(|desktop)">
                            <Helmet>
                                <title>Xion Finance - One Click To DeFi</title>
                                <meta name="description" content="Xion Finance is a DeFi protocol for swapping and bridging crypto assets to stablecoins at the best rates. Experience it yourself." />
                                <meta name="keywords" content="xion finance, swap crypto to stablecoins, swap crypto to usdt, bridge crypto, polygon usdt, best defi platforms for swapping crypto, best defi platforms for bridging crypto" />
                            </Helmet>
                            <Desktop {...desktopData} {...tokenBoxData} swapBoxProps={swapBoxProps} chainBoxProps={chainBoxProps}  />
                        </Route>
                    )}
                    {isTablet && (
                        <Route path="/:path(|ipad)">
                            <Helmet>
                                <title>Xion Finance - One Click To DeFi</title>
                                <meta name="description" content="Xion Finance is a DeFi protocol for swapping and bridging crypto assets to stablecoins at the best rates. Experience it yourself." />
                                <meta name="keywords" content="xion finance, swap crypto to stablecoins, swap crypto to usdt, bridge crypto, polygon usdt, best defi platforms for swapping crypto, best defi platforms for bridging crypto" />
                            </Helmet>
                            <IPad {...iPadData} {...tokenBoxData} swapBoxProps={swapBoxProps} chainBoxProps={chainBoxProps}  />
                        </Route>
                    )}
                    {isMobile && (
                        <Route path="/:path(|mobile)">
                            <Helmet>
                                <title>Xion Finance - One Click To DeFi</title>
                                <meta name="description" content="Xion Finance is a DeFi protocol for swapping and bridging crypto assets to stablecoins at the best rates. Experience it yourself." />
                                <meta name="keywords" content="xion finance, swap crypto to stablecoins, swap crypto to usdt, bridge crypto, polygon usdt, best defi platforms for swapping crypto, best defi platforms for bridging crypto" />
                            </Helmet>
                            <Mobile {...mobileData} {...tokenBoxData} swapBoxProps={swapBoxProps} chainBoxProps={chainBoxProps} {...tokenBoxmobileData}  />
                        </Route>
                    )}
                    {isDesktop && (
                        <Route path="/nft-xion-astronauts">
                            <Helmet>
                                <title>Xion Finance - Astronaut Claim Zone</title>
                                <meta name="description" content="Xion Astronaut NFTs are your passport to passive income for global Web3 payments." />
                                <meta name="keywords" content="passive income nfts, xion astronauts, xion mystery box, web3 payments nfts, web3 payment gateway nft, best web3 nfts" />
                            </Helmet>
                            <NFTD {...desktopData} />
                        </Route>
                    )}
                    {isTablet && (
                        <Route path="/nft-xion-astronauts">
                            <Helmet>
                                <title>Xion Finance - Astronaut Claim Zone</title>
                                <meta name="description" content="Xion Astronaut NFTs are your passport to passive income for global Web3 payments." />
                                <meta name="keywords" content="passive income nfts, xion astronauts, xion mystery box, web3 payments nfts, web3 payment gateway nft, best web3 nfts" />
                            </Helmet>
                            <NFTI {...iPadData} />
                        </Route>
                    )}
                    {isMobile && (
                        <Route path="/nft-xion-astronauts">
                            <Helmet>
                                <title>Xion Finance - Astronaut Claim Zone</title>
                                <meta name="description" content="Xion Astronaut NFTs are your passport to passive income for global Web3 payments." />
                                <meta name="keywords" content="passive income nfts, xion astronauts, xion mystery box, web3 payments nfts, web3 payment gateway nft, best web3 nfts" />
                            </Helmet>
                            <NFTM {...mobileData} />
                        </Route>
                    )}
                    {isDesktop && (
                        <Route path="/terms-and-conditions">
                            <Helmet>
                                <title>Xion Finance - Terms & Conditions</title>
                                <meta name="description" content="Understand Xion Finance's commitment to safeguarding your data and the terms that govern our services, ensuring a transparent and secure environment for users" />
                                <meta name="keywords" content="xion finance terms and conditions, xion finance data protection" />
                            </Helmet>
                            <TermsAndConditionsD {...desktopData} />
                        </Route>
                    )}
                    {isTablet && (
                        <Route path="/terms-and-conditions">
                            <Helmet>
                                <title>Xion Finance - Terms & Conditions</title>
                                <meta name="description" content="Understand Xion Finance's commitment to safeguarding your data and the terms that govern our services, ensuring a transparent and secure environment for users" />
                                <meta name="keywords" content="xion finance terms and conditions, xion finance data protection" />
                            </Helmet>
                            <TermsAndConditionsI {...iPadData} />
                        </Route>
                    )}
                    {isMobile && (
                        <Route path="/terms-and-conditions">
                            <Helmet>
                                <title>Xion Finance - Terms & Conditions</title>
                                <meta name="description" content="Understand Xion Finance's commitment to safeguarding your data and the terms that govern our services, ensuring a transparent and secure environment for users" />
                                <meta name="keywords" content="xion finance terms and conditions, xion finance data protection" />
                            </Helmet>
                            <TermsAndConditionsM {...mobileData} />
                        </Route>
                    )}

                    {isDesktop && (
                        <Route path="/privacy-policy">
                            <Helmet>
                                <title>Xion Finance - Privacy Policy</title>
                                <meta name="description" content="Understand Xion Finance's commitment to safeguarding your data and the terms that govern our services, ensuring a transparent and secure environment for users" />
                                <meta name="keywords" content="xion finance privacy policy, xion finance cookies" />
                            </Helmet>
                            <PrivacyPolicyD {...desktopData} />
                        </Route>
                    )}
                    {isTablet && (
                        <Route path="/privacy-policy">
                            <Helmet>
                                <title>Xion Finance - Privacy Policy</title>
                                <meta name="description" content="Understand Xion Finance's commitment to safeguarding your data and the terms that govern our services, ensuring a transparent and secure environment for users" />
                                <meta name="keywords" content="xion finance privacy policy, xion finance cookies" />
                            </Helmet>
                            <PrivacyPolicyI {...iPadData} />
                        </Route>
                    )}
                    {isMobile && (
                        <Route path="/privacy-policy">
                            <Helmet>
                                <title>Xion Finance - Privacy Policy</title>
                                <meta name="description" content="Understand Xion Finance's commitment to safeguarding your data and the terms that govern our services, ensuring a transparent and secure environment for users" />
                                <meta name="keywords" content="xion finance privacy policy, xion finance cookies" />
                            </Helmet>
                            <PrivacyPolicyM {...mobileData} />
                        </Route>
                    )}
                    {isDesktop && (
                        <Route path="*">
                            <Helmet>
                                <title>Xion Finance - Error 404</title>
                                <meta name="description" content="Oops! The page you're looking for doesn't exist or might be broken. Navigate back or explore the home page for more." />
                                <meta name="keywords" content="404 page error, xion finance 404 error" />
                            </Helmet>
                            <Error404D {...desktopData} />
                        </Route>
                    )}
                    {isTablet && (
                        <Route path="*">
                            <Helmet>
                                <title>Xion Finance - Error 404</title>
                                <meta name="description" content="Oops! The page you're looking for doesn't exist or might be broken. Navigate back or explore the home page for more." />
                                <meta name="keywords" content="404 page error, xion finance 404 error" />
                            </Helmet>
                            <Error404I {...iPadData} />
                        </Route>
                    )}
                    {isMobile && (
                        <Route path="*">
                            <Helmet>
                                <title>Xion Finance - Error 404</title>
                                <meta name="description" content="Oops! The page you're looking for doesn't exist or might be broken. Navigate back or explore the home page for more." />
                                <meta name="keywords" content="404 page error, xion finance 404 error" />
                            </Helmet>
                            <Error404M {...mobileData} />
                        </Route>
                    )}
                </Switch>
            </Router>
        </ThirdwebProvider>
    );
};

export default App;


const bNB1Data = {
    path534: "/img/path-534@2x.png",
    path535: "/img/path-535@2x.png",
};

const usdt1Data = {
    path538: "/img/usdtlogo.png",
};

const frame61Data = {
    overlapGroup: "/img/rectangle-238@2x.png",
};

const desktopData = {
    desktop1: "/img/BlackBG.png",
    logo: "/img/XFlogoLarge.svg",
    title: "ONE CLICK TO DEFI",
    feesSoLowItWill: "FEE’S SO LOW IT WILL MAKE YOUR WALLET SPIN",
    vector1: "/img/vector-1@2x.png",
    place: "FROM",
    send: "SEND:",
    overlapGroup11: "/img/path-532@2x.png",
    name: "MAX",
    overlapGroup1: "/img/path-531@2x.png",
    half: "HALF",
    text1: "0.0",
    path529: "/img/matictokenlogo.png",
    token: "wMATIC",
    path530: "/img/path-530@2x.png",
    frame4: "/img/frame-4.png",
    to: "TO",
    overlapGroup2: "/img/path-536.png",
    receiveEstimated: "RECEIVE (estimated):",
    text2: "0.0",
    joinAGlobalCommunity: "JOIN A GLOBAL COMMUNITY",
    line33: "/img/line-33@2x.png",
    learnMoreAboutXio: "Learn more about Xion Finance, chat to the team, and help us shape the future of DeFi.",
    inputType: "email",
    inputPlaceholder: "e.g bethe1st@toknow",
    submit: "SUBMIT",
    overlapGroup3: "/img/group-246@2x.png",
    joinTheXionCommunity: "Join the Xion community",
    forUpdatesAndNews: "for updates and news",
    overlapGroup12: "/img/group-249@2x.png",
    chatInRealTimeWithThe: "Chat in real time with the",
    xionCommunity: "Xion community",
    overlapGroup22: "/img/group-252@2x.png",
    followXionFinanceFor: "Follow xion.finance for",
    updatesAndNews1: "updates and news",
    overlapGroup32: "/img/dt.png",
    followXionfinanceFor: "Follow @xionfinance for",
    updatesAndNews2: "updates and news",
    line34: "/img/line-34.png",
    copyright: "© 2024 Xion Finance",
    aboutUsBlogTCsPrivacyPolicy: "hello@xion.finance",
    path530: "/img/path-530-1@2x.png",
    bNBProps: bNB1Data,
    usdtProps: usdt1Data,
    frame6Props: frame61Data,
};

const telegram1Data = {
    overlapGroup: "/img/group-246-1@2x.png",
};

const medium1Data = {
    overlapGroup2: "/img/group-252-1@2x.png",
    followXionFinanceFor: "Follow xion.finance for updates and news",
};

const medium2Data = {
    overlapGroup2: "/img/group-249-1@2x.png",
    followXionFinanceFor: "Chat in real time with the Xion community",
    className: "discord",
};

const mobileData = {
    mobile4: "/img/BlackBG.png",
    logo: "/img/XFlogoMobile.svg",
    title: "ONE CLICK TO DEFI",
    feesSoLowItWill: <React.Fragment>FEE’S SO LOW IT WILL MAKE <br />YOUR WALLET SPIN</React.Fragment>,
    vector1: "/img/vector-1-5@2x.png",
    swapBridge: "SWAP+BRIDGE",
    buy: "BUY",
    withdraw: "WITHDRAW",
    place: "FROM",
    path534: "/img/path-534-1@2x.png",
    path535: "/img/path-535-1@2x.png",
    spanText1: "BNB CHAIN ",
    spanText2: "MAINNET",
    send1: "SEND:",
    text3: "0.0",
    overlapGroup1: "/img/path-532-1@2x.png",
    name: "MAX",
    overlapGroup: "/img/path-531-1@2x.png",
    half: "HALF",
    path529: "/img/path-529-1@2x.png",
    token: "USD",
    path530: "/img/path-530-1@2x.png",
    frame3407: "/img/frame-3407@2x.png",
    to: "TO",
    path538: "/img/usdtlogo.png",
    spanText3: "USDT ",
    spanText4: "POLYGON",
    send2: "RECEIVE (estimated):",
    text4: "0.0",
    overlapGroup2: "/img/rectangle-238-1@2x.png",
    approve: "APPROVE",
    joinAGlobalCommunity: "JOIN A GLOBAL COMMUNITY",
    line33: "/img/line-33-1@2x.png",
    learnMoreAboutXio: <React.Fragment>Learn more about Xion Finance, chat to the team, and help <br />us shape the future of DeFi.</React.Fragment>,
    inputType: "email",
    inputPlaceholder: "e.g bethe1st@toknow",
    overlapGroup3: "/img/mt.png",
    followXionfinanceFor: <React.Fragment>Follow @xionfinance for<br />updates and news</React.Fragment>,
    line34: "/img/line-34-3@2x.png",
    copyright: "© 2024 Xion Finance",
    aboutUsBlogTCsPrivacyPolicy: "hello@xion.finance",
    telegramProps: telegram1Data,
    medium1Props: medium1Data,
    medium2Props: medium2Data,
};

const swapBoxProps = {
    logo: "/img/logo@2x.png",
    title: "ONE CLICK TO DEFI",
    feesSoLowItWill: "FEE’S SO LOW IT WILL MAKE YOUR WALLET SPIN",
    vector1: "/img/vector-1@2x.png",
    place: "FROM",
    send: "SEND:",
    overlapGroup11: "/img/path-532@2x.png",
    name: "MAX",
    overlapGroup1: "/img/path-531@2x.png",
    half: "HALF",
    text1: "0.0",
    path529: "/img/matictokenlogo.png",
    token: "wMATIC",
    path530: "/img/path-530@2x.png",
    frame4: "/img/frame-4.png",
    to: "TO",
    overlapGroup2: "/img/path-536.png",
    receiveEstimated: "RECEIVE (estimated):",
    text2: "0.0",
    joinAGlobalCommunity: "JOIN A GLOBAL COMMUNITY",
    line33: "/img/line-33@2x.png",
    learnMoreAboutXio: "Learn more about Xion Finance, chat to the team, and help us shape the future of DeFi.",
    inputType: "email",
    inputPlaceholder: "e.g bethe1st@toknow",
    submit: "SUBMIT",
    overlapGroup3: "/img/group-246@2x.png",
    joinTheXionCommunity: "Join the Xion community",
    forUpdatesAndNews: "for updates and news",
    overlapGroup12: "/img/group-249@2x.png",
    chatInRealTimeWithThe: "Chat in real time with the",
    xionCommunity: "Xion community",
    overlapGroup22: "/img/group-252@2x.png",
    followXionFinanceFor: "Follow xion.finance for",
    updatesAndNews1: "updates and news",
    overlapGroup32: "/img/dt.png",
    followXionfinanceFor: "Follow @xionfinance for",
    updatesAndNews2: "updates and news",
    line34: "/img/line-34.png",
    copyright: "© 2024 Xion Finance",
    aboutUsBlogTCsPrivacyPolicy: "hello@xion.finance",
    path530: "/img/path-530-1@2x.png",
    bNBProps: bNB1Data,
    usdtProps: usdt1Data,
    frame6Props: frame61Data,
};

const menuBox2Data = {
    className: "menu-box-1",
};

const bNB2Data = {
    path534: "/img/path-534-5@2x.png",
    path535: "/img/path-535-2@2x.png",
};

const usdt2Data = {
    path538: "/img/usdtlogo.png",
};

const frame62Data = {
    overlapGroup: "/img/rectangle-238-2@2x.png",
    className: "frame-6-1",
};

const swapBox1Data = {
    bNBProps: bNB2Data,
    usdtProps: usdt2Data,
    frame6Props: frame62Data,
};

const frame33962Data = {
    className: "frame-3396-2",
};

const telegram2Data = {
    overlapGroup: "/img/group-246-2@2x.png",
    className: "telegram-1",
};

const medium3Data = {
    overlapGroup2: "/img/group-249-2@2x.png",
    followXionFinanceFor: "Chat in real time with the Xion community",
    className: "discord-1",
};

const medium4Data = {
    overlapGroup2: "/img/group-252-2@2x.png",
    followXionFinanceFor: "Follow xion.finance for updates and news",
    className: "social-box-item-2-1",
};

const medium5Data = {
    overlapGroup2: "/img/group-257-2@2x.png",
    followXionFinanceFor: <React.Fragment>Follow @xionfinance for<br />updates and news</React.Fragment>,
    className: "social-box-item-2",
};

const menuBox3Data = {
    className: "menu-box-2",
};

const bNB3Data = {
    path534: "/img/path-534-2@2x.png",
    path535: "/img/path-535-2@2x.png",
};

const usdt3Data = {
    path538: "/img/usdtlogo.png",
};

const frame63Data = {
    overlapGroup: "/img/rectangle-238-3@2x.png",
    className: "frame-6-2",
};

const swapBox2Data = {
    className: "swap-box-3",
    bNBProps: bNB3Data,
    usdtProps: usdt3Data,
    frame6Props: frame63Data,
};

const frame33963Data = {
    className: "frame-3396-3",
};

const telegram3Data = {
    overlapGroup: "/img/group-246-3@2x.png",
    className: "telegram-2",
};

const medium6Data = {
    overlapGroup2: "/img/group-249-3@2x.png",
    followXionFinanceFor: "Chat in real time with the Xion community",
    className: "discord-2",
};

const medium7Data = {
    overlapGroup2: "/img/group-252-3@2x.png",
    followXionFinanceFor: "Follow xion.finance for updates and news",
    className: "social-box-item-3-1",
};

const medium8Data = {
    overlapGroup2: "/img/dt.png",
    followXionFinanceFor: <React.Fragment>Follow @xionfinance for<br />updates and news</React.Fragment>,
    className: "social-box-item-3",
};

const iPadData = {
    ipad3: "/img/BlackBG.png",
    logo: "/img/XFlogoLarge.svg",
    title: "ONE CLICK TO DEFI",
    feesSoLowItWill: "FEE’S SO LOW IT WILL MAKE YOUR WALLET SPIN",
    vector1: "/img/vector-1-3@2x.png",
    joinAGlobalCommunity: "JOIN A GLOBAL COMMUNITY",
    line33: "/img/line-33-3@2x.png",
    learnMoreAboutXio: "Learn more about Xion Finance, chat to the team, and help us shape the future of DeFi.",
    line34: "/img/line-34-2.png",
    copyright: "© 2024 Xion Finance",
    aboutUsBlogTCsPrivacyPolicy: "hello@xion.finance",
    menuBoxProps: menuBox3Data,
    swapBoxProps: swapBox2Data,
    frame3396Props: frame33963Data,
    telegramProps: telegram3Data,
    medium1Props: medium6Data,
    medium2Props: medium7Data,
    medium3Props: medium8Data,
};

const tokenBoxData = [
    { symbol: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' },
    { symbol: 'MATIC', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' },
    { symbol: 'BNB', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' },
    { symbol: 'ARB', address: '0x912CE59144191C1204E64559FE8253a0e49E6548' },
    { symbol: 'AVAX', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' },
    { symbol: 'OP', address: '0x4200000000000000000000000000000000000042' },
    // Add other tokens as needed
  ];
  

const chainBoxProps = {
  overlapGroup189: "/img/b-2-line.png",
  selectSourceChain5: "Select Source Chain",
  x: "/img/x@2x.png",
  inputType5: "text",
  inputPlaceholder5: "Search chain by name or chain ID",
  polygonLogo: "/img/polygonlogo.png",
  polygonMainnet: "Polygon Mainnet",
  avalancheLogo: "/img/avallogo.png",
  avalancheMainnet: "Avalanche Mainnet",
  bnbLogo: "/img/bnblogo.png",
  bnbMainnet: "BNB Mainnet",
  ethLogo: "/img/ethlogo.png",
  ethereumMainnet: "Ethereum Mainnet",
  optimismLogo: "/img/oplogo.png",
  optimismMainnet: "Optimism Mainnet",
  path534: "/img/path-534@2x.png",
  text1: "….",
};

const tokenBoxmobileData = {
  overlapGroup45: "/img/b-2-line.png",
  selectSourceToken: "Select a Token",
  x: "/img/x@2x.png",
  inputType: "text",
  inputPlaceholder: "Search token by name or address",
  group259: "/img/group-259@2x.png",
  belowIsTheSupport: <React.Fragment>Below is the supported token list.<br />More tokens can be found if you select other chains.</React.Fragment>,
  group1: "/img/group-7@2x.png",
  tetherUsd: "Tether USD",
  usdt1: "— USDT",
  group2: "/img/group-25@2x.png",
  xgt1: "Xion Global Token",
  xgt2: "— XGT",
  group3: "/img/group-21@2x.png",
  usd: "USD Coin",
  usdc: "— USDC",
  group4: "/img/group-26@2x.png",
  wrappedMatic: "Wrapped MATIC",
  wmatic: "— WMATIC",
};






