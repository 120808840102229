export const PolygonNetwork = {
  name: 'Polygon Mainnet',
  chainId: '0x89',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: ['https://polygon.llamarpc.com'],
  blockExplorerUrls: ['https://polygonscan.com/'],
};

export const AvalancheNetwork = {
  name: 'Avalanche Network C-Chain',
  chainId: '0xa86a',
  nativeCurrency: {
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
  },
  rpcUrls: ['https://avalanche-mainnet.infura.io'],
  blockExplorerUrls: ['https://snowtrace.io/'],
};

export const BNBChainNetwork = {
  name: 'BNB Chain',
  chainId: '0x38',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://binance.llamarpc.com'],
  blockExplorerUrls: ['https://bscscan.com/'],
};

export const ArbitrumNetwork = {
  name: 'Arbitrum',
  chainId: '0xa4b1',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://arb1.arbitrum.io/rpc'],
  blockExplorerUrls: ['https://arbiscan.io/'],
};

export const EthereumNetwork = {
  name: 'Ethereum Mainnet',
  chainId: '0x1',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://eth.llamarpc.com'],
  blockExplorerUrls: ['https://etherscan.io/'],
};

export const LineaNetwork = {
  name: 'Linea',
  chainId: '0xe708',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://mainnet.infura.io/v3/'],
  blockExplorerUrls: ['https://lineascan.build/tx/'],
};

export const OptimismNetwork = {
  name: 'Optimism',
  chainId: '0xa',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: ['https://optimism.llamarpc.com'],
  blockExplorerUrls: ['https://optimistic.etherscan.io/'],
};

const networks = [
  {
    name: 'Polygon Mainnet',
    chainId: '137',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: ['https://polygon.llamarpc.com'],
    blockExplorerUrls: ['https://polygonscan.com/']
  },
  {
    name: 'Avalanche Mainnet',
    chainId: '43114',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/']
  },
  {
    name: 'BNB Chain',
    chainId: '56',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://binance.llamarpc.com'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  {
    name: 'Arbitrum',
    chainId: '42161',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io/']
  },
  {
    name: 'Ethereum Mainnet',
    chainId: '1',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://eth.llamarpc.com'],
    blockExplorerUrls: ['https://etherscan.io/']
  },
  {
    name: 'Linea',
    chainId: '59144', 
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH', 
      decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    blockExplorerUrls: ['https://lineascan.build/tx/']
  },
  {
    name: 'Optimism',
    chainId: '0xa', 
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://optimism.llamarpc.com'],
    blockExplorerUrls: ['https://optimistic.etherscan.io/']
  }
];


const tokens = [
  {
    name: 'Ethereum',
    symbol: 'ETH',
    logo: '/img/ethlogo.png',
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    chainId: "1",
    decimals: 18,
    type: "evm",
  },
  {
    name: 'Ethereum',
    symbol: 'ETH',
    logo: '/img/ethlogo.png',
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    chainId: "59144",
    decimals: 18,
    type: "evm",
  },
  {
    name: 'Matic Token',
    symbol: 'MATIC',
    logo: '/img/poly.svg',
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    chainId: "137",
    decimals: 18,
    type: "evm",
  },
  {
    name: 'Binance Coin',
    symbol: 'BNB',
    logo: '/img/bnblogo.png',
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    chainId: "56",
    decimals: 18,
    type: "evm",
  },
  {
    name: 'Arbitrum',
    symbol: 'ARB',
    logo: '/img/arbitrum.png',
    address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    chainId: "42161",
    decimals: 18,
    type: "evm",
  },
  {
    name: 'Avalanche',
    symbol: 'AVAX',
    logo: '/img/avallogo.png',
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    chainId: "43114",
    decimals: 18,
    type: "evm",
  },
  {
    name: 'Optimism',
    symbol: 'OP',
    logo: '/img/oplogo.png',
    address: "0x4200000000000000000000000000000000000042",
    chainId: "10",
    decimals: 18,
    type: "evm",
  },

  // ... other tokens
];


export { tokens, networks };


