import { aV as NATIVE_TOKEN_ADDRESS } from './index-6731ff72.browser.esm.js';
import { i as isNativeToken } from './fetchCurrencyValue-e496fbcc.browser.esm.js';

/**
 * @internal
 */
function cleanCurrencyAddress(currencyAddress) {
  if (isNativeToken(currencyAddress)) {
    return NATIVE_TOKEN_ADDRESS;
  }
  return currencyAddress;
}

export { cleanCurrencyAddress as c };
