import { cq as ContractWrapper } from './index-6731ff72.browser.esm.js';

/**
 * @internal
 */
async function hasERC20Allowance(contractToApprove, currencyAddress, value) {
  const provider = contractToApprove.getProvider();
  const ERC20Abi = (await import('@thirdweb-dev/contracts-js/dist/abis/IERC20.json')).default;
  const erc20 = new ContractWrapper(provider, currencyAddress, ERC20Abi, {}, contractToApprove.storage);
  const owner = await contractToApprove.getSignerAddress();
  const spender = contractToApprove.address;
  const allowance = await erc20.read("allowance", [owner, spender]);
  return allowance.gte(value);
}

export { hasERC20Allowance as h };
