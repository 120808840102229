import React, { useState } from "react";
import "./styles.css";

export const FeeBox = ({ name, maxFeePerGas, inputAmount, exchangeRate, fromAmountUSD, usdPrice, gasLimit, gasPrice, toAmountUSD, toAmountMinUSD, aggregateSlippage, aggregatePriceImpact }) => {

  const [isMaximized, setIsMaximized] = useState(false);
  const [showInfo, setShowInfo] = useState(false); // New state to control the visibility of the info

  const toggleBox = () => {
    setIsMaximized(!isMaximized);
  };

  const toggleInfo = () => {
    setShowInfo(!showInfo); // Toggle the visibility of the additional info
  };

  // Correctly applying the function and adjusting decimal places
  const formatInputAmount = (amount) => {
    // Convert from wei to BNB (or Ether) by dividing by 1e18
    const num = parseFloat(amount) / 1e18;
    // Format to up to 6 decimal places without rounding up additional decimals
    return num.toFixed(4);
  };


  // Safely format any number to a string with 2 decimal places
  const formatNumber = (number) => {
    const num = parseFloat(number);
    return isNaN(num) ? '0.00' : num.toFixed(2);
  };

  // Introduce a new function for formatting the total fees to 3 decimal places
  const formatFees = (number) => number ? number.toFixed(2) : '0.000';

  // Assuming gasPrice is in wei and converting to Ether for the calculation
  // Note: This step depends on how gasPrice and gasLimit are provided and may need adjustment

  // Calculate total gas cost in USD
  const calculateGasCostInUSD = () => {
    const gasPriceInEther = parseFloat(gasPrice) / 1e18; // Convert gasPrice from wei to Ether
    const totalGasCostInEther = gasPriceInEther * gasLimit; // Total gas cost in Ether
    return totalGasCostInEther * usdPrice; // Convert total gas cost to USD
  };

  const totalGasCostUSD = calculateGasCostInUSD();

  const totalFeesUSD = (fromAmountUSD - totalGasCostUSD - toAmountUSD); // Example calculation, adjust as necessary



  return isMaximized ? (
    <div className="fee-box-d-expanded">
      <div className="divbox">
        <img className="path" alt="Arrow" src="/img/path536.png" onClick={toggleBox} />
        <img className="line" alt="Line" src="/img/line-35.png" />
        <div className="maximize">Summary</div>
        <div className="overlap-group">
          <div className="FROM">Convert from
          </div>
          <div className="text-wrapper">{formatInputAmount(inputAmount)} {name} = {formatNumber(toAmountMinUSD)} USDT
          </div>
        </div>
        <div className="overlap-6">
          <div className="FROM">Est. Min Recieved
          </div>
          <div className="text-wrapper">{toAmountMinUSD} USDT</div>
        </div>
        <div className="overlap">
          <div className="FROM">Exchange rate
          </div>
          <div className="text-wrapper"> 1 {name} = {exchangeRate} USDT</div>
        </div>
        <div className="overlap-2">
          <div className="FROM">Est. Gas Fee
          </div>
          <div className="text-wrapper">${formatNumber(totalGasCostUSD)} USD</div>
        </div>
        <div className="overlap-3">
          <div className="FROM">Est. Aggregate Slippage
          </div>
          <div className="text-wrapper">{aggregateSlippage}</div>
        </div>
        <div className="overlap-4">
          <div className="FROM">Est. Aggregate Price Impact </div>
          <div className="text-wrapper">{aggregatePriceImpact}</div>
        </div>
        <div className="overlap-7">
          <div className="FROM">Est. Total Fees
          </div>
          <div className="text-wrapper">${formatFees(totalFeesUSD)}</div>
        </div>
        <div className="overlap-8">
          <div className="information">
            If market prices fluctuate significantly, the transaction may revert and you will receive axlUSDC on the destination chain.
            <span className="learn-more" onClick={toggleInfo}> Learn More </span>

          </div>
          {showInfo && (
            <div className="info-popup">
              <div className="info-content">
                <div className="info-text">
                  In the case of price volatility and low slippage, the transaction may revert. The transaction reverts to protect you from losing money on slippage or unfavorable price movement.
                  <br /><br />
                  If this happens, you will receive the value of your original swap amount in axlUSDC on the destination chain - instead of your chosen token.
                  <br /><br />
                  What to do if you receive axlUSDC? You can quickly swap axlUSDC to your desired token on Squid V2&nbsp;
                  <a href="https://v2.app.squidrouter.com/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', color: 'lightblue' }}>Here</a>.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="fee-box-d-minimised">
      <div className="overlap-group-wrapper">
        <div className="overlap-group">
          <img className="path" alt="Arrow" src="/img/path-535.png" onClick={toggleBox} />
          <div className="minimize">Summary</div>
        </div>
      </div>
    </div>
  );
};


