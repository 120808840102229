import React, { useEffect, useRef } from "react";

const StakingWidget = ({ onClose }) => {
  const widgetRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (widgetRef.current && !widgetRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="widget-container" ref={widgetRef}>
      <iframe
        src="https://liquidity-widget-eecc.vercel.app/"
        style={{
          border: "none",
          overflow: "hidden",
          height: "100vh",
          width: "100%",
        }}
        scrolling="no"
        title="Liquidity Widget"
      ></iframe>
    </div>
  );
};

export default StakingWidget;
