import { WalletConnect } from '../../wallet-connect/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect.browser.esm.js';
import '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import '../../../../dist/connector-05689d68.browser.esm.js';
import 'eventemitter3';
import '../../../../dist/base-06242fcf.browser.esm.js';
import '@thirdweb-dev/chains';
import '../../../../dist/abstract-6d64547d.browser.esm.js';
import 'ethers';
import '@thirdweb-dev/sdk';
import '../../../../dist/url-a45219bd.browser.esm.js';
import '../../../../dist/wc-fbb7ab15.browser.esm.js';
import '../../../../dist/walletIds-99db3206.browser.esm.js';

/**
 * @internal
 * @deprecated Use `WalletConnect` instead
 *
 * The WalletConnect v1.0 protocol has been shut down and no longer works.
 * To avoid breaking change, `WalletConnectV1` is still available but is an alias of `WalletConnect`.
 */
const WalletConnectV1 = WalletConnect;

export { WalletConnectV1 };
