import React from "react";
import "./Telegram.css";

function Telegram(props) {
  const { overlapGroup, className } = props;

  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div
      className={`telegram ${className || ""}`}
      onClick={() => handleSocialClick("https://t.me/+Kx85ITNjKxFmNTY0")}
    >
      <div
        className="overlap-group-22"
        style={{ backgroundImage: `url(${overlapGroup})` }}
      >
        <p className="join-the-xion-community-1 titilliumweb-normal-white-9-2px">
          Join the Xion community for updates and news
        </p>
      </div>
    </div>
  );
}

export default Telegram;
