import React from "react";
import { useState } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import { BurgerMenu } from "../../../components/Burger/BurgerMenu";
import "./Mobile.css";

function Error404M(props) {
  const {
    mobile4,
    logo,
    line34,
  } = props;

  const handleGoBackClick = () => {
    window.history.back(); // Go back to the previous page
  };

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen); 
  };


  return (
    <div className="error-m">
    <div className="error-404 screen" style={{ backgroundImage: `url("img/BlackBG.png")` }}> 
      <div className="frame-3420">
        <div className="nav-1">
        <a href="/">
              <img className="logo-1" src="/img/logo-5@2x.png" alt="LOGO" />
            </a>
          <div className="img-wrapper">
            <img className="group-2" alt="Group" src="/img/Burger.png" onClick={handleBurgerMenuClick} />
          </div>          
          <div className="my-custom-wrapper">
            <div className="my-custom-class">
              <ConnectWallet btnTitle="Connect Wallet" theme={"light"} />
            </div>
          </div>
      <BurgerMenu isOpen={isBurgerMenuOpen} onClose={handleBurgerMenuClick} />
        </div>

        <div className="error-404-content">
<div className="go-back-2" onClick={handleGoBackClick}>
              <div className="go-back-2-text">Take me back!!!</div>
            </div>
        <p className="text-wrapper">The page you are looking for seems to have been lost.</p>
        <div className="overlap-group">
          <div className="div">404 ERROR</div>
          <div className="ABOUT-US">aah damn!!!</div>
          <img className="asset" alt="Asset" src="/img/asset-30-4x-8-2.png" />
        </div>
      <img className="wepik-export" alt="Wepik export" src="/img/wepik-export-20231008151734w7ut-1.png" />
      <img className="img" alt="Wepik export" src="/img/wepik-export-20231008151734w7ut-2.png" />
      <img className="asset-x" alt="Asset" src="/img/asset-30-4x-8-2.png" />
    </div>

        <footer className="footer-1">
          <img className="line-34-1" src={line34} alt="Line 34" />
          <div className="frame-3399-1">
          <div className="copyright-1">© 2023 Xion Finance</div>
            <div className="about-us-blog-t-cs-privacy-policy-1">
            <a href="mailto:hello@xion.finance" className="email-link">
      hello@xion.finance
      </a>
      </div>
            </div>
          </footer>
      </div>
    </div>
    </div>
  );
}

export default Error404M;