import { _ as _defineProperty } from '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import { W as WagmiAdapter } from '../../../../dist/connector-05689d68.browser.esm.js';
import { a as AbstractClientWallet } from '../../../../dist/base-06242fcf.browser.esm.js';
import { w as walletIds } from '../../../../dist/walletIds-99db3206.browser.esm.js';
import { g as getInjectedMagicEdenProvider } from '../../../../dist/getInjectedMagicEdenProvider-f8a9ec6a.browser.esm.js';
import 'eventemitter3';
import '@thirdweb-dev/chains';
import '../../../../dist/abstract-6d64547d.browser.esm.js';
import 'ethers';
import '@thirdweb-dev/sdk';
import '../../../../dist/url-a45219bd.browser.esm.js';

/**
 * Wallet interface to connect [Magic Eden](https://wallet.magiceden.io/) extension or mobile app
 *
 * @example
 * ```ts
 * import { MagicEdenWallet } from "@thirdweb-dev/wallets";
 *
 * const wallet = new MagicEdenWallet();
 *
 * wallet.connect();
 * ```
 *
 * @wallet
 */
class MagicEdenWallet extends AbstractClientWallet {
  /**
   * @internal
   */
  get walletName() {
    return "Magic Eden";
  }

  /**
   * Create instance of `MagicEdenWallet`
   *
   * @param options - The `options` object contains the following properties:
   * ### clientId (recommended)
   *
   * Provide `clientId` to use the thirdweb RPCs for given `chains`
   *
   * You can create a client ID for your application from [thirdweb dashboard](https://thirdweb.com/create-api-key).
   *
   * ### chains (optional)
   * Provide an array of chains you want to support.
   *
   * Must be an array of `Chain` objects, from the [`@thirdweb-dev/chains`](https://www.npmjs.com/package/\@thirdweb-dev/chains) package.
   *
   * Defaults to our [default chains](/react/react.thirdwebprovider#default-chains).
   *
   * ### dappMetadata (optional)
   * Information about your app that the wallet will display when your app tries to connect to it.
   *
   * Must be an object containing `name`, `url`, and optionally `description` and `logoUrl` properties.
   *
   * ```javascript
   * import { MagicEdenWallet } from "@thirdweb-dev/wallets";
   *
   * const wallet = new MagicEdenWallet({
   *   dappMetadata: {
   *     name: "thirdweb powered dApp",
   *     url: "https://thirdweb.com",
   *     description: "thirdweb powered dApp",
   *     logoUrl: "https://thirdweb.com/favicon.ico",
   *   },
   * });
   * ```
   */
  constructor(options) {
    super(MagicEdenWallet.id, options);
    this.isInjected = !!getInjectedMagicEdenProvider();
  }
  async getConnector() {
    if (!this.connector) {
      const {
        MagicEdenConnector
      } = await import('../../../connectors/magic-eden/dist/thirdweb-dev-wallets-evm-connectors-magic-eden.browser.esm.js');
      const connector = new MagicEdenConnector({
        chains: this.chains,
        connectorStorage: this.walletStorage,
        options: {
          shimDisconnect: true
        }
      });
      this.connector = new WagmiAdapter(connector);
    }
    return this.connector;
  }
}
/**
 * @internal
 */
/**
 * @internal
 */
/**
 * @internal
 */
_defineProperty(MagicEdenWallet, "id", walletIds.magicEden);

export { MagicEdenWallet };
