import { d as buildTransactionFunction, T as Transaction } from './transactions-8593cfdc.browser.esm.js';
import { d3 as FEATURE_PRIMARY_SALE } from './index-6731ff72.browser.esm.js';

/**
 * Handle primary sales recipients
 * @remarks Configure primary sale recipients for an entire contract.
 * @example
 * ```javascript
 * const contract = await sdk.getContract("{{contract_address}}");
 * const salesRecipient = await contract.sales.getRecipient();
 * await contract.sales.setRecipient(recipientWalletAddress);
 * ```
 * @public
 */
class ContractPrimarySale {
  featureName = FEATURE_PRIMARY_SALE.name;
  constructor(contractWrapper) {
    this.contractWrapper = contractWrapper;
  }

  /**
   * Get the primary sale recipient
   * @returns The wallet address.
   * @example
   * ```javascript
   * const salesRecipient = await contract.sales.getRecipient();
   * ```
   * @public
   * @twfeature PrimarySale
   */
  async getRecipient() {
    const result = await this.contractWrapper.read("primarySaleRecipient", []);
    return result;
  }

  /**
   * Set the primary sale recipient
   * @param recipient - the wallet address
   * @example
   * ```javascript
   * await contract.sales.setRecipient(recipientWalletAddress);
   * ```
   * @public
   * @twfeature PrimarySale
   */
  setRecipient = /* @__PURE__ */buildTransactionFunction(async recipient => {
    return Transaction.fromContractWrapper({
      contractWrapper: this.contractWrapper,
      method: "setPrimarySaleRecipient",
      args: [recipient]
    });
  });
}

export { ContractPrimarySale as C };
