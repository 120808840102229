import React from "react";
import "./Frame3395.css";

function Frame3395() {
  return (
    <div className="frame-3395-2">
      <input
className="eg-bethe1sttoknow-2 roboto-normal-shark-19-2px"
name="egbethe1sttoknow"
placeholder={inputPlaceholder}
type={inputType}
value={emailInput} // Bind the value to the emailInput state variable
onChange={handleInputChange} // Update the state when the input changes
/>
    </div>
  );
}

export default Frame3395;