import React from "react";
import "./styles.css";
import { useChainId } from "@thirdweb-dev/react";

const blockchainExplorers = {
  '137': "https://polygonscan.com/tx/",
};

const NFTSuccessfulPopup = ({ onClose, transactionHash, address }) => {
  const chainId = useChainId(); // Correctly use useChainId hook
  const explorerUrl = blockchainExplorers[chainId?.toString()] || "https://polygonscan.com/tx/";
  const twitterMessage = encodeURIComponent("🚀 Just got myself a Xion Mystery Box which contains one of six rare astronauts! 🌌 Each astronaut, a gateway to exclusive benefits and a universe brimming with possibilities. 🌠\n\nEmbark on an interstellar adventure with @xionfinance, where every NFT is a journey through the cosmos of DeFi. Claim your astronaut, join the ranks of elite explorers, and harness the power of passive income. 🛸💫\n\nAre you ready to explore the infinite expanses of blockchain space? #XionAstronaut #NFTExplorers #DeFiFrontiers\n\nGet yours: https://www.xion.finance/nft-xion-astronauts");

  const twitterUrl = `https://twitter.com/intent/tweet?text=${twitterMessage}`;  
  

  console.log(`Chain ID: ${chainId}`); // Log the chain ID
  console.log(`Transaction Hash: ${transactionHash}`); // Log the transaction hash
  console.log(`Explorer URL: ${explorerUrl}`); // Log the full URL

  const handleViewTransactionClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (explorerUrl && transactionHash) {
      const url = `${explorerUrl}${transactionHash}`;
      console.log(`Opening URL: ${url}`); // Log the URL being opened
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      console.error('Explorer URL or Transaction Hash is undefined');
    }
  };


  return (
    <div className="nftsfpopup">
      <div className="div">
        <div className="contact-details">
          <div className="transaction">TRANSACTION SUCCESSFUL</div>
        </div>
        <img className="group" alt="Group" src="/img/successfultick.gif" />
        <div className="RB-button">
          <div className="overlap-group-wrapper">
            <div className="overlap-group" onClick={handleViewTransactionClick}>
              <div className="view-transaction">
                View Transaction
              </div>
              <img src="/img/linkicon.png" alt="link icon" className="link-icon" />
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="element-recived">1x - Xion Mystery Box</div>
            </div>     
      </div>
          <div>
            <div>
              <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="twitter-share-button">
                <img src="/img/share_gradient.png" alt="Share Icon" className="share-icon" />
                Share on X
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTSuccessfulPopup;