import React, { useState } from "react";
import "./TokenBoxMobile.css";
import { tokens } from "../../../FetchThirdWeb/NetworkInfo";
import { useChainId } from "@thirdweb-dev/react";

function TokenBoxMobile(props) {
  const [selectedTokenAddress, setSelectedTokenAddress] = useState(''); // State to store selected token address
  const chainId = useChainId(); // Use the hook to get the current chain ID

  const handleTokenSelect = (tokenSymbol, tokenAddress) => {
    const selectedToken = tokens.find(t => t.symbol === tokenSymbol, tokenAddress);
    if (selectedToken) {
      setSelectedTokenAddress(selectedTokenAddress);
      console.log("Selected Token Address:", selectedTokenAddress); 
    }
  };  

  const handleBothTokenSelects = (tokenSymbol, tokenAddress) => {
    handleTokenSelect(tokenSymbol, tokenAddress); // This updates the local state
    props.onTokenChange(tokenSymbol, tokenAddress); // This should call handleTokenChange in SwapBox
  };

  const {
    onClose, 
    onTokenChange,
    selectedToken,
    tokenBoxData,
  } = props;  

  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const filteredTokens = chainId ? tokens.filter(token => token.chainId === chainId.toString()) : [];

  
  return (
    <div className="token-box-mobile">
          <div className="overlap-group45">
            <div className="flex-row27">
              <div className="select-source-token">Available Tokens</div>
              <div className="info-selection">Note: Only base tokens available per network, change networks to change token.</div>
              <img className="x" src="/img/x@2x.png" alt="X" onClick={() => onClose(false)} />
            </div>
            <div className="overlap-group205">
              <input
                className="search-token-by-name-or-address"
                name="searchtokenbynameoraddress"
                placeholder="Search token by name or address"
                type="text"
                onChange={handleSearchChange}
              />
            </div>
            <div className="overlap-group58">
              <img className="group-299" src="/img/group-259@2x.png" alt="Group 299" />
              <div className="below-is-the-support">
                <p className="below-is-the-support-1 valign-text-middle">
                  Below is the supported token list.<br />More tokens can be found if you select other chains.
                </p>
              </div>
            </div>
      <div className="scroll-group-10">
      {filteredTokens.filter(token => token.symbol.toLowerCase().startsWith(searchValue)).map(token =>
          <div className="scroll-group-10-item" key={token.symbol}>
            <div
              className={`overlap-group-181 titilliumweb-normal-white-17px ${selectedToken === token.symbol ? "active" : ""}`}
              onClick={() => handleBothTokenSelects(token.symbol, token.address)}
            >
                    <img className="group" src={token.logo} alt="Group" />
                    <div className="wrapped-matic">{token.name}</div>
                    <div className="w-matic">{token.symbol}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    
    export default TokenBoxMobile;