import { cv as AmountSchema } from './index-6731ff72.browser.esm.js';
import { utils } from 'ethers';
import { f as fetchCurrencyMetadata } from './fetchCurrencyValue-e496fbcc.browser.esm.js';

/**
 *
 * @param provider - The provider to use
 * @param inputPrice - The input price to normalize
 * @param currencyAddress - The currency address to normalize the price for
 * @returns
 * @internal
 */
async function normalizePriceValue(provider, inputPrice, currencyAddress) {
  const metadata = await fetchCurrencyMetadata(provider, currencyAddress);
  return utils.parseUnits(AmountSchema.parse(inputPrice), metadata.decimals);
}

export { normalizePriceValue as n };
