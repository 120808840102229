import React, { useState, useCallback, useRef, useEffect } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import MenuBox from "../../components/MenuBox";
import BuyBox from "../../components/BuyBox";
import { TypeAnimation } from "react-type-animation";
import { BurgerMenu } from "../../components/Burger/BurgerMenu";
import useFetchXGTData from "../../../FetchThirdWeb/fetchXGTData"; // Adjust the path as needed
import { FeeBox } from "../../components/FeeBox/Index";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import SwapBox from "../../components/SwapBox";
import {
  useAddress,
  useConnectionStatus,
  useBalance,
} from "@thirdweb-dev/react";
import "./IPad.css";
import Frame3396 from "../../components/Frame3396";
import Telegram from "../../components/Telegram";
import Medium from "../../components/Medium";
import CookiesDesktop from "../../components/CookiesDesktop";
import StakingWidget from "../../components/StakingWidget";

function IPad(props) {
  const {
    ipad3,
    logo,
    title,
    buyBoxProps,
    vector1,
    joinAGlobalCommunity,
    line33,
    learnMoreAboutXio,
    line34,
    copyright,
    aboutUsBlogTCsPrivacyPolicy,
    menuBoxProps,
    swapBoxProps,
    frame3396Props,
    telegramProps,
    medium1Props,
    medium2Props,
    medium3Props,
    desktop1,
    place,
    send,
    overlapGroup11,
    name,
    overlapGroup1,
    half,
    text1,
    frame4,
    to,
    overlapGroup2,
    receiveEstimated,
    text2,
    inputType,
    inputPlaceholder,
    submit,
    overlapGroup3,
    joinTheXionCommunity,
    forUpdatesAndNews,
    overlapGroup12,
    chatInRealTimeWithThe,
    xionCommunity,
    overlapGroup22,
    followXionFinanceFor,
    updatesAndNews1,
    overlapGroup32,
    followXionfinanceFor,
    updatesAndNews2,
    usdtProps,
    frame6Props,
    onNetworkChange, // Add onNetworkChange prop
    tokenBoxData,
    usdt1Data,
    frame61Data,
    toAmountUSD,
    fromAmountUSD,
    toAmountMinUSD,
    estimatedRouteDuration,
    exchangeRate,
    usdPrice,
    aggregatePriceImpact,
    aggregateSlippage,
    gasPrice,
    toAmount,
    fromAmount,
    gasLimit,
    selectedTokenAddress,
    maxFeePerGas,
  } = props;

  const xgtData = useFetchXGTData();
  const [activeNetwork, setActiveNetwork] = useState("");
  const [isTokenBoxOpen, setIsTokenBoxOpen] = useState(false);
  const [isChainBoxOpen, setIsChainBoxOpen] = useState(false);
  const [activeBox, setActiveBox] = useState("swap+bridge");
  const [emailInput, setEmailInput] = useState(""); // State for email input
  const [buttonText, setButtonText] = useState("SUBMIT"); // State for button text
  const [inputAmount, setInputAmount] = useState(0);
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const menuBoxRef = useRef(null);
  const location = useLocation();
  const connectionStatus = useConnectionStatus(); // Use the hook here
  const userAddress = useAddress(); // Use the hook to get the user's address
  const address = useAddress(); // Get the user's address
  const [transactionHash, setTransactionHash] = useState("");
  const [isStakingWidgetOpen, setIsStakingWidgetOpen] = useState(false);

  // Use the useBalance hook
  const {
    data: tokenBalance,
    isLoading: isBalanceLoading,
    error: balanceError,
  } = useBalance(userAddress, selectedTokenAddress);

  useEffect(() => {
    if (balanceError) {
      console.error("Error fetching token balance:", balanceError);
      return;
    }

    if (!isBalanceLoading && tokenBalance) {
      const formattedBalance = parseFloat(tokenBalance.displayValue).toFixed(2); // Formatting the balance
      console.log(
        `Balance for token at address ${selectedTokenAddress} for user ${userAddress} is: ${formattedBalance}`
      );
    }
  }, [
    tokenBalance,
    isBalanceLoading,
    balanceError,
    selectedTokenAddress,
    userAddress,
  ]);

  useEffect(() => {
    // Check if there's a state passed from the 404 page
    if (location.state && location.state.selectedBox) {
      setActiveBox(location.state.selectedBox);
    }
  }, [location]);

  useEffect(() => {
    if (
      activeBox === "buy" ||
      activeBox === "withdraw" ||
      activeBox === "swap+bridge"
    ) {
      scrollToRef(menuBoxRef);
    }
  }, [activeBox]);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  // Inside Desktop component
  const [swapBoxData, setSwapBoxData] = useState({
    toAmountUSD: "",
    toAmountMinUSD: "",
    fromAmountUSD: "",
    fromAmount: "",
    estimatedRouteDuration: "",
    name: "",
    exchangeRate: "",
    usdPrice: "",
    aggregatePriceImpact: "0",
    aggregateSlippage: "0",
    gasPrice: "",
    toAmount: "",
    gasLimit: "0",
    inputAmount: "",
    maxFeePerGas: "",
  });

  const handleTransactionComplete = useCallback((hash) => {
    setTransactionHash(hash);
    console.log("Transaction hash set in Desktop:", hash);
    // Now you can pass this hash to other components or perform additional logic as needed
  }, []);

  // State to hold the last route data for comparison
  const [lastRouteData, setLastRouteData] = useState({});

  // Adjusted handleSwapBoxDataUpdate to check against lastRouteData
  const handleSwapBoxDataUpdate = (data) => {
    // Check if new data is different from lastRouteData
    if (JSON.stringify(data) !== JSON.stringify(lastRouteData)) {
      console.log("New route data detected, updating...");
      setSwapBoxData(data);
      setLastRouteData(data); // Update lastRouteData with the new data

      // Perform operations that should happen with a new route
      // This is where you might update FeeBox data or perform additional fetches
    } else {
      console.log(
        "Received route data is the same as the last, no update needed."
      );
    }
  };

  // Inside Desktop component
  useEffect(() => {
    // This code block will run every time swapBoxData updates
    // Check if swapBoxData contains the necessary route info before proceeding
    if (swapBoxData && swapBoxData.exchangeRate && swapBoxData.gasPrice) {
      // Now swapBoxData contains new route info
      // Perform any operations here that need to happen once a new route is fetched
      // For example, updating state related to FeeBox or triggering any additional fetches

      console.log(
        "New route data received, update FeeBox or perform additional actions"
      );
      console.log("Handling new route data...");

      // If you need to fetch additional data based on the new route, do it here
      // fetchAdditionalData(swapBoxData).then(...);
    }
  }, [swapBoxData]); // Depend on swapBoxData to trigger this effect

  const handleTokenChange = (selectedTokenSymbol) => {
    const tokenData = tokens.find(
      (token) => token.symbol === selectedTokenSymbol
    );
    if (tokenData) {
      setSelectedToken(tokenData.name);
      setSelectedTokenAddress(tokenData.address);

      // Log the user's address and the token contract address
      console.log(`User Address: ${address}`);
      console.log(
        `Token Contract Address for ${selectedTokenSymbol}: ${tokenData.address}`
      );
    }
  };

  const setActiveBoxFromFooter = (boxName) => {
    setActiveBox(boxName);
    // Optional: Scroll to top or specific section if needed
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to update estimated amount based on input
  const updateEstimatedAmount = (newAmount) => {
    // Logic to calculate estimated amount in USDT
    // For demonstration, let's assume 1 token = 2 USDT
    setEstimatedAmount(newAmount * 2);
  };

  const handleAmountChange = (e) => {
    const newAmount = parseFloat(e.target.value);
    setInputAmount(newAmount);
    updateEstimatedAmount(newAmount);
  };

  const handleInputChange = (e) => {
    setEmailInput(e.target.value); // Update the email input state
  };

  const handleSubmit = useCallback(() => {
    // Fetch the data from the form using unique class names
    const formData = {
      email: emailInput,
    };

    // Update this URL to point to your Vercel serverless function
    const serverlessFunctionURL = "/api/sendToZapier";

    fetch(serverlessFunctionURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setButtonText("Submitted");
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(
          "There was a problem with the fetch operation:",
          error.message
        );
      });
  }, [emailInput]); // Include emailInput in the dependencies array

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };

  const handleTokenComponentClick = () => {
    setIsTokenBoxOpen(!isTokenBoxOpen);
  };

  // State to manage the visibility of the staking widget and overlay

  const handleOpenStakingWidget = () => {
    setIsStakingWidgetOpen(true);
  };

  const handleCloseStakingWidget = () => {
    setIsStakingWidgetOpen(false);
  };

  const handleClickOutside = (e) => {
    if (isStakingWidgetOpen && !e.target.closest(".widget-container")) {
      setIsStakingWidgetOpen(false);
    }
  };

  return (
    <div
      className="ipad-3 screen"
      style={{ backgroundImage: `url(${ipad3})` }}
      onClick={handleClickOutside}
    >
      {isStakingWidgetOpen && <div className="overlay" />}
      <div className="frame-3416-2">
        <div className="nav-3">
          <a href="/">
            <img className="logo-3" src={logo} alt="LOGO" />
          </a>
          <img
            className="img-wrapper"
            alt="Burger"
            src="/img/Burger.png"
            onClick={handleBurgerMenuClick}
          />
          <div className="my-custom-wrapper">
            <div className="my-custom-class">
              <ConnectWallet btnTitle="Connect Wallet" theme={"dark"} />
            </div>
          </div>
          <BurgerMenu
            isOpen={isBurgerMenuOpen}
            onClose={handleBurgerMenuClick}
          />
        </div>
        {/* <div className="alphalaunchbanner">
          <img className="alphaimg" src="/img/AL.png" />
        </div> */}
        <div className="frame-3413-4">
          <div className="heromenu-box-3">
            <div className="hero-box-3">
              <div className="frame-3413-5">
                <div className="flex-col-9">
                  <h1 className="title-3">{title}</h1>
                  <TypeAnimation
                    sequence={[
                      "Convert Crypto to USDT on Polygon",
                      3000, // wait 1s before typing the second sentence
                      "Buy USDT With Debit or Credit Card",
                      3000, // wait 1s before typing the third sentence
                      "Withdraw USDT To Bank Account Globally",
                      3000,
                    ]}
                    wrapper="p"
                    speed={50}
                    className="fees-so-low-it-will-3"
                    repeat={Infinity}
                  />
                  <img className="vector-1-3" src={vector1} alt="Vector 1" />
                </div>
              </div>
              <div className="frame-34">
                <div className="b-2">
                  <p className="XGT-MARKET-4">
                    <span className="text-wrapper-62">XGT V3 - </span>
                    <span className="text-wrapper-63">
                      {xgtData ? xgtData.price : "Loading..."}
                    </span>
                    <span className="text-wrapper-62"> • MARKET CAP - </span>
                    <span className="text-wrapper-63">
                      {xgtData ? xgtData.marketCap : "Loading..."}
                    </span>
                    <span className="text-wrapper-62">
                      {" "}
                      • CIRCULATING SUPPLY -{" "}
                    </span>
                    <span className="text-wrapper-63">
                      {xgtData ? xgtData.circulatingSupply : "Loading..."}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <MenuBox
              ref={menuBoxRef}
              setActiveBox={setActiveBox}
              activeBoxProp={activeBox}
            />
          </div>
        </div>
        {activeBox === "swap+bridge" && (
          <>
            <div className="swap-box">
              <SwapBox
                className="swap-box"
                usdtProps={usdt1Data}
                frame6Props={frame61Data}
                defaultNetwork="Polygon"
                defaultToken="MATIC"
                onTokenChange={handleTokenChange}
                initialSelectedToken="MATIC"
                isTokenBoxOpen={isTokenBoxOpen}
                onTokenClick={handleTokenComponentClick}
                selectedTokenAddress={selectedTokenAddress}
                onSwapBoxDataUpdate={handleSwapBoxDataUpdate}
                onTransactionComplete={handleTransactionComplete}
              />
            </div>
            <FeeBox
              toAmountUSD={toAmountUSD}
              toAmountMinUSD={toAmountMinUSD}
              fromAmountUSD={fromAmountUSD}
              fromAmount={fromAmount}
              estimatedRouteDuration={estimatedRouteDuration}
              name={name}
              exchangeRate={exchangeRate}
              usdPrice={usdPrice}
              gasPrice={gasPrice}
              aggregatePriceImpact={aggregatePriceImpact}
              aggregateSlippage={aggregateSlippage}
              toAmount={toAmount}
              gasLimit={gasLimit}
              maxFeePerGas={maxFeePerGas}
              inputAmount={inputAmount}
              {...swapBoxData}
            />
          </>
        )}
        {activeBox === "buy" && <BuyBox />}
        {activeBox === "withdraw" && (
          <iframe
            className="ramp-widget"
            allow="usb; polygon; clipboard-write"
            src="https://widget.mtpelerin.com/?_ctkn=2c97f059-e245-4c67-8698-34f3172bfb7f&lang=en&type=web&tabs=buy,sell&tab=sell&primary=%23e3006f&ssc=USDT&sdc=USD&net=matic_mainnet&mode=dark&logo=https%3A%2F%2Fwww.xion.finance%2Fimg%2Flogo@2x.png%2F"
          ></iframe>
        )}
        <div className="frame-3415-1">
          <div className="sub-3">
            <div className="sub-box-3">
              <div className="join-a-global-community-3 oswald-normal-ceramic-25px">
                {joinAGlobalCommunity}
              </div>
              <img className="line-33-3" src={line33} alt="Line 33" />
              <p className="learn-more-about-xio-3 titilliumweb-bold-hint-of-red-15px">
                {learnMoreAboutXio}
              </p>
            </div>
            <div className="email-box-2">
              <Frame3396 className={frame3396Props.className} />
            </div>
            <div className="social-box-3">
              <Telegram
                overlapGroup={telegramProps.overlapGroup}
                className={telegramProps.className}
              />
              <Medium
                overlapGroup2={medium1Props.overlapGroup2}
                followXionFinanceFor={medium1Props.followXionFinanceFor}
                className={medium1Props.className}
                link="https://discord.gg/VMYSvaxm9r"
              />
              <Medium
                overlapGroup2={medium2Props.overlapGroup2}
                followXionFinanceFor={medium2Props.followXionFinanceFor}
                className={medium2Props.className}
                link="https://medium.com/@xionfinance"
              />
              <Medium
                overlapGroup2={medium3Props.overlapGroup2}
                followXionFinanceFor={medium3Props.followXionFinanceFor}
                className={medium3Props.className}
                link="https://twitter.com/XionFinance"
              />
            </div>
            {isStakingWidgetOpen && (
              <StakingWidget onClose={handleCloseStakingWidget} />
            )}
            {!isStakingWidgetOpen && (
              <button
                className="open-widget-button"
                onClick={handleOpenStakingWidget}
              >
                Earn 9%+ APY
              </button>
            )}
          </div>
          <CookiesDesktop />
        </div>
        <Footer
          scrollToRef={scrollToRef}
          menuBoxRef={menuBoxRef}
          setActiveBoxFromFooter={setActiveBoxFromFooter}
        />
      </div>
    </div>
  );
}

export default IPad;
