import { w as walletIds } from '../../../../dist/walletIds-99db3206.browser.esm.js';
import { a as AbstractClientWallet, c as createAsyncLocalStorage } from '../../../../dist/base-06242fcf.browser.esm.js';
import { updateChainRPCs, defaultChains, Ethereum } from '@thirdweb-dev/chains';
import '../../../../dist/abstract-6d64547d.browser.esm.js';
import '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import 'ethers';
import 'eventemitter3';
import '@thirdweb-dev/sdk';
import '../../../../dist/url-a45219bd.browser.esm.js';

// eslint-disable-next-line @typescript-eslint/ban-types

/**
 * @internal
 */
class SignerWallet extends AbstractClientWallet {
  constructor(options) {
    super("signerWallet", options);
    if (options.clientId && options.chain) {
      options.chain = updateChainRPCs(options.chain, options.clientId);
    }
    this.options = options;
    this.signer = options.signer;
    this._storage = options?.storage || createAsyncLocalStorage(walletIds.localWallet);
  }
  async getConnector() {
    if (!this.connector) {
      const {
        SignerConnector
      } = await import('../../../connectors/signer/dist/thirdweb-dev-wallets-evm-connectors-signer.browser.esm.js');
      if (!this.signer) {
        this.signer = this.options.signer;
      }
      const defaults = (this.options.chain ? [...defaultChains, this.options.chain] : defaultChains).map(c => updateChainRPCs(c, this.options.clientId));
      this.connector = new SignerConnector({
        chain: this.options.chain || updateChainRPCs(Ethereum, this.options.clientId),
        signer: this.signer,
        chains: this.chains || defaults,
        clientId: this.options.clientId,
        secretKey: this.options.secretKey
      });
    }
    return this.connector;
  }
}

export { SignerWallet };
