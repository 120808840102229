import React, { useState, useCallback, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import "./desktop.css";
import { BurgerMenu } from "../../components/Burger/BurgerMenu";
import useFetchXGTData from '../../../FetchThirdWeb/fetchXGTData'; // Adjust the path as needed 
import { useHistory } from 'react-router-dom'; // Import useHistory
import Footer from "../../components/Footer";
import { useXionAPI } from "../../../api/XionAPI.js";
import NFTFailedPopup from "../../components/NFTPopups/NFTFailed.jsx";
import NFTProcessingPopup from "../../components/NFTPopups/NFTProcessing.jsx";
import NFTSuccessfulPopup from "../../components/NFTPopups/NFTSuccessful.jsx";

function NFTD(props) {


  const history = useHistory();
  const address = useAddress(); // Use the useAddress hook to check if wallet is connected
  const { approveAndPay, showProcessingPopup, showFailedPopup, showSuccessPopup, transactionData } = useXionAPI();
  const [mintedBoxesCount, setMintedBoxesCount] = useState(536); // Initial count of minted boxes

  const handleMenuOptionSelect = (boxName) => {
    history.push('/', { selectedBox: boxName });
  };

  const handleClick = () => {

    const existingScript = document.querySelector('script[src="https://cdn-quick-ar.threedy.ai/latest/threedy.js"]');
    if (existingScript) existingScript.remove();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn-quick-ar.threedy.ai/latest/threedy.js';
    script.async = true;
    script.onload = () => {
      window['QUICK_AR_INIT'] = { key: 'zuGOvNuiyG' };
    };

    // Append new script to head
    document.head.appendChild(script);
  };

  const [currentAstronaut, setCurrentAstronaut] = useState(0); // Add this line
  const astronautVideos = [
    "img/Veteran.mp4",
    "img/Elite.mp4",
    "img/Rookie.mp4",
    "img/Pro.mp4",
    "img/Master.mp4",
    "img/Legend.mp4",
  ]; // Add this array

  const handleVectorClick = (direction) => {
    if (direction === 'next') {
      setCurrentAstronaut((prevIndex) => (prevIndex + 1) % astronautVideos.length);
    } else {
      setCurrentAstronaut((prevIndex) => (prevIndex - 1 + astronautVideos.length) % astronautVideos.length);
    }
  };

  const astronautDescriptions = [
    "A seasoned explorer who partners with the Rookie to navigate the complexities of DeFi. Commanding his waverider, he has earned his stripes and his NFT. Yet, the quest is far from over. Together with his Rookie companion, he aims to ascend to Elite status and maximize his XGT yield.",
    "A high-ranking adventurer who has scoured the galaxy for the most lucrative opportunities. Piloting his raider, he's already a force to be reckoned with. His next mission is to achieve Pro status, as he joins forces with the Rookie and Veteran to conquer new DeFi frontiers.",
    "A fresh recruit who has just touched down on planet Xion. Eager to explore the world of decentralized finance, he embarks on his first mission in his drone. This adventure earns him his first NFT, but the journey has just begun. He must now learn from the Veteran to unlock more NFTs and amplify his XGT earnings.",
    "An expert in identifying high-yield opportunities across the DeFi universe. With his starship, he's on a quest for unparalleled gains. As he earns his NFT, he leads the Rookie, Veteran, and Elite on a journey to reach the pinnacle of DeFi mastery: becoming a Master DeFier.",
    "A sage of the DeFi realm, in tune with the intricacies of yield farming. Commanding his destroyer, he's unlocked the XGT hyperdrive, allowing him to venture into uncharted dimensions. His next destination is the realm of Legendary farmers, where only the most skilled navigators dare to tread.",
    "The epitome of DeFi prowess, his fleet has shaped the landscape of decentralized finance. At the helm of his battleship, he stands as the ultimate commander of the Xion fleet. With his earned NFT, he cements his legacy, as his XGT generation eclipses that of any other fleet.",
  ];

  const [emailInput, setEmailInput] = useState(""); // State for email input
  const [buttonText, setButtonText] = useState("SUBMIT"); // State for button text
  const xgtData = useFetchXGTData();

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const handleSocialClick = (url) => {
    window.open(url, "_blank");
  };


  const handleInputChange = (e) => {
    setEmailInput(e.target.value); // Update the email input state
  };

  const handleSubmit = useCallback(() => {
    // Fetch the data from the form using unique class names
    const formData = {
      email: emailInput,
    };

    // Update this URL to point to your Vercel serverless function
    const serverlessFunctionURL = "/api/sendToZapier";

    fetch(serverlessFunctionURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setButtonText("Submitted");
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log("There was a problem with the fetch operation:", error.message);
      });
  }, [emailInput]); // Include emailInput in the dependencies array

  const {
    desktop1,
    logo,
    title,
    vector1,
    joinAGlobalCommunity,
    line33,
    learnMoreAboutXio,
    inputType,
    inputPlaceholder,
    submit,
    overlapGroup3,
    joinTheXionCommunity,
    forUpdatesAndNews,
    overlapGroup12,
    chatInRealTimeWithThe,
    xionCommunity,
    overlapGroup22,
    followXionFinanceFor,
    updatesAndNews1,
    overlapGroup32,
    followXionfinanceFor,
    updatesAndNews2,
    line34,
    copyright,
    aboutUsBlogTCsPrivacyPolicy,
  } = props;

  const handleButtonClick = () => {
    if (address) {
      approveAndPay(0); // Initiates the purchase for the first product when the wallet is connected
    } else {
      console.log("Wallet not connected. Please connect your wallet.");
      // The ConnectWallet component should prompt the user to connect their wallet, so no additional action is needed here
    }
  };

  useEffect(() => {
    if (showSuccessPopup) {
      // Increment the minted boxes count by 1
      setMintedBoxesCount((prevCount) => prevCount + 1);
    }
  }, [showSuccessPopup]); // Depend on showSuccessPopup to trigger the effect



  return (
    <div className="nft-d">
      <div className="desktop-nft screen" style={{ backgroundImage: `url("img/BlackBG.png")` }}>
        <div className="frame-3414">
          <div className="nav">
            <a href="/">
              <img className="logo" src={logo} alt="LOGO" />
            </a>
            <div className="img-wrapper">
              <img className="group-2" alt="Group" src="/img/Burger.png" onClick={handleBurgerMenuClick} />
            </div>
            <div className="my-custom-wrapper">
              <div className="my-custom-class">
                <ConnectWallet btnTitle="Connect Wallet" theme={"dark"} />
              </div>
            </div>
            <BurgerMenu isOpen={isBurgerMenuOpen} onClose={handleBurgerMenuClick} />
          </div>
          <div className="frame-3411">
            <div className="heromenu-box">
              <div className="hero-box">
                <div className="frame-3413">
                  <div className="flex-col">
                    <h1 className="title">NFT CLAIM ZONE</h1>
                    <TypeAnimation
                      sequence={[
                        "A UNIQUE REAL WORLD USE CASE NFT COLLECTION",
                        3000, // wait 1s before typing the second sentence
                        "GET 1 OF 6 LIMITED ASTRONAUTS",
                        3000, // wait 1s before typing the third sentence
                        "NOW AVAILABLE",
                        3000,
                      ]}
                      wrapper="p"
                      speed={50}
                      className="fees-so-low-it-will"
                      repeat={Infinity}
                    />
                    <img className="vector-1" src={vector1} alt="Vector 1" />
                  </div>
                </div>
                <div className="frame-34">
                  <div className="b-2">
                    <p className="XGT-MARKET-4">
                      <span className="text-wrapper-62">XGT V3 - </span>
                      <span className="text-wrapper-63">
                        {xgtData ? xgtData.price : 'Loading...'}
                      </span>
                      <span className="text-wrapper-62"> • MARKET CAP - </span>
                      <span className="text-wrapper-63">
                        {xgtData ? xgtData.marketCap : 'Loading...'}
                      </span>
                      <span className="text-wrapper-62"> • CIRCULATING SUPPLY - </span>
                      <span className="text-wrapper-63">
                        {xgtData ? xgtData.circulatingSupply : 'Loading...'}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overlap-wrapper">
            <div className="overlap-6">
              <div className="sub-5">
                <div className="sub-box-5">
                  <p className="text-wrapper-15">WHAT ARE XION ASTRONAUT NFTs?</p>
                  <img className="line-4" alt="Line" src="/img/line-33-5.svg" />
                  <p className="learn-more-about-5">Xion Astronaut NFTs are your passport to passive income on Web3 payments</p>
                </div>
                <div className="frame-8" />
              </div>
              <div className="frame-9">
                <div className="overlap-group-5">
                  <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-9.png" />
                  <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-10.png" />
                  <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-11.png" />
                  <p className="text-wrapper-16">
                    Acquire 1 of the 6 unique Xion astronaut NFTs enabling you to passively earn income through transaction
                    fees.
                  </p>
                  <p className="element-unique-avatars-2">
                    <span className="text-wrapper-17">
                      6 unique avatars will be available, from Rookie to Legendary… each with their own unique abilities & rarity.
                      <br />
                      <br />
                    </span>
                  </p>
                  <p className="each-d-astronaut">
                    Each 3D Astronaut will be interoperable within a multitude of metaverses for play-to-earn and metaverse
                    business opportunities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="NF-tcards-3">
            <div className="overlap-7">
              <div className="sub-5">
                <div className="sub-box-5">
                  <p className="text-wrapper-15">VIEW THE XION ASTRONAUT NFTs!</p>
                  <img className="line-4" alt="Line" src="/img/line-33-5.svg" />
                  <p className="learn-more-about-5">6 UNIQUE ASTRONAUT NFTS ON OFFER ALL WITH CUSTOM REWARD LEVELS</p>
                </div>
                <div className="frame-8" />
              </div>
              <div className="cards-2">
                <div className="overlap-8">
                  <div className="overlap-group-6">
                    <p className="the-legend-astronaut-2">
                      {astronautDescriptions[currentAstronaut]}
                    </p>
                    <img className="vector-16" alt="Vector" src="/img/vector-16.svg" />
                    <img className="vector-17" alt="Vector" src="/img/vector-18.svg" />
                    <img className="vector-18" alt="Vector" src="/img/vector-19.svg" />
                    <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-4.png" />
                    <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-5.png" />
                    <img className="rectangle-7" alt="Rectangle" src="/img/rectangle-6.png" />
                    <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-7.png" />
                    <img className="rectangle-9" alt="Rectangle" src="/img/rectangle-8.png" />
                    <img className="rectangle-10" alt="Rectangle" src="/img/rectangle-12.png" />

                    <div style={{ position: "relative" }}>
                      <video autoPlay muted loop className="rectangle-11" alt="Rectangle" src={astronautVideos[currentAstronaut]} />
                      <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(50, 50, 50, 0.5))" }}></div>
                    </div>

                    <img className="arrow-2" alt="Arrow" src="/img/arrow-1.svg" onClick={() => handleVectorClick('prev')} />
                    <img className="icon-arrow-circle-2" alt="Icon arrow circle" src="/img/icon-arrow-circle-right-1.png" onClick={() => handleVectorClick('next')} />
                  </div>
                  <img className="vector-19" alt="Vector" src="/img/vector-15.svg" />
                  <img className="vector-20" alt="Vector" src="/img/vector-17.svg" />
                </div>
                <button className="get-started-wrapper" onClick={() => window.open('/img/nft-earnings-potential.jpg', '_blank')}>
                  <div className="get-started-4">VIEW EARNINGS</div>
                </button>
                <div className="CS-button-2">
                  <div className="get-started-5" onClick={() => window.open('https://youtu.be/euZ0C2fmRsw', '_blank')}>
                    WATCH VIDEO
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="NF-tcards-4">
            <p className="every-successful-2">
              Every successful renewal builds loyalty points to redeem NFTs, gift cards, XGT & more
            </p>
            <div className="overlap-9">
              <div className="sub-5">
                <div className="sub-box-5">
                  <div className="text-wrapper-15">XION ASTRONAUT NFT UTILITIES!</div>
                  <img className="line-4" alt="Line" src="/img/line-33-5.svg" />
                  <p className="learn-more-about-5">REAL USE CASE UNLIKE ANY OTHER NFT OUT THERE</p>
                </div>
                <div className="frame-8" />
              </div>
              <div className="frame-10">
                <div className="overlap-group-wrapper-3">
                  <div className="overlap-group-7">
                    <img className="p-block-4" alt="Power" src="/img/POWER.png" />
                  </div>
                </div>
                <div className="overlap-group-wrapper-3">
                  <div className="overlap-group-7">
                    <img className="p-block-4" alt="Royalty" src="/img/ROYALTY.png" />
                  </div>
                </div>
                <div className="overlap-group-wrapper-3">
                  <div className="overlap-group-7">
                    <img className="p-block-4" alt="Loyalty" src="/img/LOYALTY.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="MYSTERY-BOX-2">
            <div className="overlap-10">
              <div className="sub-5">
                <div className="sub-box-5">
                  <div className="text-wrapper-15">CLAIM A XION MYSTERY BOX</div>
                  <img className="line-4" alt="Line" src="/img/line-33-5.svg" />
                  <p className="text-wrapper-23">Six unique astronauts, unlocking endless possibilities</p>
                </div>
                <div className="frame-8" />
              </div>
              <div className="MBOX-2">
                <div className="overlap-group-8">
                  <img className="vector-25" alt="Vector" src="/img/vector-26.svg" />
                  <div className="mysterybox">
                    <iframe
                      src="https://quick-ar.threedy.ai/embed/f07c7f45"
                      style={{ width: '100%', height: '100%', border: '0', borderStyle: 'none' }}
                      scrolling="no">
                    </iframe>

                    <div onClick={handleClick}>
                      <img className="vector-26" alt="Vector" src="/img/vector-27.svg" />
                    </div>
                  </div>
                  <img className="vector-27" alt="Vector" src="/img/vector-28.svg" />
                  <div className="MI-nt-2" onClick={handleButtonClick}>
                    <div className="get-started-4"> {address ? "BUY NOW - $199.99" : "CONNECT WALLET"}
                    </div>
                  </div>
                  {showProcessingPopup && <NFTProcessingPopup />}
                  {showFailedPopup && <NFTFailedPopup onClose={() => setShowFailedPopup(false)} />}
                  {showSuccessPopup && <NFTSuccessfulPopup onClose={() => setShowSuccessPopup(false)} transactionHash={transactionData.transactionHash} address={transactionData.address} />}
                  <div className="JOIN-a-GLOBAL-22">CLAIMABLE BOXES</div>
                  <div className="JOIN-a-GLOBAL-23">MINTED BOXES</div>
                  <div className="JOIN-a-GLOBAL-24">0</div>
                  <div className="JOIN-a-GLOBAL-25">{`${mintedBoxesCount}/ 9000`}</div>
                </div>
              </div>
            </div>

            <div className="frame-3412">
              <div className="sub">
                <div className="sub-box">
                  <div className="join-a-global-community">{joinAGlobalCommunity}</div>
                  <img className="line-33" src={line33} alt="Line 33" />
                  <p className="learn-more-about-xio">{learnMoreAboutXio}</p>
                </div>
                <div className="frame-262">
                  <div className="frame-3395">
                    <input
                      className="eg-bethe1sttoknow roboto-normal-shark-19-2px"
                      name="egbethe1sttoknow"
                      placeholder={inputPlaceholder}
                      type={inputType}
                    />
                  </div>
                  <div className="frame-3396">
                    <div className="submit titilliumweb-normal-white-19-2px">{submit}</div>
                  </div>
                </div>
                <div className="social-box">
                  <div className="social-box-item" onClick={() => handleSocialClick("https://t.me/xionglobal")} >
                    <div
                      className="overlap-group titilliumweb-normal-white-9-2px"
                      style={{ backgroundImage: `url(${overlapGroup3})` }}
                    >
                      <div className="join-the-xion-community">{joinTheXionCommunity}</div>
                      <div className="for-updates-and-news">{forUpdatesAndNews}</div>
                    </div>
                  </div>
                  <div className="social-box-item" onClick={() => handleSocialClick("https://discord.gg/VMYSvaxm9r")} >
                    <div
                      className="overlap-group titilliumweb-normal-white-9-2px"
                      style={{ backgroundImage: `url(${overlapGroup12})` }}
                    >
                      <p className="chat-in-real-time-with-the">{chatInRealTimeWithThe}</p>
                      <div className="xion-community">{xionCommunity}</div>
                    </div>
                  </div>
                  <div className="social-box-item-1" onClick={() => handleSocialClick("https://xionglobal.medium.com/")} >
                    <div
                      className="overlap-group titilliumweb-normal-white-9-2px"
                      style={{ backgroundImage: `url(${overlapGroup22})` }}
                    >
                      <div className="follow-xionfinance-for">{followXionFinanceFor}</div>
                      <div className="updates-and-news">{updatesAndNews1}</div>
                    </div>
                  </div>
                  <div className="social-box-item-1" onClick={() => handleSocialClick("https://twitter.com/XionFinance")} >
                    <div
                      className="overlap-group titilliumweb-normal-white-9-2px"
                      style={{ backgroundImage: `url(${overlapGroup32})` }}
                    >
                      <div className="follow-xionfinance-for-1">{followXionfinanceFor}</div>
                      <div className="updates-and-news">{updatesAndNews2}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer
            onMenuOptionSelect={handleMenuOptionSelect} />
        </div>
      </div>
    </div>

  );
}

export default NFTD;


