import React, { useState, useEffect } from "react";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import Frame3396 from "../../components/Frame3396";
import Telegram from "../../components/Telegram";
import Medium from "../../components/Medium";
import { TypeAnimation } from 'react-type-animation';
import "./ipad.css";
import { useState } from "react";
import { BurgerMenu } from "../../components/Burger/BurgerMenu";
import useFetchXGTData from '../../../FetchThirdWeb/fetchXGTData'; // Adjust the path as needed
import Footer from "../../components/Footer";
import { useHistory } from 'react-router-dom'; // Import useHistory
import { useXionAPI } from "../../../api/XionAPI.js";
import NFTFailedPopup from "../../components/NFTPopups/NFTFailed.jsx";
import NFTProcessingPopup from "../../components/NFTPopups/NFTProcessing.jsx";
import NFTSuccessfulPopup from "../../components/NFTPopups/NFTSuccessful.jsx";


function NFTI(props) {


  const history = useHistory();
  const address = useAddress(); // Use the useAddress hook to check if wallet is connected
  const { approveAndPay, showProcessingPopup, showFailedPopup, showSuccessPopup, transactionData } = useXionAPI();
  const [mintedBoxesCount, setMintedBoxesCount] = useState(536); // Initial count of minted boxes

  const handleMenuOptionSelect = (boxName) => {
    history.push('/', { selectedBox: boxName });
  };

  const handleClick = () => {

    const existingScript = document.querySelector('script[src="https://cdn-quick-ar.threedy.ai/latest/threedy.js"]');
    if (existingScript) existingScript.remove();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn-quick-ar.threedy.ai/latest/threedy.js';
    script.async = true;
    script.onload = () => {
      window['QUICK_AR_INIT'] = { key: 'zuGOvNuiyG' };
    };

    // Append new script to head
    document.head.appendChild(script);
  };


  const [currentAstronaut, setCurrentAstronaut] = useState(0); // Add this line
  const astronautVideos = [
    "img/Veteran.mp4",
    "img/Elite.mp4",
    "img/Rookie.mp4",
    "img/Pro.mp4",
    "img/Master.mp4",
    "img/Legend.mp4",
  ]; // Add this array

  const handleVectorClick = (direction) => {
    if (direction === 'next') {
      setCurrentAstronaut((prevIndex) => (prevIndex + 1) % astronautVideos.length);
    } else {
      setCurrentAstronaut((prevIndex) => (prevIndex - 1 + astronautVideos.length) % astronautVideos.length);
    }
  };

  const astronautDescriptions = [
    "A seasoned explorer who partners with the Rookie to navigate the complexities of DeFi. Commanding his waverider, he has earned his stripes and his NFT. Yet, the quest is far from over. Together with his Rookie companion, he aims to ascend to Elite status and maximize his XGT yield.",
    "A high-ranking adventurer who has scoured the galaxy for the most lucrative opportunities. Piloting his raider, he's already a force to be reckoned with. His next mission is to achieve Pro status, as he joins forces with the Rookie and Veteran to conquer new DeFi frontiers.",
    "A fresh recruit who has just touched down on planet Xion. Eager to explore the world of decentralized finance, he embarks on his first mission in his drone. This adventure earns him his first NFT, but the journey has just begun. He must now learn from the Veteran to unlock more NFTs and amplify his XGT earnings.",
    "An expert in identifying high-yield opportunities across the DeFi universe. With his starship, he's on a quest for unparalleled gains. As he earns his NFT, he leads the Rookie, Veteran, and Elite on a journey to reach the pinnacle of DeFi mastery: becoming a Master DeFier.",
    "A sage of the DeFi realm, in tune with the intricacies of yield farming. Commanding his destroyer, he's unlocked the XGT hyperdrive, allowing him to venture into uncharted dimensions. His next destination is the realm of Legendary farmers, where only the most skilled navigators dare to tread.",
    "The epitome of DeFi prowess, his fleet has shaped the landscape of decentralized finance. At the helm of his battleship, he stands as the ultimate commander of the Xion fleet. With his earned NFT, he cements his legacy, as his XGT generation eclipses that of any other fleet.",
  ];

  const {
    ipad3,
    logo,
    title,
    buyBoxProps,
    vector1,
    joinAGlobalCommunity,
    line33,
    learnMoreAboutXio,
    line34,
    copyright,
    aboutUsBlogTCsPrivacyPolicy,
    menuBoxProps,
    swapBoxProps,
    frame3396Props,
    telegramProps,
    medium1Props,
    medium2Props,
    medium3Props,
  } = props;

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const xgtData = useFetchXGTData();

  const handleBurgerMenuClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const handleButtonClick = () => {
    if (address) {
      approveAndPay(0); // Initiates the purchase for the first product when the wallet is connected
    } else {
      console.log("Wallet not connected. Please connect your wallet.");
      // The ConnectWallet component should prompt the user to connect their wallet, so no additional action is needed here
    }
  };

  useEffect(() => {
    if (showSuccessPopup) {
      // Increment the minted boxes count by 1
      setMintedBoxesCount((prevCount) => prevCount + 1);
    }
  }, [showSuccessPopup]); // Depend on showSuccessPopup to trigger the effect

  return (
    <div className="nft-i">
      <div className="ipad-nft screen" style={{ backgroundImage: `url(img/BlackBG.png)` }}>
        <div className="frame-3416-2">
          <div className="nav-3">
            <a href="/">
              <img className="logo-3" src={logo} alt="LOGO" />
            </a>
            <img className="img-wrapper" alt="Burger" src="/img/Burger.png" onClick={handleBurgerMenuClick} />
            <div className="my-custom-wrapper">
              <div className="my-custom-class">
                <ConnectWallet btnTitle="Connect Wallet" theme={"dark"} />
              </div>
            </div>
            <BurgerMenu isOpen={isBurgerMenuOpen} onClose={handleBurgerMenuClick} />
          </div>
          <div className="frame-3413-4">
            <div className="heromenu-box-3">
              <div className="hero-box-3">
                <div className="frame-3413-5">
                  <div className="flex-col-9">
                    <h1 className="title-3">NFT CLAIM ZONE</h1>
                    <TypeAnimation
                      sequence={[
                        'A UNIQUE REAL WORLD USE CASE NFT COLLECTION',
                        3000, // wait 1s before typing the second sentence
                        'GET 1 OF 6 LIMITED ASTRONAUTS',
                        3000, // wait 1s before typing the third sentence
                        'NOW AVAILABLE',
                        3000,
                      ]}
                      wrapper="p"
                      speed={50}
                      className="fees-so-low-it-will-3"
                      repeat={Infinity}
                    />
                    <img className="vector-1-3" src={vector1} alt="Vector 1" />
                  </div>
                </div>
                <div className="frame-34">
                  <div className="b-2">
                    <p className="XGT-MARKET-4">
                      <span className="text-wrapper-62">XGT V3 - </span>
                      <span className="text-wrapper-63">
                        {xgtData ? xgtData.price : 'Loading...'}
                      </span>
                      <span className="text-wrapper-62"> • MARKET CAP - </span>
                      <span className="text-wrapper-63">
                        {xgtData ? xgtData.marketCap : 'Loading...'}
                      </span>
                      <span className="text-wrapper-62"> • CIRCULATING SUPPLY - </span>
                      <span className="text-wrapper-63">
                        {xgtData ? xgtData.circulatingSupply : 'Loading...'}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="WAXANF-ts-2">
            <div className="sub-7">
              <div className="sub-box-6">
                <p className="JOIN-a-GLOBAL-26">WHAT ARE XION ASTRONAUT NFTs?</p>
                <img className="line-6" alt="Line" src="/img/line-33-5.svg" />
                <p className="learn-more-about-6">Xion Astronaut NFTs are your passport to passive income on Web3 payments</p>
              </div>
            </div>
            <div className="frame-17">
              <img className="rectangle-12" alt="Rectangle" src="/img/rectangle-9.png" />
              <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-10.png" />
              <img className="rectangle-14" alt="Rectangle" src="/img/rectangle-11.png" />
              <p className="text-wrapper-36">
                Acquire 1 of the 6 unique Xion astronaut NFTs enabling you to passively earn income through transaction fees.
              </p>
              <p className="element-unique-avatars-3">
                <span className="text-wrapper-37">
                  6 unique avatars will be available, from Rookie to Legendary… each with their own unique abilities & rarity.
                  <br />
                  Power, Loyalty & Royalty.
                  <br />
                  <br />
                </span>
              </p>
              <p className="each-astronaut-2">
                Each 3D Astronaut will be interoperable within a multitude of metaverses for play-to-earn and metaverse business opportunities.
              </p>

            </div>
          </div>
          <div className="NF-tcards-5">
            <div className="sub-7">
              <div className="sub-box-6">
                <p className="JOIN-a-GLOBAL-27">VIEW THE XION ASTRONAUT NFTs!</p>
                <img className="line-6" alt="Line" src="/img/line-33-5.svg" />
                <p className="learn-more-about-6">6 UNIQUE ASTRONAUT NFTS ON OFFER ALL WITH CUSTOM REWARD LEVELS</p>
              </div>
            </div>
            <div className="cards-3">
              <div className="overlap-14">
                <div className="overlap-group-12">
                  <p className="the-legend-astronaut-3">
                    {astronautDescriptions[currentAstronaut]}
                  </p>
                  <img className="vector-29" alt="Vector" src="/img/vector-30.svg" />
                  <img className="vector-30" alt="Vector" src="/img/vector-31.svg" />
                  <img className="vector-31" alt="Vector" src="/img/vector-32.svg" />
                  <img className="vector-32" alt="Vector" src="/img/vector-33.svg" />
                  <div style={{ position: "relative", left: "45px" }}>
                    <video autoPlay muted loop className="rectangle-21" alt="Rectangle" src={astronautVideos[currentAstronaut]} />
                    <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(50, 50, 50, 0.5))" }}></div>
                  </div>


                  <img className="arrow-3" alt="Arrow" src="/img/arrow-2.svg" onClick={() => handleVectorClick('prev')} />
                  <img className="icon-arrow-circle-3" alt="Icon arrow circle" src="/img/icon-arrow-circle-right-2.png" onClick={() => handleVectorClick('next')} />

                  <img className="rectangle-15" alt="Rectangle" src="/img/rectangle-4.png" />
                  <img className="rectangle-16" alt="Rectangle" src="/img/rectangle-5.png" />
                  <img className="rectangle-17" alt="Rectangle" src="/img/rectangle-6.png" />
                  <img className="rectangle-18" alt="Rectangle" src="/img/rectangle-7.png" />
                  <img className="rectangle-19" alt="Rectangle" src="/img/rectangle-8.png" />
                  <img className="rectangle-20" alt="Rectangle" src="/img/rectangle-12.png" />

                </div>
                <img className="vector-33" alt="Vector" src="/img/vector-29.svg" />
              </div>
              <button className="button" onClick={() => window.open('/img/nft-earnings-potential.jpg', '_blank')}>
                <div className="get-started-6">VIEW EARNINGS</div>
              </button>
              <div className="CS-button-3">
                <div className="get-started-7" onClick={() => window.open('https://youtu.be/euZ0C2fmRsw', '_blank')}>
                  WATCH VIDEO
                </div>
              </div>
            </div>
          </div>
          <div className="NF-tcards-6">
            <p className="every-successful-3">
              Every successful renewal builds loyalty points to redeem NFTs, gift cards, XGT & more        </p>
            <div className="sub-8">
              <div className="sub-box-7">
                <div className="JOIN-a-GLOBAL-27">XION ASTRONAUT NFT UTILITIES!</div>
                <img className="line-6" alt="Line" src="/img/line-33.svg" />
                <p className="learn-more-about-6">REAL USE CASE UNLIKE ANY OTHER NFT OUT THERE</p>
              </div>
            </div>
            <div className="frame-18">
              <div className="POWER">
                <div className="overlap-group-13">
                  <img className="p-block-5" alt="Power" src="/img/POWER.png" />

                </div>
              </div>
              <div className="ROYALTY-2">
                <div className="overlap-15">
                  <img className="p-block-6" alt="Royalty" src="/img/ROYALTY.png" />

                </div>
              </div>
              <div className="LOYALTY">
                <div className="overlap-16">
                  <img className="p-block-7" alt="Loyalty" src="/img/LOYALTY.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="MYSTERY-BOX-3">
            <div className="sub-9">
              <div className="sub-box-8">
                <div className="JOIN-a-GLOBAL-27">BUY A XION MYSTERY BOX</div>
                <img className="line-6" alt="Line" src="/img/line-33.svg" />
                <p className="learn-more-about-7">Six unique astronauts, unlocking endless possibilities</p>
              </div>
            </div>
            <div className="MBOX-3">
              <div className="overlap-group-14">
                <img className="vector-39" alt="Vector" src="/img/vector-26.svg" />
                <div className="mysterybox">
                  <iframe
                    src="https://quick-ar.threedy.ai/embed/f07c7f45"
                    style={{ width: '100%', height: '100%', border: '0', borderStyle: 'none' }}
                    scrolling="no">
                  </iframe>
                </div>
                <div onClick={handleClick}>
                  <img className="vector-40" alt="Vector" src="/img/vector-27.svg" />
                </div>

                <img className="vector-41" alt="Vector" src="/img/vector-28.svg" />
                <div className="MI-nt-3"onClick={handleButtonClick}> 
                  <div className="get-started-8"> {address ? "BUY NOW - $199.99" : "CONNECT WALLET"}
                  </div>
                </div>
                {showProcessingPopup && <NFTProcessingPopup />}
                  {showFailedPopup && <NFTFailedPopup onClose={() => setShowFailedPopup(false)} />}
                  {showSuccessPopup && <NFTSuccessfulPopup onClose={() => setShowSuccessPopup(false)} transactionHash={transactionData.transactionHash} address={transactionData.address} />}
                <div className="JOIN-a-GLOBAL-37">CLAIMABLE BOXES</div>
                <div className="JOIN-a-GLOBAL-38">MINTED BOXES</div>
                <div className="JOIN-a-GLOBAL-39">0</div>
                <div className="JOIN-a-GLOBAL-40">{`${mintedBoxesCount} / 9000`}</div>
              </div>
            </div>
          </div>

          <div className="frame-3415-1">
            <div className="sub-3">
              <div className="sub-box-3">
                <div className="join-a-global-community-3 oswald-normal-ceramic-25px">{joinAGlobalCommunity}</div>
                <img className="line-33-3" src={line33} alt="Line 33" />
                <p className="learn-more-about-xio-3 titilliumweb-bold-hint-of-red-15px">{learnMoreAboutXio}</p>
              </div>
              <div className="email-box-2">
                <Frame3396 className={frame3396Props.className} />
              </div>
              <div className="social-box-3">
                <Telegram overlapGroup={telegramProps.overlapGroup} className={telegramProps.className} />
                <Medium
                  overlapGroup2={medium1Props.overlapGroup2}
                  followXionFinanceFor={medium1Props.followXionFinanceFor}
                  className={medium1Props.className}
                  link="https://discord.gg/VMYSvaxm9r"
                />
                <Medium
                  overlapGroup2={medium2Props.overlapGroup2}
                  followXionFinanceFor={medium2Props.followXionFinanceFor}
                  className={medium2Props.className}
                  link="https://xionfinance.medium.com/"
                />
                <Medium
                  overlapGroup2={medium3Props.overlapGroup2}
                  followXionFinanceFor={medium3Props.followXionFinanceFor}
                  className={medium3Props.className}
                  link="https://twitter.com/XionFinance"
                />
              </div>
            </div>
          </div>
          <Footer
            onMenuOptionSelect={handleMenuOptionSelect} />
        </div>
      </div>
    </div>

  );
}

export default NFTI;
