import { useEffect, useState } from 'react';

const useFetchUSDTPrice = () => {
  const [usdtPrice, setUsdtPrice] = useState('Loading...');

  useEffect(() => {
    const fetchPrice = async () => {
      const url = 'https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd';

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.tether && data.tether.usd) {
          setUsdtPrice(data.tether.usd);
        } else {
          setUsdtPrice('Unavailable');
        }
      } catch (error) {
        console.error('Error fetching USDT price:', error);
        setUsdtPrice('Error');
      }
    };

    fetchPrice();
  }, []);

  return usdtPrice;
};

export default useFetchUSDTPrice;
