import React, { useState, useEffect } from "react";
import "./styles.css";

const ProcessingPopup = () => {
    // Initialize the timer state to start counting from 0
    const [timeElapsed, setTimeElapsed] = useState(0);

    useEffect(() => {
        // Start a timer that increments the `timeElapsed` state every second
        const timerId = setInterval(() => {
            setTimeElapsed(prevTime => prevTime + 1);
        }, 1000);

        // Cleanup function to clear the timer when the component unmounts or the transaction ends
        return () => clearInterval(timerId);
    }, []); // Empty dependency array means this effect runs only on mount and cleanup on unmount

    return (
        <div className="pspopup">
            <div className="div">
                <div className="contact-details">
                    <div className="transaction">TRANSACTION PROCESSING</div>
                    <div className="onway">Your tokens are on their way, feel free to leave this page.</div> 
                </div>
                <img className="group" alt="Group" src="/img/processingcircle.gif" />
                <div className="element-sec-wrapper">
                    <div className="element-sec">
                        {timeElapsed}s <br/>
                        TRACK ~1MIN

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessingPopup;
