import React from "react";
import "./styles.css";

function FailedPopup({ onClose, failureReason }) {
  let message = "TRANSACTION ERROR";
  if (failureReason === "NEEDS_GAS") {
    message = "TRANSACTION FAILED - NEEDS MORE GAS";
  } else if (failureReason === "PARTIAL_SUCCESS") {
    message = "TRANSACTION PARTIALLY SUCCESSFUL";
  } // Add more conditions as needed

  // Define your support URL here
  const supportUrl = "https://t.me/xionfinancedefi";

  return (
    <div className="fdpopup">
      <div className="div">
        <div className="contact-details">
          <div className="transaction-needs">{message}</div>
          <div className="failed-reason"> We encountered an error which could be caused by a congested network or low gas fee.
            <br /> <br />
            Check your wallet to see more details about the error or <a href={supportUrl} target="_blank" rel="noopener noreferrer">contact support</a>.
          </div>

        </div>
        <img className="group" alt="Group" src="/img/failedtick.gif" />
      </div>
      <img className="close" alt="X" src="/img/group-3110-3.png" onClick={(e) => {
        e.stopPropagation(); // Prevent event from bubbling up
        onClose(); // Resets everything and stops fetching transaction status
      }} />

    </div>
  );
}

export default FailedPopup;