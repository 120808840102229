import React from "react";

export const PolygonNetworkBox = ({ onNetworkClick }) => { // Change props to onNetworkClick
  
  return (
    // Add the onClick handler to the div
    <div className="network" onClick={onNetworkClick}>
      <div className="overlap-group-61">
      <img className="path-534-1" src="/img/poly.svg" alt="Path 534 1" />
      <div className="overlap-group1-1">
        <div className="mainnet titilliumweb-normal-hint-of-red-7-3px">NETWORK</div>
        <div className="chain titilliumweb-normal-havelock-blue-14px">POLYGON</div>
            </div>
          <img className="path-535-1" src={"/img/path-535@2x.png"} alt="Path 535 1" />
          </div>
          </div>
  );
};


export default PolygonNetworkBox;