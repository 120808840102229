import React from "react";
import Gif from "/static/img/xgtloadingicon.gif";
import "./LoadingScreen.css";

function LoadingScreen() {
  return (
    <div className="loading-screen-container">
      <div className="loading-screen">
        <img src={Gif} alt="Loading" />
      </div>
    </div>
  );
}

export default LoadingScreen;
