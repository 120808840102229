import React, { useState, useEffect } from "react";
import "./NetworkBoxMobile.css";
import { PolygonNetworkBox } from "../../Networks/Polygon";
import { AvalancheNetworkBox } from "../../Networks/Avalanche";
import ChainBoxMobile from '../../ChainBoxMobile';
import { OptimismNetworkBox } from "../../Networks/Optimism";
import { BNBNetworkBox } from "../../Networks/Binancebnb";
import { LineaNetworkBox } from "../../Networks/Linea";
import { EthereumNetworkBox } from "../../Networks/Ethereum"
import { ArbitrumNetworkBox } from "../../Networks/Arbitrum"
import { useChainId } from '@thirdweb-dev/react';

function NetworkBoxMobile(props) {
  const { onNetworkChange, chainBoxmobileProps } = props;
  const chainId = useChainId();
  const [selectedNetwork, setSelectedNetwork] = useState("polygon"); // Default network
  const [isChainBoxOpen, setIsChainBoxOpen] = useState(false);

  // Mapping chain IDs to network names
  const chainIdToNetwork = {
    1: "ethereum",
    137: "polygon",
    43114: "avalanche",
    56: "bnbchain",
    10: "optimism",
    42161: "arbitrum",
    // Add other chain IDs and their corresponding network names here
  };

  useEffect(() => {
    // Update the selected network based on the chain ID
    const networkName = chainIdToNetwork[chainId];
    if (networkName) {
      setSelectedNetwork(networkName);
    }
  }, [chainId]);

  const handleChainBoxClose = () => {
    console.log('ChainBox closed');
    setIsChainBoxOpen(false);
  };

  const handleNetworkBoxClick = () => {
    console.log('NetworkBox clicked');
    setIsChainBoxOpen(true);
  };

  const handleNetworkSelect = (network) => {
    setSelectedNetwork(network);
    if (typeof onNetworkChange === 'function') {
      onNetworkChange(network);
    }
    setIsChainBoxOpen(false);
  };

  return (
    <>
      {isChainBoxOpen && (
        <ChainBoxMobile
          {...ChainBoxMobile}
          onClose={handleChainBoxClose}
          onNetworkChange={handleNetworkSelect}
          selectedNetwork={selectedNetwork}
        />
      )}
      <div onClick={handleNetworkBoxClick}>
        {/* Display the selected network */}
        {selectedNetwork === "polygon" && <PolygonNetworkBox />}
        {selectedNetwork === "avalanche" && <AvalancheNetworkBox />}
        {selectedNetwork === "bnbchain" && <BNBNetworkBox />}
        {selectedNetwork === "optimism" && <OptimismNetworkBox />}
        {selectedNetwork === "linea" && <LineaNetworkBox />}
        {selectedNetwork === "ethereum" && <EthereumNetworkBox />}
        {selectedNetwork === "arbitrum" && <ArbitrumNetworkBox />}
      </div>
    </>
  );
}

export default NetworkBoxMobile;
