[
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "buyer",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "BuyerApprovedForListing",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "listingCreator",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      }
    ],
    "name": "CancelledListing",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "currency",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "pricePerToken",
        "type": "uint256"
      }
    ],
    "name": "CurrencyApprovedForListing",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "listingCreator",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "assetContract",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pricePerToken",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "startTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "endTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "address",
            "name": "listingCreator",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "enum IDirectListings.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "enum IDirectListings.Status",
            "name": "status",
            "type": "uint8"
          },
          {
            "internalType": "bool",
            "name": "reserved",
            "type": "bool"
          }
        ],
        "indexed": false,
        "internalType": "struct IDirectListings.Listing",
        "name": "listing",
        "type": "tuple"
      }
    ],
    "name": "NewListing",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "listingCreator",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "assetContract",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "buyer",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "quantityBought",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "totalPricePaid",
        "type": "uint256"
      }
    ],
    "name": "NewSale",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "listingCreator",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "assetContract",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pricePerToken",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "startTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "endTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "address",
            "name": "listingCreator",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "enum IDirectListings.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "enum IDirectListings.Status",
            "name": "status",
            "type": "uint8"
          },
          {
            "internalType": "bool",
            "name": "reserved",
            "type": "bool"
          }
        ],
        "indexed": false,
        "internalType": "struct IDirectListings.Listing",
        "name": "listing",
        "type": "tuple"
      }
    ],
    "name": "UpdatedListing",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_listingId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_buyer",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "_toApprove",
        "type": "bool"
      }
    ],
    "name": "approveBuyerForListing",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_listingId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_currency",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_pricePerTokenInCurrency",
        "type": "uint256"
      }
    ],
    "name": "approveCurrencyForListing",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_listingId",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_buyFor",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_quantity",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_currency",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_expectedTotalPrice",
        "type": "uint256"
      }
    ],
    "name": "buyFromListing",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_listingId",
        "type": "uint256"
      }
    ],
    "name": "cancelListing",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "pricePerToken",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "startTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "endTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "bool",
            "name": "reserved",
            "type": "bool"
          }
        ],
        "internalType": "struct IDirectListings.ListingParameters",
        "name": "_params",
        "type": "tuple"
      }
    ],
    "name": "createListing",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_startId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_endId",
        "type": "uint256"
      }
    ],
    "name": "getAllListings",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pricePerToken",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "startTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "endTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "address",
            "name": "listingCreator",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "enum IDirectListings.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "enum IDirectListings.Status",
            "name": "status",
            "type": "uint8"
          },
          {
            "internalType": "bool",
            "name": "reserved",
            "type": "bool"
          }
        ],
        "internalType": "struct IDirectListings.Listing[]",
        "name": "listings",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_startId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_endId",
        "type": "uint256"
      }
    ],
    "name": "getAllValidListings",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pricePerToken",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "startTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "endTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "address",
            "name": "listingCreator",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "enum IDirectListings.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "enum IDirectListings.Status",
            "name": "status",
            "type": "uint8"
          },
          {
            "internalType": "bool",
            "name": "reserved",
            "type": "bool"
          }
        ],
        "internalType": "struct IDirectListings.Listing[]",
        "name": "listings",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_listingId",
        "type": "uint256"
      }
    ],
    "name": "getListing",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "listingId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pricePerToken",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "startTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "endTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "address",
            "name": "listingCreator",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "enum IDirectListings.TokenType",
            "name": "tokenType",
            "type": "uint8"
          },
          {
            "internalType": "enum IDirectListings.Status",
            "name": "status",
            "type": "uint8"
          },
          {
            "internalType": "bool",
            "name": "reserved",
            "type": "bool"
          }
        ],
        "internalType": "struct IDirectListings.Listing",
        "name": "listing",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalListings",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_listingId",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "assetContract",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "pricePerToken",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "startTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "endTimestamp",
            "type": "uint128"
          },
          {
            "internalType": "bool",
            "name": "reserved",
            "type": "bool"
          }
        ],
        "internalType": "struct IDirectListings.ListingParameters",
        "name": "_params",
        "type": "tuple"
      }
    ],
    "name": "updateListing",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]