import { ethers, BigNumber } from 'ethers';
import { A as AbstractWallet } from './abstract-6d64547d.browser.esm.js';

class EngineSigner extends ethers.Signer {
  constructor(config, provider) {
    super();
    this.config = {
      ...config,
      engineUrl: config.engineUrl.replace(/\/$/, "")
    };
    // @ts-expect-error Allow passing null here
    ethers.utils.defineReadOnly(this, "provider", provider || null);
  }
  async getAddress() {
    return this.config.backendWalletAddress;
  }
  async signMessage(message) {
    const res = await this.fetch({
      path: "/backend-wallet/sign-message",
      method: "POST",
      body: {
        message
      }
    });
    return res.result;
  }
  async signTransaction(transaction) {
    const tx = await ethers.utils.resolveProperties(transaction);
    const res = await this.fetch({
      path: "/backend-wallet/sign-transaction",
      method: "POST",
      body: {
        ...tx,
        nonce: tx.nonce?.toString(),
        gasLimit: tx.gasLimit?.toString(),
        gasPrice: tx.gasPrice?.toString(),
        value: tx.value?.toString(),
        maxPriorityFeePerGas: tx.maxPriorityFeePerGas?.toString(),
        maxFeePerGas: tx.maxFeePerGas?.toString()
      }
    });
    return res.result;
  }
  async sendTransaction(transaction) {
    if (!this.provider) {
      throw new Error("Sending transactions requires a provider!");
    }
    const chainId = (await this.provider.getNetwork()).chainId;
    const tx = await ethers.utils.resolveProperties(transaction);
    const res = await this.fetch({
      path: `/backend-wallet/${chainId}/send-transaction`,
      method: "POST",
      body: {
        toAddress: tx.to,
        data: tx.data,
        value: tx.value || "0"
      }
    });
    const queueId = res.result.queueId;

    // We return dummy data from here, since we don't have a transaction hash yet
    return {
      hash: queueId,
      confirmations: 0,
      from: this.config.backendWalletAddress,
      nonce: 0,
      gasLimit: BigNumber.from(0),
      value: BigNumber.from(0),
      data: "",
      chainId,
      wait: async confirmations => {
        if (!this.provider) {
          throw new Error("Sending transactions requires a provider!");
        }
        while (true) {
          const {
            result: txRes
          } = await this.fetch({
            path: `/transaction/status/${queueId}`,
            method: "GET"
          });
          switch (txRes.status) {
            case "errored":
              throw new Error(`Transaction errored with reason: ${txRes.errorMessage}`);
            case "cancelled":
              throw new Error(`Transaction execution cancelled.`);
            case "mined":
              const receipt = await this.provider.getTransactionReceipt(txRes.transactionHash);
              return receipt;
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    };
  }
  connect(provider) {
    return new EngineSigner(this.config, provider);
  }
  async fetch(_ref) {
    let {
      path,
      method,
      body
    } = _ref;
    const res = await fetch(`${this.config.engineUrl}${path}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.config.accessToken}`,
        "x-backend-wallet-address": this.config.backendWalletAddress
      },
      ...(body ? {
        body: JSON.stringify(body)
      } : {})
    });
    if (!res.ok) {
      throw new Error(await res.text());
    }
    return res.json();
  }
}

/**
 * @wallet
 */
class EngineWallet extends AbstractWallet {
  constructor(config) {
    super();
    this._signer = new EngineSigner(config);
  }
  async getSigner() {
    return this._signer;
  }
}

export { EngineSigner as E, EngineWallet as a };
