import React, { useEffect } from 'react';
import "../MobileBuyBox/MobileBuyBox.css"

function MobileBuyBox() {
  useEffect(() => {
    // Create a new script element
    const script = document.createElement('script');

    // Set the source of the script to the wert library
    script.src = 'https://javascript.wert.io/wert-3.0.0.js';
    script.async = true;

    // On load, initialize the wert widget
    script.onload = () => {
      const wertWidget = new window.WertWidget({
        partner_id: '01HRA2KV2AG73WHP87F5FTYPP3',
        container_id: 'wert-widget',
        origin: 'https://widget.wert.io',
        theme: 'dark',
        commodities: '[{"commodity":"USDT","network":"polygon"}]',
        lang: 'en',
        color_background: '#000000',
        color_buttons: '#ff00ff',
        color_secondary_buttons: '#ffffff',
      });

      wertWidget.mount();
    };

    // Add the script to the document body
    document.body.appendChild(script);

    // Remove script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);  // The empty array makes this run on mount and unmount only

  return (
    <div className="swap-box-9">
      <div id="wert-widget" className="frame-container-9"></div>
    </div>
  );
}

export default MobileBuyBox;
