import { N as NFTInputOrUriSchema } from './setErc20Allowance-11cc76dc.browser.esm.js';
import { cv as AmountSchema, b6 as BigNumberishSchema, b9 as AddressOrEnsSchema } from './index-6731ff72.browser.esm.js';
import { R as RawDateSchema } from './assertEnabled-3781a788.browser.esm.js';
import { z } from 'zod';

/**
 * @internal
 */
const CommonWrappableSchema = /* @__PURE__ */(() => z.object({
  contractAddress: AddressOrEnsSchema
}))();

/**
 * @internal
 */
const ERC20WrappableSchema = /* @__PURE__ */(() => CommonWrappableSchema.extend({
  quantity: AmountSchema
}))();

/**
 * @internal
 */
const ERC721WrappableSchema = /* @__PURE__ */(() => CommonWrappableSchema.extend({
  tokenId: BigNumberishSchema
}))();

/**
 * @internal
 */
const ERC1155WrappableSchema = /* @__PURE__ */(() => CommonWrappableSchema.extend({
  tokenId: BigNumberishSchema,
  quantity: BigNumberishSchema
}))();

/**
 * @internal
 */
const ERC20RewardSchema = /* @__PURE__ */(() => ERC20WrappableSchema.omit({
  quantity: true
}).extend({
  quantityPerReward: AmountSchema
}))();

/**
 * @internal
 */
const ERC721RewardSchema = ERC721WrappableSchema;

/**
 * @internal
 */
const ERC1155RewardSchema = /* @__PURE__ */(() => ERC1155WrappableSchema.omit({
  quantity: true
}).extend({
  quantityPerReward: BigNumberishSchema
}))();

/**
 * @internal
 */
const ERC20RewardContentsSchema = /* @__PURE__ */(() => ERC20RewardSchema.extend({
  totalRewards: BigNumberishSchema.default("1")
}))();

/**
 * @internal
 */
const ERC721RewardContentsSchema = ERC721RewardSchema;

/**
 * @internal
 */
const ERC1155RewardContentsSchema = /* @__PURE__ */(() => ERC1155RewardSchema.extend({
  totalRewards: BigNumberishSchema.default("1")
}))();

/**
 * @internal
 */
const PackRewardsSchema = /* @__PURE__ */(() => z.object({
  erc20Rewards: z.array(ERC20RewardSchema).default([]),
  erc721Rewards: z.array(ERC721RewardSchema).default([]),
  erc1155Rewards: z.array(ERC1155RewardSchema).default([])
}))();

/**
 * @internal
 */
const PackRewardsOutputSchema = /* @__PURE__ */(() => z.object({
  erc20Rewards: z.array(ERC20RewardContentsSchema).default([]),
  erc721Rewards: z.array(ERC721RewardContentsSchema).default([]),
  erc1155Rewards: z.array(ERC1155RewardContentsSchema).default([])
}))();

/**
 * @internal
 */
const PackMetadataInputSchema = /* @__PURE__ */(() => PackRewardsOutputSchema.extend({
  packMetadata: NFTInputOrUriSchema,
  rewardsPerPack: BigNumberishSchema.default("1"),
  openStartTime: RawDateSchema.default(new Date())
}))();

/**
 * @public
 */

/**
 * @public
 */

/**
 * @public
 */

/**
 * @public
 */

export { ERC20WrappableSchema as E, PackRewardsSchema as P, ERC721WrappableSchema as a, ERC1155WrappableSchema as b, PackRewardsOutputSchema as c, PackMetadataInputSchema as d };
