import React from 'react';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import './styles.css'; // Importing the CSS file

const CookiesDesktop = () => {
 
  const handleAccept = () => {
    console.log('User accepted the cookie policy.');
    // Set a cookie to track that the user accepted the cookie policy
    Cookies.set('userConsent', 'accepted', { expires: 365 });
};

const handleDecline = () => {
    console.log('User declined the cookie policy.');
    // Set a cookie to track that the user declined the cookie policy
    Cookies.set('userConsent', 'declined', { expires: 365 });
};

  return (
    <CookieConsent
    location="bottom"
    buttonText={<button className="button" onClick={handleAccept}>I Agree</button>}
    declineButtonText={<button className="declineButton" onClick={handleDecline}>I Decline</button>}
    cookieName="userConsent"
    expires={150}
    enableDeclineButton={true}
    onDecline={handleDecline}
    style={{
        alignItems: "center",
        background: "#0000006e",
        color: "white",
        display: "flex",
        flexWrap: "wrap",
        position: "fixed",
        width: "90%",
        zIndex: 999,
        bottom: "0px",
        padding: "0px",
        fontSize: "12px",
        borderColor: "transparent",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        marginBottom: "20px",
        borderRadius: "7px",
marginLeft: "25px",
      }}
      buttonStyle={{
        background: 'transparent',
        border: 'none',
        padding: '0',
        margin: '5px 5px 5px 5px',
        boxShadow: 'none'
      }}
      declineButtonStyle={{
        background: 'transparent',
        border: 'none', 
        padding: '0',
        margin: '5px 5px 5px 5px',
        boxShadow: 'none'
      }}

      // Styles that were applied to styled components should be adjusted or moved to the CSS file
    >
      This website uses cookies to enhance your experience in accordance with our <a href="/privacy-policy">Privacy Policy</a>. 
      By clicking "I Agree", you consent to the use of cookies. You can always opt-out if you wish.
    </CookieConsent>
  );
}

export default CookiesDesktop;
