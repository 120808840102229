import React from "react";
import "./Medium.css";

function Medium(props) {
  const { overlapGroup2, followXionFinanceFor, className, link } = props;

  function handleSocialClick(url) {
    window.open(url, '_blank');
  }

  return (
    <div className={`medium ${className || ""}`} onClick={() => handleSocialClick(link)}>
      <div className="overlap-group2" style={{ backgroundImage: `url(${overlapGroup2})` }}>
        <p className="follow-xionfinance-for-3 titilliumweb-normal-white-9-2px">{followXionFinanceFor}</p>
      </div>
    </div>
  );
}

export default Medium;
