import { Core } from '@walletconnect/core';
import { Web3Wallet } from '@walletconnect/web3wallet';
import { utils } from 'ethers';
import EventEmitter from 'eventemitter3';
import { T as TW_WC_PROJECT_ID, W as WC_RELAY_URL, E as EIP155_SIGNING_METHODS } from './wc-fbb7ab15.browser.esm.js';
export { E as EIP155_SIGNING_METHODS } from './wc-fbb7ab15.browser.esm.js';
import { formatJsonRpcResult } from '@walletconnect/jsonrpc-utils';
export { a as AbstractClientWallet, A as AsyncLocalStorage, D as DEFAULT_DAPP_META, c as createAsyncLocalStorage, i as isWalletAnalyticsEnabled, s as setWalletAnalyticsEnabled } from './base-06242fcf.browser.esm.js';
export { w as walletIds } from './walletIds-99db3206.browser.esm.js';
export { W as WagmiConnector } from './WagmiConnector-2f14002d.browser.esm.js';
export { A as AddChainError, C as ChainNotConfiguredError, P as ProviderRpcError, S as SwitchChainError, U as UserRejectedRequestError } from './errors-9f6736cb.browser.esm.js';
export { n as normalizeChainId } from './normalizeChainId-1fb9aedf.browser.esm.js';
export { C as Connector, W as WagmiAdapter } from './connector-05689d68.browser.esm.js';
export { BloctoWallet } from '../evm/wallets/blocto/dist/thirdweb-dev-wallets-evm-wallets-blocto.browser.esm.js';
export { MetaMaskWallet } from '../evm/wallets/metamask/dist/thirdweb-dev-wallets-evm-wallets-metamask.browser.esm.js';
export { g as getInjectedXDEFIProvider } from './getInjectedXDEFIProvider-ce9c378e.browser.esm.js';
export { OKXWallet } from '../evm/wallets/okx/dist/thirdweb-dev-wallets-evm-wallets-okx.browser.esm.js';
export { g as getInjectedOKXProvider } from './getInjectedOKXProvider-f368fd1c.browser.esm.js';
export { CoreWallet } from '../evm/wallets/core-wallet/dist/thirdweb-dev-wallets-evm-wallets-core-wallet.browser.esm.js';
export { g as getInjectedCoreWalletProvider } from './getInjectedCoreWalletProvider-6baee5ae.browser.esm.js';
export { OneKeyWallet } from '../evm/wallets/onekey/dist/thirdweb-dev-wallets-evm-wallets-onekey.browser.esm.js';
export { g as getInjectedOneKeyProvider } from './getInjectedOneKeyProvider-b8c89caf.browser.esm.js';
export { CryptoDefiWallet } from '../evm/wallets/crypto-defi-wallet/dist/thirdweb-dev-wallets-evm-wallets-crypto-defi-wallet.browser.esm.js';
export { g as getInjectedCryptoDefiWalletProvider } from './getInjectedCryptoDefiWalletProvider-3a00394e.browser.esm.js';
export { RabbyWallet } from '../evm/wallets/rabby/dist/thirdweb-dev-wallets-evm-wallets-rabby.browser.esm.js';
export { g as getInjectedRabbyProvider } from './getInjectedRabbyProvider-db56bdef.browser.esm.js';
export { Coin98Wallet } from '../evm/wallets/coin98/dist/thirdweb-dev-wallets-evm-wallets-coin98.browser.esm.js';
export { g as getInjectedCoin98Provider } from './getInjectedCoin98Provider-056e8511.browser.esm.js';
export { g as getInjectedMagicEdenProvider } from './getInjectedMagicEdenProvider-f8a9ec6a.browser.esm.js';
export { g as getInjectedMetamaskProvider } from './getInjectedMetamaskProvider-d2e9e4f3.browser.esm.js';
export { g as getInjectedPhantomProvider } from './getInjectedPhantomProvider-95eb3d4f.browser.esm.js';
export { g as getInjectedRainbowProvider } from './getInjectedRainbowProvider-f52a988e.browser.esm.js';
export { g as getInjectedCoinbaseProvider } from './getInjectedCoinbaseProvider-b24ac791.browser.esm.js';
export { a as assertWindowEthereum } from './assertWindowEthereum-88295886.browser.esm.js';
export { E as EngineSigner, a as EngineWallet } from './engine-cd293e46.browser.esm.js';
export { PaperWallet } from '../evm/wallets/paper-wallet/dist/thirdweb-dev-wallets-evm-wallets-paper-wallet.browser.esm.js';
export { CoinbaseWallet } from '../evm/wallets/coinbase-wallet/dist/thirdweb-dev-wallets-evm-wallets-coinbase-wallet.browser.esm.js';
export { EmbeddedWallet } from '../evm/wallets/embedded-wallet/dist/thirdweb-dev-wallets-evm-wallets-embedded-wallet.browser.esm.js';
export { EthersWallet } from '../evm/wallets/ethers/dist/thirdweb-dev-wallets-evm-wallets-ethers.browser.esm.js';
export { FrameWallet } from '../evm/wallets/frame/dist/thirdweb-dev-wallets-evm-wallets-frame.browser.esm.js';
export { InjectedWallet } from '../evm/wallets/injected/dist/thirdweb-dev-wallets-evm-wallets-injected.browser.esm.js';
export { LocalWallet, isValidPrivateKey } from '../evm/wallets/local-wallet/dist/thirdweb-dev-wallets-evm-wallets-local-wallet.browser.esm.js';
export { SignerWallet } from '../evm/wallets/signer/dist/thirdweb-dev-wallets-evm-wallets-signer.browser.esm.js';
export { MagicLink } from '../evm/wallets/magic/dist/thirdweb-dev-wallets-evm-wallets-magic.browser.esm.js';
export { XDEFIWallet } from '../evm/wallets/xdefi/dist/thirdweb-dev-wallets-evm-wallets-xdefi.browser.esm.js';
export { PhantomWallet } from '../evm/wallets/phantom/dist/thirdweb-dev-wallets-evm-wallets-phantom.browser.esm.js';
export { PrivateKeyWallet } from '../evm/wallets/private-key/dist/thirdweb-dev-wallets-evm-wallets-private-key.browser.esm.js';
export { RainbowWallet } from '../evm/wallets/rainbow-wallet/dist/thirdweb-dev-wallets-evm-wallets-rainbow-wallet.browser.esm.js';
export { SafeWallet } from '../evm/wallets/safe/dist/thirdweb-dev-wallets-evm-wallets-safe.browser.esm.js';
export { SmartWallet, getAllSigners, getAllSmartWallets, getSmartWalletAddress, getUserOpReceipt, isSmartWalletDeployed } from '../evm/wallets/smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-smart-wallet.browser.esm.js';
export { TrustWallet } from '../evm/wallets/trust/dist/thirdweb-dev-wallets-evm-wallets-trust.browser.esm.js';
export { WalletConnect } from '../evm/wallets/wallet-connect/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect.browser.esm.js';
export { WalletConnectV1 } from '../evm/wallets/wallet-connect-v1/dist/thirdweb-dev-wallets-evm-wallets-wallet-connect-v1.browser.esm.js';
export { ZerionWallet } from '../evm/wallets/zerion/dist/thirdweb-dev-wallets-evm-wallets-zerion.browser.esm.js';
export { TokenBoundSmartWallet } from '../evm/wallets/token-bound-smart-wallet/dist/thirdweb-dev-wallets-evm-wallets-token-bound-smart-wallet.browser.esm.js';
export { MagicEdenWallet } from '../evm/wallets/magic-eden/dist/thirdweb-dev-wallets-evm-wallets-magic-eden.browser.esm.js';
export { A as AUTH_TOKEN_LOCAL_STORAGE_NAME, c as AuthProvider, D as DEVICE_SHARE_LOCAL_STORAGE_NAME, a as DEVICE_SHARE_LOCAL_STORAGE_NAME_DEPRECATED, E as EmbeddedWalletSdk, R as RecoveryShareManagement, U as UserWalletStatus, W as WALLET_USER_DETAILS_LOCAL_STORAGE_NAME, b as WALLET_USER_ID_LOCAL_STORAGE_NAME } from './embedded-wallet-1384bc74.browser.esm.js';
import '@thirdweb-dev/chains';
import './abstract-6d64547d.browser.esm.js';
import './defineProperty-d7c057bf.browser.esm.js';
import '@thirdweb-dev/sdk';
import './url-a45219bd.browser.esm.js';
import 'buffer';
import '@thirdweb-dev/crypto';
import './constants-5a6f7f26.browser.esm.js';
import '@account-abstraction/contracts';
import '@paperxyz/sdk-common-utilities';
import 'ethers/lib/utils';

const PREFIX = "__TW__";
class LocalStorage {
  constructor(name) {
    this.name = name;
  }
  getItem(key) {
    return localStorage.getItem(`${PREFIX}/${this.name}/${key}`);
  }
  setItem(key, value) {
    return localStorage.setItem(`${PREFIX}/${this.name}/${key}`, value);
  }
  removeItem(key) {
    return localStorage.removeItem(`${PREFIX}/${this.name}/${key}`);
  }
}

/**
 * @internal
 */
function createLocalStorage(name) {
  return new LocalStorage(name);
}

// connect dapp support through wcv2 protocol

class WalletConnectHandler extends EventEmitter {
  constructor(wallet) {
    super();
    this.wallet = wallet;
  }
}

class WalletConnectV2Handler extends WalletConnectHandler {
  constructor(options, wallet) {
    super(wallet);
    const defaultWCReceiverConfig = {
      walletConnectWalletMetadata: {
        name: "Thirdweb Smart Wallet",
        description: "Thirdweb Smart Wallet",
        url: "https://thirdweb.com",
        icons: ["https://thirdweb.com/favicon.ico"]
      },
      walletConnectV2ProjectId: TW_WC_PROJECT_ID,
      walletConnectV2RelayUrl: WC_RELAY_URL,
      ...(options?.walletConnectReceiver === true ? {} : options?.walletConnectReceiver)
    };
    this._wcMetadata = defaultWCReceiverConfig.walletConnectWalletMetadata;
    this._core = new Core({
      projectId: defaultWCReceiverConfig.walletConnectV2ProjectId,
      relayUrl: defaultWCReceiverConfig.walletConnectV2RelayUrl
    });
  }
  async init() {
    this._wcWallet = await Web3Wallet.init({
      core: this._core,
      metadata: this._wcMetadata
    });
    const sessions = this._wcWallet.getActiveSessions();
    const keys = Object.keys(sessions);
    if (keys[0]) {
      this._session = sessions[keys[0]];
    }
    this._setupWalletConnectEventsListeners();
  }
  async connectApp(wcUri) {
    if (!this._wcWallet) {
      throw new Error("Please, init the wallet before connecting an app.");
    }
    await this._wcWallet.core.pairing.pair({
      uri: wcUri
    });
  }
  async approveSession() {
    if (!this._wcWallet) {
      throw new Error("Please, init the wallet before making session requests.");
    }
    if (!this._activeProposal) {
      throw new Error("Please, pass a valid proposal.");
    }
    const account = await this.wallet.getAddress();
    const {
      id,
      params
    } = this._activeProposal;
    const {
      requiredNamespaces,
      relays
    } = params;
    const namespaces = {};
    Object.keys(requiredNamespaces).forEach(key => {
      const accounts = [];
      const namespace = requiredNamespaces[key];
      if (namespace) {
        namespace.chains?.map(chain => {
          accounts.push(`${chain}:${account}`);
        });
        namespaces[key] = {
          accounts,
          methods: namespace.methods,
          events: namespace.events
        };
      }
    });
    this._session = await this._wcWallet.approveSession({
      id,
      relayProtocol: relays[0]?.protocol,
      namespaces
    });
    this.emit("session_approved");
  }
  async rejectSession() {
    if (!this._wcWallet) {
      throw new Error("Please, init the wallet before making session requests.");
    }
    if (!this._activeProposal) {
      throw new Error("Please, pass a valid proposal.");
    }
    const {
      id
    } = this._activeProposal;
    await this._wcWallet.rejectSession({
      id,
      reason: {
        message: "User rejected methods.",
        code: 5002
      }
    });
  }
  async approveEIP155Request() {
    if (!this._activeRequestEvent) {
      return;
    }
    const {
      topic,
      params,
      id
    } = this._activeRequestEvent;
    const {
      request
    } = params;
    let response;
    switch (request.method) {
      case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
      case EIP155_SIGNING_METHODS.ETH_SIGN:
        const message = this._getSignParamsMessage(request.params);
        const signedMessage = await this.wallet.signMessage(message || ""); // TODO: handle empty message

        response = formatJsonRpcResult(id, signedMessage);
        break;
      // case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
      // case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
      // case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
      //   const {
      //     domain,
      //     types,
      //     message: data,
      //   } = getSignTypedDataParamsData(request.params);
      //   // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
      //   delete types.EIP712Domain;
      //   const signedData = await wallet._signTypedData(domain, types, data);
      //   return formatJsonRpcResult(id, signedData);
      case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
        const signer = await this.wallet.getSigner();
        const sendTransaction = request.params[0];
        const tx = await signer.sendTransaction(sendTransaction);
        const {
          transactionHash
        } = await tx.wait();
        response = formatJsonRpcResult(id, transactionHash);
        break;
      case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
        const signerSign = await this.wallet.getSigner();
        const signTransaction = request.params[0];
        const signature = await signerSign.signTransaction(signTransaction);
        response = formatJsonRpcResult(id, signature);
        break;
      default:
        const error = {
          id,
          jsonrpc: "2.0",
          error: {
            message: "Invalid event.",
            code: 1002
          }
        };
        return this._wcWallet?.respondSessionRequest({
          topic,
          response: error
        });
    }
    return this._wcWallet?.respondSessionRequest({
      topic,
      response
    });
  }
  async rejectEIP155Request() {
    if (!this._activeRequestEvent) {
      return;
    }
    const {
      topic,
      id
    } = this._activeRequestEvent;
    const response = {
      id,
      jsonrpc: "2.0",
      error: {
        message: "User rejected methods.",
        code: 5002
      }
    };
    return this._wcWallet?.respondSessionRequest({
      topic,
      response
    });
  }
  getActiveSessions() {
    if (!this._wcWallet) {
      throw new Error("Please, init the wallet before getting sessions.");
    }
    const sessions = this._wcWallet.getActiveSessions();
    const sessionKeys = Object.keys(sessions);
    if (!sessions || sessionKeys.length === 0) {
      return [];
    }
    const thisSessions = [];
    for (const sessionKey of sessionKeys) {
      const session = sessions[sessionKey];
      if (session) {
        const topic = session.topic;
        const peerMeta = session.peer.metadata;
        thisSessions.push({
          topic,
          peer: {
            metadata: peerMeta
          }
        });
      }
    }
    return thisSessions;
  }
  disconnectSession() {
    if (!this._wcWallet) {
      throw new Error("Please, init the wallet before disconnecting sessions.");
    }
    if (!this._session) {
      return Promise.resolve();
    }
    const params = {
      topic: this._session.topic,
      reason: {
        message: "User disconnected.",
        code: 6000
      }
    };
    return this._wcWallet?.disconnectSession(params);
  }
  _setupWalletConnectEventsListeners() {
    if (!this._wcWallet) {
      throw new Error("Please, init the wallet before making session requests.");
    }
    this._wcWallet.on("session_proposal", proposal => {
      this._activeProposal = proposal;
      this.emit("session_proposal", {
        proposer: {
          metadata: proposal.params.proposer.metadata
        }
      });
    });
    this._wcWallet.on("session_delete", session => {
      this._session = undefined;
      this._activeProposal = undefined;
      this.emit("session_delete", {
        topic: session.topic
      });
    });
    this._wcWallet.on("session_request", async requestEvent => {
      if (!this._session) {
        return;
      }
      const {
        params: requestParams
      } = requestEvent;
      const {
        request
      } = requestParams;
      const {
        params
      } = request;
      switch (request.method) {
        case EIP155_SIGNING_METHODS.ETH_SIGN:
        case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
          this._activeRequestEvent = requestEvent;
          const message = params[0];
          const decodedMessage = new TextDecoder().decode(utils.arrayify(message));
          const paramsCopy = [...params];
          paramsCopy[0] = decodedMessage;
          this.emit("session_request", {
            topic: this._session.topic,
            params: paramsCopy,
            peer: {
              metadata: this._session.peer.metadata
            },
            method: request.method
          });
          return;
        case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
          this._activeRequestEvent = requestEvent;
          this.emit("session_request", {
            topic: this._session.topic,
            params: requestEvent.params.request.params,
            peer: {
              metadata: this._session.peer.metadata
            },
            method: request.method
          });
          return;
        default:
          throw new Error(`WCV2.Method not supported: ${request.method}`);
      }
    });
  }

  /**
   * Gets message from various signing request methods by filtering out
   * a value that is not an address (thus is a message).
   * If it is a hex string, it gets converted to utf8 string
   */
  _getSignParamsMessage(params) {
    const message = params.filter(p => !utils.isAddress(p))[0] || ""; // TODO: handle empty message

    if (utils.isHexString(message)) {
      return utils.toUtf8String(message);
    }
    return message;
  }
}

export { LocalStorage, WalletConnectHandler, WalletConnectV2Handler, createLocalStorage };
