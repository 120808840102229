import React from "react";
import "./styles.css";
import { useChainId } from "@thirdweb-dev/react";

const blockchainExplorers = {
  '137': "https://polygonscan.com/tx/",
  '43114': "https://snowtrace.io/tx/",
  '56': "https://bscscan.com/tx/",
  '42161': "https://arbiscan.io/tx/",
  '1': "https://etherscan.io/tx/",
  '59144': "https://lineascan.build/tx/",
  '10': "https://optimistic.etherscan.io/tx/",
  // Add more blockchain explorers as needed
};

const SuccessfulPopup = ({ onClose, transactionHash, toAmountMinUSD }) => {
  const chainId = useChainId(); // Correctly use useChainId hook
  const explorerUrl = blockchainExplorers[chainId?.toString()] || "https://polygonscan.com/tx/";
  // Construct the share message
  const twitterMessage = encodeURIComponent("🔁 Swapped to $USDT with @xionfinance and it was like discovering a warp drive in the financial cosmos! 🌠💵\n\nLow fees, lightning-fast transactions - it's not just a swap; it's a launchpad to a universe of possibilities. 🛸💰\n\nDive into the future where every transaction with Xion is a gateway to new worlds of opportunity. #USDTRevolution #XionPioneer");

  const twitterUrl = `https://twitter.com/intent/tweet?text=${twitterMessage}`;

  console.log(`Chain ID: ${chainId}`); // Log the chain ID
  console.log(`Transaction Hash: ${transactionHash}`); // Log the transaction hash
  console.log(`Explorer URL: ${explorerUrl}`); // Log the full URL

  const handleViewTransactionClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (explorerUrl && transactionHash) {
      const url = `${explorerUrl}${transactionHash}`;
      console.log(`Opening URL: ${url}`); // Log the URL being opened
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      console.error('Explorer URL or Transaction Hash is undefined');
    }
  };

  return (
    <div className="sfpopup">
      <div className="div">
        <div className="contact-details">
          <div className="transaction">TRANSACTION SUCCESSFUL</div>
        </div>
        <img className="group" alt="Group" src="/img/successfultick.gif" />
        <div className="RB-button">
          <div className="overlap-group-wrapper">
            <div className="overlap-group" onClick={handleViewTransactionClick}>
              <div className="view-transaction">
                View Transaction
              </div>
              <img src="/img/linkicon.png" alt="link icon" className="link-icon" />
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="element-recived">USDT {toAmountMinUSD}</div>
              <img src="/img/usdtlogo.png" alt="USDT Logo" className="usdt-logo" />
            </div>
            <img className="close" alt="X" src="/img/group-3110-3.png" onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up
              onClose(); // Resets everything and stops fetching transaction status
            }} />
          </div>
          <div>
            <div>
              <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="twitter-share-button">
                <img src="/img/share_gradient.png" alt="Share Icon" className="share-icon" />
                Share on X
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulPopup;