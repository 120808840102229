import React, { useState, useEffect, useRef, useCallback } from "react";
import NetworkBoxMobile from "../NetworksMobile/NetworkBox";
import { useAddress, useConnect, useChainId } from "@thirdweb-dev/react";
import TokenComponentMobile from "../TokenComponentMobile";
import "./MobileSwapBox.css";
import ChainBoxMobile from "../ChainBoxMobile";
import { updateNetworkForToken, updateTokenForNetwork } from "../../../FetchThirdWeb/SwapBoxLogic";
import useTokenBalance from "../../../FetchThirdWeb/useTokenBalance";
import useFetchTokensData from '../../../FetchThirdWeb/useFetchPolygonPrice';
import useFetchUSDTPrice from '../../../FetchThirdWeb/useFetchUSDTPrice';
import { Squid } from "@0xsquid/sdk";
import { useSigner, Web3Button } from "@thirdweb-dev/react";
import SuccessfulPopup from "../Popups/Successful";
import FailedPopup from "../Popups/Failed";
import ProcessingPopup from "../Popups/Processing";
import { tokens, networks } from "../../../FetchThirdWeb/NetworkInfo";
import { transactionLogger } from '../../../api/TransactionLogger';

function MobileSwapBox(props) {
  const {
    className,
    tokenBoxData
  } = props;


  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [selectedToken, setSelectedToken] = useState('');
  const [inputAmount, setInputAmount] = useState(0);
  const [isTokenBoxOpen, setIsTokenBoxOpen] = useState(false);
  const [isChainBoxOpen, setIsChainBoxOpen] = useState(false);
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const address = useAddress();
  const { connect, connectors, error: connectError } = useConnect(); // Updated to destructure `connect` and add `connectors`
  const isWalletConnected = !!address; // This will be true if address is not null/undefined
  const { balance, isLoading } = useTokenBalance();
  const tokenPrices = useFetchTokensData();
  const usdtPrice = useFetchUSDTPrice();
  const [lastApiCallTime, setLastApiCallTime] = useState(null);
  const chainId = useChainId();
  const [route, setRoute] = useState(null);
  const [toAmountMinUSD, setToAmountMinUSD] = useState('0.00');
  const [toAmountUSD, setToAmountUSD] = useState('0.00');
  const [fromAmountUSD, setFromAmountUSD] = useState('0.00');
  const [name, setname] = useState('');
  const [exchangeRate, setexchangeRate] = useState('');
  const [usdPrice, setusdPrice] = useState('');
  const [aggregatePriceImpact, setaggregatePriceImpact] = useState('');
  const [aggregateSlippage, setaggregateSlippage] = useState('');
  const [gasPrice, setgasPrice] = useState('');
  const [toAmount, setToAmount] = useState('');
  const [fromAmount, setFromAmount] = useState('');
  const [gasLimit, setgasLimit] = useState('');
  const [maxFeePerGas, setmaxFeePerGas] = useState('');
  const [estimatedRouteDuration, setestimatedRouteDuration] = useState('');
  const [transactionRequest, setTransactionRequest] = useState(null);
  const signer = useSigner();
  const [showProcessingPopup, setShowProcessingPopup] = useState(false);
  const [showSuccessfulPopup, setShowSuccessfulPopup] = useState(false);
  const [showFailedPopup, setShowFailedPopup] = useState(false);
  const [isTransactionConfirmed, setIsTransactionConfirmed] = useState(false);
  const [selectedTokenAddress, setSelectedTokenAddress] = useState('');
  const [isFetchingRoute, setIsFetchingRoute] = useState(false); // New state to track fetching status


  const integratorId = process.env.INTEGRATOR_ID; // Assuming it's stored in your environment variables
  const squid = new Squid({
    baseUrl: "https://v2.api.squidrouter.com",
    integratorId: integratorId,
  });

  useEffect(() => {
    if (route) {
      const toAmountUSD = route.estimate?.toAmountUSD;
      const toAmountMinUSD = route.estimate?.toAmountMinUSD;
      const fromAmountUSD = route.estimate?.fromAmountUSD;
      const estimatedRouteDuration = route.estimate?.estimatedRouteDuration;
      const name = route.estimate.fromToken?.name;
      const exchangeRate = route.estimate?.exchangeRate;
      const usdPrice = route.estimate.fromToken?.usdPrice;
      const aggregatePriceImpact = route.estimate?.aggregatePriceImpact;
      const aggregateSlippage = route.estimate?.aggregateSlippage;
      const gasPrice = route.transactionRequest?.gasPrice;
      const toAmount = route.estimate?.toAmount;
      const fromAmount = route.estimate?.fromAmount;
      const gasLimit = route.transactionRequest?.gasLimit;
      const maxFeePerGas = route.transactionRequest?.maxFeePerGas;

      // Update state with these values
      setToAmountUSD(toAmountUSD || 'Loading');
      setToAmountMinUSD(toAmountMinUSD || 'Loading');
      setFromAmountUSD(fromAmountUSD || 'Loading');
      setestimatedRouteDuration(estimatedRouteDuration || '20 Seconds');
      setname(name || 'N/A');
      setexchangeRate(exchangeRate || 'N/A');
      setusdPrice(usdPrice || 'N/A');
      setaggregatePriceImpact(aggregatePriceImpact || 'N/A');
      setaggregateSlippage(aggregateSlippage || 'N/A');
      setgasPrice(gasPrice || 'N/A');
      setToAmount(toAmount || 'N/A');
      setFromAmount(fromAmount || 'N/A');
      setgasLimit(gasLimit || 'N/A');
      setInputAmount(inputAmount || '00.000000');
      setmaxFeePerGas(maxFeePerGas || 'N/A');

      // Now, correctly define newData object with the updated state values
      const newData = {
        toAmountUSD: toAmountUSD || 'Loading',
        toAmountMinUSD: toAmountMinUSD || 'Loading',
        fromAmountUSD: fromAmountUSD || 'Loading',
        estimatedRouteDuration: estimatedRouteDuration || '20 Seconds',
        name: name || 'N/A',
        exchangeRate: exchangeRate || 'N/A',
        usdPrice: usdPrice || 'N/A',
        aggregatePriceImpact: aggregatePriceImpact || 'N/A',
        aggregateSlippage: aggregateSlippage || 'N/A',
        gasPrice: gasPrice || 'N/A',
        toAmount: toAmount || 'N/A',
        fromAmount: fromAmount || 'N/A',
        gasLimit: gasLimit || 'N/A',
        inputAmount: inputAmount, // Add formattedInputAmount to the data you're passing to FeeBox
        maxFeePerGas: maxFeePerGas,
      };

      // Call the callback function passed from Desktop with the correctly defined newData
      props.onSwapBoxDataUpdate(newData);
    }
  }, [route, props.onSwapBoxDataUpdate]);

  const handleTokenSelect = (tokenAddress) => {
    // Assuming tokenAddress is the address of the selected token
    setSelectedTokenAddress(tokenAddress); // Store the selected token's address
    console.log("Token address updated to:", tokenAddress);
  };

  // Add this useEffect hook
  useEffect(() => {
    console.log("SwapBox received selectedTokenAddress:", selectedTokenAddress);
    setSelectedTokenAddress(selectedTokenAddress);
  }, [selectedTokenAddress]);

  useEffect(() => {
    // Function to set default token address based on selected network
    const setDefaultTokenForNetwork = () => {
      if (chainId) { // Check if chainId is defined
        const defaultToken = tokens.find(token => token.chainId === chainId.toString());
        if (defaultToken) {
          setSelectedTokenAddress(defaultToken.address);
        }
      } else {
        // Optionally, handle the case when chainId is undefined, for example, setting a default state
        console.log("Chain ID is undefined. User may not be connected.");
        // You can set a default state or perform other actions as needed
      }
    };

    setDefaultTokenForNetwork();
  }, [chainId]);

  useEffect(() => {
    const initSquid = async () => {
      const squid = new Squid({
        baseUrl: "https://v2.api.squidrouter.com",
        integratorId: process.env.INTEGRATOR_ID,
      });
      await squid.init();
      window.squid = squid; // Make squid globally available for this example
    };
    initSquid();
  }, []);

  useEffect(() => {
    if (address) {
      console.log('Wallet connected with address: ', address);
    } else {
      console.log('Wallet not connected');
    }
  }, [address]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputAmount > 0) {
        if (!lastApiCallTime || (Date.now() - lastApiCallTime) > 10000) {
          fetchRoute();
        }
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [inputAmount, lastApiCallTime]);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    // Function to fetch and log supported tokens
    const fetchAndLogSupportedTokens = async () => {
      try {
        // Assuming getTokens is a function that fetches the supported tokens from the Squid API
        const supportedTokens = await getTokens();
        console.log("Supported Tokens:", supportedTokens);

        // Example of logging each token's details
        supportedTokens.forEach(token => {
          console.log(`Token Name: ${token.name}, Token Address: ${token.address}`);
        });
      } catch (error) {
        console.error("Failed to fetch supported tokens:", error);
      }
    };

    // Call the function when the component mounts
    fetchAndLogSupportedTokens();
  }, []); // Empty dependency array to run only once on component mount

  const handleTokenChange = (tokenSymbol) => {
    setSelectedToken(tokenSymbol);
    updateNetworkForToken(tokenSymbol, setSelectedNetwork);
  };

  const handleNetworkChange = (networkName) => {
    // Find the network object by name
    const network = networks.find(net => net.name === networkName);
    if (network) {
      setSelectedNetwork(networkName);
      useChainId(network.chainId); // This is just a placeholder. You should use a method to switch networks if your app needs it.
      // Set default token for selected network
      const defaultToken = tokens.find(token => token.chainId === network.chainId);
      if (defaultToken) {
        setSelectedTokenAddress(defaultToken.address);
      }
    }
  };


  const handleAmountChange = (e) => {
    if (!isWalletConnected) {
      console.log("Wallet not connected");
      return; // Early return to prevent action if wallet is not connected
    }
    let amount = e.target.value.replace(",", "."); // Replace comma with dot for consistency
    // Limit input to 6 decimal places
    const decimalIndex = amount.indexOf(".");
    if (decimalIndex !== -1 && amount.substring(decimalIndex + 1).length > 6) {
      amount = amount.substring(0, decimalIndex + 7);
    }
    const amountInWei = parseFloat(amount) * 10 ** 18 || 0;
    setInputAmount(amountInWei);
    setIsFetchingRoute(true); // Set to true when user starts typing
  };

  const fetchRoute = async () => {
    if (inputAmount <= 0 || !address || !selectedTokenAddress) {
      connectWithWallet("injected");
      return;
    }

    const feeBasisPoints = process.env.FEE_BASIS_POINTS;
    const polygonChainId = "137";
    const nativeToken = selectedTokenAddress;
    const polygonUsdt = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";
    const integratorAddress = process.env.INTEGRATOR_ADDRESS;
    const SOURCE = 'SOURCE';

    console.log("Preparing to fetch route with fee collection...");


    // Initialize the Squid client
    await squid.init();

    try {
      const { route } = await squid.getRoute({
        fromChain: chainId,
        toChain: polygonChainId,
        fromToken: nativeToken,
        toToken: polygonUsdt,
        fromAmount: inputAmount.toString(),
        fromAddress: address,
        toAddress: address,
        slippageConfig: {
          autoMode: 1,
        },
        collectFees: {
          integratorAddress: integratorAddress,
          fee: feeBasisPoints,
          feeLocation: SOURCE,
        }
      });
      setRoute(fetchRoute.route); // Store the fetched route in state
      console.log(route);
      setLastApiCallTime(Date.now());
      console.log("Route fetched using Squid SDK");
      setIsFetchingRoute(false); // Set to false once fetching completes or fails

      if (route && route.transactionRequest) {
        setTransactionRequest(route.transactionRequest); // Store transaction request data
        setRoute(route); // Assuming you need the route for other purposes
      }
    } catch (error) {
      console.error("Error fetching route with Squid SDK:", error);

    }
  };

  const statusCheckIntervalRef = useRef(null);
  const [retryCount, setRetryCount] = useState(0); // Use state to track retry attempts
  const MAX_RETRIES = 5; // Maximum number of retries

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      if (statusCheckIntervalRef.current) {
        clearInterval(statusCheckIntervalRef.current);
      }
    };
  }, []);

  const [transactionHash, setTransactionHash] = useState('');

  const executeTransaction = async () => {
    if (!address) {
      // Your existing logic to connect the wallet
    } else {
      try {
        await squid.init();
        const result = await squid.executeRoute({
          route,
          signer,
        });
        setIsTransactionConfirmed(true);
        console.log("Transaction Hash:", result.hash);
        setTransactionHash(result.hash);
        setShowProcessingPopup(true);
        logTransaction({ transactionHash:result.hash, toAmount: toAmount.toString(), chainId: chainId.toString() });

        // Wait for 20 seconds before starting to check transaction status
        setTimeout(() => {
          checkTransactionStatus(result.hash);
        }, 1000); // 20 seconds delay
      } catch (error) {
        console.error(error);
        setShowProcessingPopup(false);
        setShowFailedPopup(true);
      }
    }
  };

  const checkTransactionStatus = async (transactionHash) => {
    // Define the status check function
    const getStatus = async () => {
      try {
        const status = await squid.getStatus({
          transactionId: transactionHash,
          integratorId: integratorId,
          fromChainId: chainId.toString(),
          toChainId: "137",
        });
        console.log(`Transaction status: ${status.squidTransactionStatus}`);

        // Check if the transaction is successful or failed, then clear interval
        if (status.squidTransactionStatus.toUpperCase() === "SUCCESS" || ["NEEDS_GAS", "PARTIAL_SUCCESS", "NOT_FOUND"].includes(status.squidTransactionStatus.toUpperCase())) {
          clearInterval(statusCheckIntervalRef.current);
          setShowProcessingPopup(false);
          status.squidTransactionStatus.toUpperCase() === "SUCCESS" ? setShowSuccessfulPopup(true) : setShowFailedPopup(true);
        }
      } catch (error) {
        console.error("Error checking transaction status:", error);
        setShowProcessingPopup(false);
        setShowFailedPopup(true);
        clearInterval(statusCheckIntervalRef.current); // Stop checking if there's an error
      }
    };

    // Start checking the transaction status immediately and repeat every 20 seconds
    statusCheckIntervalRef.current = setInterval(getStatus, 20000);
  };

  const logTransaction = useCallback(({ transactionHash, toAmount, chainId }) => {
    const formData = { transactionHash, toAmount, chainId };

    const serverlessFunctionURL = "/api/TransactionLogger";

    fetch(serverlessFunctionURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("Transaction logged successfully");
      return response.json();
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error.message);
    });
}, []);


  const resetSwapBox = () => {
    // Clear the status check interval
    if (statusCheckIntervalRef.current) {
      clearInterval(statusCheckIntervalRef.current);
      statusCheckIntervalRef.current = null; // Reset the ref
    }
    // Reset all relevant state variables to their initial values
    setIsTransactionConfirmed(false);
    setShowProcessingPopup(false);
    setShowSuccessfulPopup(false);
    setShowFailedPopup(false);
    // Reset any additional state variables related to the transaction or UI here
    // For example, resetting input amount, selected token/network, etc.
    setInputAmount(0);
    setSelectedToken('');
    setSelectedNetwork('');
    // Reset the route and transaction request if necessary
    setRoute(null);
    setTransactionRequest(null);
    // Add more reset actions as needed based on your component's state
  };


  // Modify the handleClosePopup function to include the reset logic
  const handleClosePopup = () => {
    setShowProcessingPopup(false);
    setShowSuccessfulPopup(false);
    setShowFailedPopup(false);
    resetSwapBox(); // Call the reset function to clear state and prepare for a new swap
  };

  return (
    <div className="mobile-swap-box">
      <div className="frame-container-1">
        <div className="frame-1-1">
          <div className="place-1 titilliumweb-normal-hint-of-red-12-6px">FROM</div>
          <NetworkBoxMobile
            onClick={() => setIsChainBoxOpen(true)} selectedNetwork={selectedNetwork} onNetworkChange={handleNetworkChange} />
        </div>
        {isChainBoxOpen && <ChainBoxMobile onClose={() => setIsChainBoxOpen(false)} onNetworkSelect={handleNetworkChange} selectedNetwork={selectedNetwork} onNetworkChange={handleNetworkChange} />}


        <div className="frame-3409">
          <div className="tb-1">
            <div className="flex-row-2">
              <div className="flex-col-2">
                <div className="send-1 titilliumweb-normal-hint-of-red-12-6px">SEND</div>
                <div className="usdvalue titilliumweb-normal-ceramic-9-5px">${fromAmountUSD}</div>
                <div className="walletbalance titilliumweb-normal-ceramic-9-5px">BALANCE: {isLoading ? "Loading..." : balance}</div>
              </div>
            </div>
            <div className="flex-col-3">
              <div className="overlap-group-container">
                <input
                  type="number"
                  min="0" // Prevents entering values less than 0
                  placeholder="Enter amount"
                  disabled={!isWalletConnected} // Disable input when wallet is not connected
                  onChange={handleAmountChange}
                  className="text-1 titilliumweb-bold-desert-storm-37-6px"
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                />
                <div className="frame-3404">
                  <TokenComponentMobile handleTokenSelect={handleTokenSelect} onTokenAddressChange={setSelectedTokenAddress} initialSelectedToken={selectedToken} onTokenChange={handleTokenChange} tokenBoxData={tokenBoxData} isTokenBoxOpen={isTokenBoxOpen} onClose={() => setIsTokenBoxOpen(false)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="frame-4" src="/img/frame-4.png" alt="Frame 4" />
        <div className="frame-2-1">
          <div className="to-1 titilliumweb-normal-hint-of-red-12-6px">TO</div>
          <div className="usdt-6">
            <div className="overlap-group-19">
              <img className="path-538-3" src={"/img/usdtlogo.png"} alt="Path 538" />
              <div className="usdt-7 titilliumweb-normal-white-14px">
                <span className="titilliumweb-normal-havelock-blue-14px">USDT</span>
                <span className="titilliumweb-normal-hint-of-red-14px">POLYGON</span>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-3410">
          <div className="bb-1">
            <div className="overlap-group-20">
              <div className="send-2 titilliumweb-normal-hint-of-red-12-6px">RECEIVE:</div>
              <div className="text titilliumweb-bold-desert-storm-37-6px">{isFetchingRoute ? "Fetching Route..." : toAmountMinUSD}</div>
            </div>
            <div className="usdvaluerecieve titilliumweb-normal-ceramic-9-5px">${toAmountUSD}</div>
          </div>
        </div>
        <div className="frame-6-3">
          <div className="ab-3" onClick={isWalletConnected ? executeTransaction : undefined}>
            <div className="overlap-group-21" style={{ backgroundImage: `url(/img/rectangle-238-1@2x.png)` }}>
              <div className="approve-3 titilliumweb-bold-ceramic-18-8px">{isWalletConnected ? "Approve and Swap" : "Connect Wallet"}
              </div>
            </div>
          </div>
        </div>
        <div className="powered-by"> Powered By</div>
        <img className="squid" src="/img/Squid-Icon-Logo-Purple.svg" alt="Squid"/>
      </div>
      {isTransactionConfirmed && showProcessingPopup && (
        <ProcessingPopup estimatedRouteDuration={estimatedRouteDuration} />
      )}
      {showSuccessfulPopup && <SuccessfulPopup onClose={handleClosePopup} transactionHash={transactionHash} fromAmount={fromAmount} toAmountMinUSD={toAmountMinUSD} />}
      {showFailedPopup && <FailedPopup onClose={handleClosePopup} />}
    </div>
  );
}

export default MobileSwapBox;

