import React from "react";
import "./Usdt.css";

function Usdt(props) {
  const { path538 } = props;
  const defaultImage = "/img/path-538-1@2x.png";  // Provide a default path

  return (
    <div className="usdt">
      <div className="overlap-group-9">
        <img className="path-538" src={path538 || defaultImage} alt="Path 538" />
        <div className="overlap-group1-4">
          <div className="usdt-1 titilliumweb-normal-havelock-blue-14px">USDT</div>
          <div className="polygon titilliumweb-normal-hint-of-red-7-3px">POLYGON</div>
        </div>
      </div>
    </div>
  );
}

export default Usdt;
