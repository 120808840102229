import React, { useState, useCallback } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import "./Desktop.css";
import { BurgerMenu } from "../../../components/Burger/BurgerMenu";
import { useHistory } from 'react-router-dom'; // Import useHistory
import Footer from "../../../components/Footer";

function TermsAndConditionsD(props) {
  const {
    desktop1,
    logo,
    line34,
    copyright,
  } = props;

  const history = useHistory();

  const handleMenuOptionSelect = (boxName) => {
    history.push('/', { selectedBox: boxName });
  };


  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  return (
    <div className="terms-d">
      <div className="terms-and-conditions screen" style={{ backgroundImage: `url("img/BlackBG.png")` }}>
        <div className="frame-3414">
          <div className="nav">
            <a href="/">
              <img className="logo" src={logo} alt="LOGO" />
            </a>
            <div className="img-wrapper">
              <img className="group-2" alt="Group" src="/img/Burger.png" onClick={handleBurgerMenuClick} />
            </div>
            <div className="my-custom-wrapper">
              <div className="my-custom-class">
                <ConnectWallet btnTitle="Connect Wallet" theme={"dark"} />
              </div>
            </div>
            <BurgerMenu isOpen={isBurgerMenuOpen} onClose={handleBurgerMenuClick} />
          </div>

          <div className="terms-content">
            <h1>Xion Finance dApp Terms and Conditions</h1>
            <p>Last Updated: 08 October 2023</p>

            <p>Introduction</p>
            <p>Welcome to Xion Finance, a decentralized application ("dApp") that provides a Swap & Bridge Protocol. This platform allows users to connect their web3 wallets to swap tokens across different chains and networks. Please note that this dApp is not a regulated financial service provider. By using the services provided by Xion Finance, you agree to comply with these Terms and Conditions, which are designed to ensure that you use our services responsibly.</p>

            <p>Acceptance of Terms</p>
            <p>By accessing or using the Xion Finance dApp, you signify your agreement to be bound by these Terms and Conditions. If you do not agree to these terms, you are prohibited from accessing or using the dApp. Your continued use signifies your acceptance of any changes to these terms.</p>

            <p>Eligibility</p>
            <p>You must be at least 18 years old to use the Xion Finance dApp. By agreeing to these Terms, you represent and warrant that you are at least 18 years old and are otherwise capable of entering into a legally binding agreement.</p>

            <p>User Responsibility</p>
            <p>You are solely responsible for securing your Web3 wallet, as well as any actions that occur through your wallet. This includes any swaps or bridges you perform. Xion Finance is not responsible for any losses or issues that may arise from your use of the dApp, including but not limited to financial losses, data losses, or unauthorized access to your wallet.</p>

            <p>Swaps and Bridges</p>
            <p>Xion Finance allows users to swap tokens from different chains to other tokens and networks. You acknowledge that you are solely responsible for all swaps and bridges you perform on the dApp. Xion Finance is not liable for any losses, financial or otherwise, incurred as a result of using these features.</p>

            <p>Investing and APR</p>
            <p>The dApp may offer features that allow you to invest in tokens to earn an Annual Percentage Rate (APR). Investing in cryptocurrencies carries inherent risks, and you acknowledge that you are solely responsible for any losses or gains. Xion Finance makes no guarantee regarding the APR or the performance of any investment.</p>

            <p>Third-Party Services</p>
            <p>The dApp allows for the purchase of USDT on the Polygon network through a regulated service provider and also provides a withdrawal option facilitated by a regulated service provider. Xion Finance is not responsible for the actions or services provided by these third-party service providers, nor do we endorse them.</p>

            <p>No Financial Advice</p>
            <p>Xion Finance is not a financial advisor or a regulated financial service provider. Any information provided by the dApp should not be considered financial advice. Consult with a qualified professional for financial advice before making any investment decisions.</p>

            <p>Limitation of Liability</p>
            <p>To the fullest extent permitted by applicable law, Xion Finance shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (a) your use or inability to use the service; (b) unauthorized access to your account.</p>

            <p>Security and Scams</p>
            <p>We implement a variety of security measures to maintain the highest safety when utilsing the Xion Finance (dApp). However, you acknowledge that there are risks, and you agree that you are solely responsible for securing your web3 wallet and private keys. Xion Finance will not be responsible for any losses resulting from scams, phishing websites, or other fraudulent activities.</p>

            <p>Intellectual Property</p>
            <p>All content, including but not limited to text, graphics, logos, and software, is the property of Xion Finance and is protected by international copyright laws. Unauthorized use or duplication is strictly prohibited.</p>

            <p>Termination</p>
            <p>Xion Finance reserves the right to terminate or suspend access to the dApp without prior notice for conduct that violates these Terms or is harmful to other users of the dApp, us, or third parties, or for any other reason.</p>

            <p>Dispute Resolution</p>
            <p>Any dispute arising out of these Terms and Conditions will be resolved through arbitration in Singapore, in accordance with the rules of the Singapore International Arbitration Centre.</p>

            <p>Changes to Terms</p>
            <p>Xion Finance reserves the right to change, modify, or revise these Terms and Conditions at any time. Any changes will be effective immediately upon posting, and your continued use of the dApp signifies your acceptance of the revised terms.</p>

            <p>Governing Law</p>
            <p>These Terms and Conditions are governed by the laws of Singapore. Any disputes arising from these terms will be resolved in the jurisdiction of Singapore.</p>
            <br></br>
            <br></br>
            <h1>Hourly USDt Giveaway Terms and Conditions</h1>

            <p>Introduction</p>

            <p>Welcome to the Hourly USDt Giveaway, a decentralized application ("dApp") provided by Xion Finance. The Hourly USDt Giveaway allows users to participate in a competitive environment to win USDT rewards. By accessing or using the Hourly USDt Giveaway, you agree to comply with these additional Terms and Conditions, which are designed to ensure that you use our services responsibly.</p>

            <p>Acceptance of Terms</p>

            <p>By accessing or using the Hourly USDt Giveaway, you signify your agreement to be bound by these Terms and Conditions. If you do not agree to these terms, you are prohibited from accessing or using the giveaway. Your continued use signifies your acceptance of any changes to these terms.</p>

            <p>Eligibility</p>

            <p>You must be at least 18 years old to participate in the Hourly USDt Giveaway. By agreeing to these Terms, you represent and warrant that you are at least 18 years old and are otherwise capable of entering into a legally binding agreement. Users under the age of 18 are strictly prohibited from accessing or using the giveaway.</p>

            <p>User Responsibility</p>

            <p>You are solely responsible for your actions while participating in the Hourly USDt Giveaway. This includes understanding the risks involved in participating, including the risk of financial loss. Xion Finance is not responsible for any losses, disputes, or issues that may arise from your participation in the giveaway.</p>

            <p>Hourly USDt Giveaway Mechanics</p>

            <p>The Hourly USDt Giveaway operates on a competitive basis where users click a button to participate. The giveaway runs on a timer, and users must click the button within the specified time frame to be eligible to win USDT rewards. By participating in the giveaway, you acknowledge and agree to abide by the rules and mechanics set forth by Xion Finance.</p>

            <p>Risk During Red Zone</p>

            <p>During the "red zone" period of the countdown timer, users may experience delays or issues with transaction processing. Clicking the button during this period carries the risk that your transaction may not be processed in time, and Xion Finance will not be held liable for any losses or issues that arise as a result.</p>

            <p>Allocation of Funds</p>

            <p>The Hourly USDt Giveaway rewards winners with USDT from the prize pool. A portion of the funds collected from successful transactions is allocated to the prize pool, while the remainder is used to facilitate gas fees, API calls, servers, and operational expenses related to the giveaway. By participating in the giveaway, you acknowledge and agree to this allocation of funds.</p>

            <p>No Financial Advice</p>

            <p>Xion Finance does not provide financial advice regarding participation in the Hourly USDt Giveaway. Any information provided by Xion Finance should not be considered financial advice. Users are encouraged to conduct their own research and seek professional advice before participating in the giveaway.</p>

            <p>Limitation of Liability</p>

            <p>To the fullest extent permitted by applicable law, Xion Finance shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, resulting from your participation in the Hourly USDt Giveaway.</p>

            <p>Compliance with Laws</p>

            <p>Users are responsible for ensuring that their participation in the Hourly USDt Giveaway complies with all applicable laws and regulations in their jurisdiction. Xion Finance is not responsible for any legal consequences arising from non-compliance with local laws.</p>

            <p>Termination</p>

            <p>Xion Finance reserves the right to terminate or suspend access to the Hourly USDt Giveaway without prior notice for conduct that violates these Terms or is harmful to other users of the giveaway, us, or third parties, or for any other reason.</p>

            <p>Changes to Terms</p>

            <p>Xion Finance reserves the right to change, modify, or revise these Terms and Conditions for the Hourly USDt Giveaway at any time. Any changes will be effective immediately upon posting, and your continued use of the giveaway signifies your acceptance of the revised terms.</p>

            <p>Governing Law</p>

            <p>These Terms and Conditions for the Hourly USDt Giveaway are governed by the laws of Singapore. Any disputes arising from these terms will be resolved in the jurisdiction of Singapore.</p>

            <p>Contact</p>

            <p>For any questions about these additional Terms and Conditions, please contact us at hello@xion.finance.</p>

          </div>

          <Footer
            onMenuOptionSelect={handleMenuOptionSelect} />
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsD;
