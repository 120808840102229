import { useCallback } from "react";
import React from "react";
import "./Burger.css";

export const BurgerMenu = ({ isOpen, onClose }) => {

  // Define the onClick event handler function
  const handleXionPayClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);

  // Define the onClick event handler function
  const handleCoingeckoClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://www.coingecko.com/en/coins/xion-finance", "_blank");
  }, []);

  // Define the onClick event handler function
  const handleCoinmarketcapClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://coinmarketcap.com/currencies/xion-finance/", "_blank");
  }, []);

  // Define the onClick event handler function
  const handlePolygonScanClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://polygonscan.com/token/0x9EB8A789Ed1Bd38D281962b523349d5D17A37d47", "_blank");
  }, []);

  // Define the onClick event handler function
  const handleEtherScanClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://etherscan.io/", "_blank");
  }, []);

  // Define the onClick event handler function
  const handleBSCScanClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://bscscan.com/", "_blank");
  }, []);



  return (
    <div className={`overlap-b-group${isOpen ? ' open' : ''}`}>
      <div className="burger-menu-container">
        <div className="burger-2">
          <img className="burger-outline" alt="Burger Outline" src="/img/Desktop-ipad-square-menu.png" />
          <img className="burger" alt="X" src="/img/group-3110-3.png" onClick={onClose} />
        </div>
        <div className="MENU">
          <div className="div">
            <img className="xionpay" alt="XionPay" src="/img/xionpay.png" onClick={handleXionPayClick} />
            <div className="overlap-group">
              <div className="frame">
                <div className="text-wrapper">EXPLORERS</div>
                <img className="vector-2" alt="PolygonScan" src="/img/polyscanlogo.svg" onClick={handlePolygonScanClick} />
                <img className="group-42" alt="Vector" src="/img/wcglogo.png" onClick={handleCoingeckoClick} />
                <img className="imgmenu" alt="Group" src="/img/group.png" onClick={handleEtherScanClick} />
                <img className="group-21" alt="Group" src="/img/group-3113.png" onClick={handleBSCScanClick} />
                <img className="line" alt="Line" src="/img/line-36.png" />
              </div>
              <img className="vector" alt="CoinmarketCap" src="/img/wcmclogo.png" onClick={handleCoinmarketcapClick} />
              <img className="spaceman" alt="Spaceman" src="/img/spaceman-1.png" />
            </div>
            <div className="group-3">
              <a href="/nft-xion-astronauts">
                <div className="text-wrapper-2">NFT CLAIM ZONE</div>
                <img className="nftimage" alt="Frame" src="/img/frame-3439.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};