import { _ as _defineProperty } from '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import { W as WagmiAdapter } from '../../../../dist/connector-05689d68.browser.esm.js';
import { a as AbstractClientWallet } from '../../../../dist/base-06242fcf.browser.esm.js';
import { w as walletIds } from '../../../../dist/walletIds-99db3206.browser.esm.js';
import { g as getInjectedPhantomProvider } from '../../../../dist/getInjectedPhantomProvider-95eb3d4f.browser.esm.js';
import 'eventemitter3';
import '@thirdweb-dev/chains';
import '../../../../dist/abstract-6d64547d.browser.esm.js';
import 'ethers';
import '@thirdweb-dev/sdk';
import '../../../../dist/url-a45219bd.browser.esm.js';

/**
 * Wallet interface to connect [Phantom Wallet](https://phantom.app/)
 *
 * @example
 * ```javascript
 * import { PhantomWallet } from "@thirdweb-dev/wallets";
 *
 * const wallet = new PhantomWallet();
 *
 * wallet.connect();
 * ```
 *
 * @wallet
 */
class PhantomWallet extends AbstractClientWallet {
  /**
   * @internal
   */
  get walletName() {
    return "Phantom";
  }

  /**
   * Create a `PhantomWallet` instance
   * @param options -
   * The `options` object includes the following properties
   *
   * ### clientId (recommended)
   * Provide `clientId` to use the thirdweb RPCs for given `chains`
   *
   * You can create a client ID for your application from [thirdweb dashboard](https://thirdweb.com/create-api-key).
   *
   * ### chains (optional)
   * Provide an array of chains you want to support.
   *
   * Must be an array of `Chain` objects, from the [`@thirdweb-dev/chains`](https://www.npmjs.com/package/\@thirdweb-dev/chains) package.
   *
   * Defaults to our [default chains](/react/react.thirdwebprovider#default-chains).
   *
   * ### dappMetadata (optional)
   * Information about your app that the wallet will display when your app tries to connect to it.
   *
   * Must be an object containing `name`, `url`, and optionally `description` and `logoUrl` properties.
   *
   * ```javascript
   * import { PhantomWallet } from "@thirdweb-dev/wallets";
   *
   * const walletWithOptions = new PhantomWallet({
   *   dappMetadata: {
   *     name: "thirdweb powered dApp",
   *     url: "https://thirdweb.com",
   *     description: "thirdweb powered dApp",
   *     logoUrl: "https://thirdweb.com/favicon.ico",
   *   },
   * });
   * ```
   */
  constructor(options) {
    super(PhantomWallet.id, options);
    this.isInjected = !!getInjectedPhantomProvider();
  }
  async getConnector() {
    if (!this.connector) {
      const {
        PhantomConnector
      } = await import('../../../connectors/phantom/dist/thirdweb-dev-wallets-evm-connectors-phantom.browser.esm.js');
      const phantomConnector = new PhantomConnector({
        chains: this.chains,
        connectorStorage: this.walletStorage,
        options: {
          shimDisconnect: true
        }
      });
      this.phantomConnector = phantomConnector;
      this.connector = new WagmiAdapter(phantomConnector);
    }
    return this.connector;
  }
}
/**
 * @internal
 */
/**
 * @internal
 */
/**
 * @internal
 */
/**
 * @internal
 */
/**
 * @internal
 */
_defineProperty(PhantomWallet, "meta", {
  name: "Phantom",
  iconURL: "ipfs://bafybeibkpca5nwxpsjrtuxmz2ckb5lyc2sl2abg5f7dnvxku637vvffjti",
  urls: {
    chrome: "https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa"
    // not specifiying theme because they can't be used to connect
    // android: "https://play.google.com/store/apps/details?id=app.phantom",
    // ios: "https://apps.apple.com/us/app/phantom-crypto-wallet/id1598432977",
  }
});
/**
 * @internal
 */
_defineProperty(PhantomWallet, "id", walletIds.phantom);

export { PhantomWallet };
