import React, { useState } from "react";
import { useSwitchChain } from "@thirdweb-dev/react";
import "./ChainBox.css";
import { Polygon, Avalanche, Optimism, Linea, Arbitrum, Binance, Ethereum } from "@thirdweb-dev/chains";

function ChainBox(props) {
  const { onClose, onNetworkChange, selectedNetwork } = props;
  const [searchValue, setSearchValue] = useState('');
  const switchChain = useSwitchChain();

  const handleNetworkSelect = async (networkChainId, networkName) => {
    try {
      await switchChain(networkChainId);
      if (typeof onNetworkChange === 'function') {
        onNetworkChange(networkName);
      }
      console.log("Switched to network:", networkName);
    } catch (error) {
      console.error('Error switching network:', error);
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  return (
    <div className="container-center-horizontal">
      <div className="chain-box-desktop screen">
        <div className="overlap-group-189" style={{ backgroundImage: `url('/img/b-2-line.png')` }}>
          <div className="flex-row-112">
            <div className="select-source-chain-5">Available Networks</div>
            <div className="info-token-selection">Note: Change networks in your wallet; it will update automatically.</div>
            <img className="x" src='/img/x@2x.png' alt="X" onClick={() => onClose(false)} />
          </div>
          <div className="overlap-group-114">
            <input
              className="search-chain-by-name-or-chain-id"
              name="searchchainbynameorchainid"
              placeholder="Search chain by name or chain ID"
              type="text"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="scroll-group-1">
          <div className="overlap-group-container-115">
         {/* Polygon */}
         {'polygon'.startsWith(searchValue) &&
            <div
              className={`overlap-group-116 ${selectedNetwork === "polygon" ? "active" : ""}`}
              onClick={() => handleNetworkSelect(Polygon.chainId, 'polygon')}
            >
                <img className="polygonLogo" src='/img/poly.svg' alt="polygon logo" />
                <div className="Polygon-Mainnet titilliumweb-normal-white-17px">Polygon Mainnet</div>
              </div>
            }
          </div>
          <div className="overlap-group-container-115">
          {/* Arbitrum */}
          {'arbitrum'.startsWith(searchValue) &&
            <div
              className={`overlap-group210 ${selectedNetwork === "arbitrum" ? "active" : ""}`}
              onClick={() => handleNetworkSelect(Arbitrum.chainId, 'arbitrum')}
            >
                <img className="arbitrumLogo" src='/img/arbitrum.png' alt="arbitrum logo" />
                <div className="Avalanche-Mainnet titilliumweb-normal-white-17px">Arbitrum</div>
              </div>
            }
          </div>
          <div className="overlap-group-container-115">
          {/* Avalanche */}
          {'avalanche'.startsWith(searchValue) &&
            <div
              className={`overlap-group-116 ${selectedNetwork === "avalanche" ? "active" : ""}`}
              onClick={() => handleNetworkSelect(Avalanche.chainId, 'avalanche')}
            >
                <img className="avalancheLogo" src='/img/avallogo.png' alt="avalanche logo" />
                <div className="Avalanche-Mainnet titilliumweb-normal-white-17px">Avalanche Mainnet</div>
              </div>
            }
          </div>
          <div className="overlap-group-container-115">
          {/* Binance Smart Chain */}
          {'bnb'.startsWith(searchValue) &&
            <div
              className={`overlap-group210 ${selectedNetwork === "bnbchain" ? "active" : ""}`}
              onClick={() => handleNetworkSelect(Binance.chainId, 'bnbchain')}
            >
                <img className="bnbLogo" src='/img/bnblogo.png' alt="bnb smart chain logo" />
                <div className="Avalanche-Mainnet titilliumweb-normal-white-17px">BNB Chain</div>
              </div>
            }
          </div>
          <div className="overlap-group-container-115">
          {/* Ethereum */}
          {'ethereum'.startsWith(searchValue) &&
            <div
              className={`overlap-group-116 ${selectedNetwork === "ethereum" ? "active" : ""}`}
              onClick={() => handleNetworkSelect(Ethereum.chainId, 'ethereum')}
            >
                <img className="ethereumLogo" src='/img/ethlogo.png' alt="ethereum logo" />
                <div className="Avalanche-Mainnet titilliumweb-normal-white-17px">Ethereum</div>
              </div>
            }
          </div>
          <div className="overlap-group-container-115">
          {/* Linea */}
          {'linea'.startsWith(searchValue) &&
            <div
              className={`overlap-group-116 ${selectedNetwork === "linea" ? "active" : ""}`}
              onClick={() => handleNetworkSelect(Linea.chainId, 'linea')}
            >
                <img className="lineaLogo" src='/img/linea.svg' alt="linea logo" />
                <div className="Avalanche-Mainnet titilliumweb-normal-white-17px">Linea</div>
              </div>
            }
          </div>
          <div className="overlap-group-container-115">
          {/* Optimism */}
          {'optimism'.startsWith(searchValue) &&
            <div
              className={`overlap-group-116 ${selectedNetwork === "optimism" ? "active" : ""}`}
              onClick={() => handleNetworkSelect(Optimism.chainId, 'optimism')}
            >
                <img className="optimismLogo" src='/img/oplogo.png' alt="optimism logo" />
                <div className="Avalanche-Mainnet titilliumweb-normal-white-17px">Optimism</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChainBox;