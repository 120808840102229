import { A as AbstractWallet } from '../../../../dist/abstract-6d64547d.browser.esm.js';
import { ethers } from 'ethers';
import { getChainProvider } from '@thirdweb-dev/sdk';
import '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import 'eventemitter3';
import '../../../../dist/url-a45219bd.browser.esm.js';
import '@thirdweb-dev/chains';

/**
 * Wallet interface to connect using a Private Key
 *
 * @example
 * ```ts
 * import { PrivateKeyWallet } from "@thirdweb-dev/wallets";
 *
 * // can be any ethers.js signer
 * const privateKey = process.env.PRIVATE_KEY;
 * const wallet = new PrivateKeyWallet(privateKey);
 * ```
 *
 * @wallet
 */
class PrivateKeyWallet extends AbstractWallet {
  /**
   * Create instance of `PrivateKeyWallet`
   *
   * @param privateKey - The private key to use for signing transactions.
   *
   * @param chain - The chain or rpc url to connect to when querying the blockchain directly through this wallet.
   *
   * @param secretKey -
   * Provide `secretKey` to use the thirdweb RPCs for given `chain`
   *
   * You can create a secret key from [thirdweb dashboard](https://thirdweb.com/create-api-key).
   */
  constructor(privateKey, chain, secretKey) {
    super();
    this._signer = new ethers.Wallet(privateKey, chain ? getChainProvider(chain, {
      secretKey
    }) : undefined);
  }

  /**
   * Get the [ethers.js signer](https://docs.ethers.io/v5/api/signer/) object used by the wallet
   */
  async getSigner() {
    return this._signer;
  }
}

export { PrivateKeyWallet };
