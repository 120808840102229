import { _ as _defineProperty } from '../../../../dist/defineProperty-d7c057bf.browser.esm.js';
import { W as WagmiAdapter } from '../../../../dist/connector-05689d68.browser.esm.js';
import { a as AbstractClientWallet } from '../../../../dist/base-06242fcf.browser.esm.js';
import { T as TW_WC_PROJECT_ID } from '../../../../dist/wc-fbb7ab15.browser.esm.js';
import { w as walletIds } from '../../../../dist/walletIds-99db3206.browser.esm.js';
import 'eventemitter3';
import '@thirdweb-dev/chains';
import '../../../../dist/abstract-6d64547d.browser.esm.js';
import 'ethers';
import '@thirdweb-dev/sdk';
import '../../../../dist/url-a45219bd.browser.esm.js';

/**
 * Wallet interface to connect a wallet using [WalletConnect](https://docs.walletconnect.com/) protocol by either opening the official WalletConnect Modal or by displaying a custom QR Code.
 *
 * @example
 * ```javascript
 * import { WalletConnect } from "@thirdweb-dev/wallets";
 *
 * const wallet = new WalletConnect();
 *
 * wallet.connect();
 * ```
 *
 * @wallet
 */
class WalletConnect extends AbstractClientWallet {
  get walletName() {
    return "WalletConnect";
  }
  /**
   *
   * @param options -
   * The `options` object includes the following properties:
   *
   * ### projectId (recommended)
   * Your project's unique identifier. It can be obtained at [cloud.walletconnect.com](https://cloud.walletconnect.com). It is highly recommended to use your own project id and only use the default one for testing purposes.
   *
   * It enables the following functionalities within WalletConnect's web3modal:
   *
   * - wallet and chain logos
   * - optional WalletConnect RPC
   * - support for all wallets from our Explorer and WalletConnect v2 support
   *
   * Defaults to thirdweb's common project id.
   *
   * ### clientId (recommended)
   * Provide `clientId` to use the thirdweb RPCs for given `chains`
   *
   * You can create a client ID for your application from [thirdweb dashboard](https://thirdweb.com/create-api-key).
   *
   * ### chains (optional)
   * Provide an array of chains you want to support.
   *
   * Must be an array of `Chain` objects, from the [`@thirdweb-dev/chains`](https://www.npmjs.com/package/\@thirdweb-dev/chains) package.
   *
   * Defaults to our [default chains](/react/react.thirdwebprovider#default-chains).
   *
   *
   * ### dappMetadata
   * Information about your app that the wallet will display when your app tries to connect to it.
   *
   * Must be an object containing `name`, `url`, and optionally `description` and `logoUrl` properties.
   *
   * ```javascript
   * import { WalletConnect } from "@thirdweb-dev/wallets";
   *
   * const walletWithOptions = new WalletConnect({
   *   dappMetadata: {
   *     name: "thirdweb powered dApp",
   *     url: "https://thirdweb.com",
   *     description: "thirdweb powered dApp",
   *     logoUrl: "https://thirdweb.com/favicon.ico",
   *   },
   * });
   * ```
   *
   * ### qrcode (optional)
   * Whether to display the Wallet Connect QR code Modal or not.
   *
   * Must be a `boolean`. Defaults to `true`.
   *
   * ### qrModalOptions
   * WalletConnect's [options](https://docs.walletconnect.com/advanced/walletconnectmodal/options) to customize the QR Code Modal.
   *
   */
  constructor(options) {
    super(options?.walletId || WalletConnect.id, options);
    _defineProperty(this, "_maybeThrowError", error => {
      if (error) {
        throw error;
      }
    });
    _defineProperty(this, "_onConnect", data => {
      this._provider = data.provider;
      if (!this._provider) {
        throw new Error("WalletConnect provider not found after connecting.");
      }
    });
    _defineProperty(this, "_onDisconnect", () => {
      this._removeListeners();
    });
    _defineProperty(this, "_onChange", async payload => {
      if (payload.chain) ; else if (payload.account) ;
    });
    _defineProperty(this, "_onMessage", payload => {
      switch (payload.type) {
        case "display_uri":
          this.emit("display_uri", payload.data);
          break;
      }
    });
    _defineProperty(this, "_onSessionRequestSent", () => {
      this.emit("wc_session_request_sent");
    });
    this.projectId = options?.projectId || TW_WC_PROJECT_ID;
    this.qrcode = options?.qrcode === false ? false : true;
  }
  async getConnector() {
    if (!this.connector) {
      // import the connector dynamically
      const {
        WalletConnectConnector
      } = await import('../../../connectors/wallet-connect/dist/thirdweb-dev-wallets-evm-connectors-wallet-connect.browser.esm.js');
      this._walletConnectConnector = new WalletConnectConnector({
        chains: this.chains,
        options: {
          qrcode: this.qrcode,
          projectId: this.projectId,
          dappMetadata: this.dappMetadata,
          storage: this.walletStorage,
          qrModalOptions: this.options?.qrModalOptions
        }
      });
      this.connector = new WagmiAdapter(this._walletConnectConnector);
      this._provider = await this._walletConnectConnector.getProvider();
      this._setupListeners();
    }
    return this.connector;
  }
  _setupListeners() {
    if (!this._walletConnectConnector) {
      return;
    }
    this._removeListeners();
    this._walletConnectConnector.on("connect", this._onConnect);
    this._walletConnectConnector.on("disconnect", this._onDisconnect);
    this._walletConnectConnector.on("change", this._onChange);
    this._walletConnectConnector.on("message", this._onMessage);
    this._provider?.signer.client.on("session_request_sent", this._onSessionRequestSent);
  }
  _removeListeners() {
    if (!this._walletConnectConnector) {
      return;
    }
    this._walletConnectConnector.removeListener("connect", this._onConnect);
    this._walletConnectConnector.removeListener("disconnect", this._onDisconnect);
    this._walletConnectConnector.removeListener("change", this._onChange);
    this._walletConnectConnector.removeListener("message", this._onMessage);
    this._provider?.signer.client.removeListener("session_request_sent", this._onSessionRequestSent);
  }

  /**
   * Connect to the wallet using a QR code.
   * You can use this method to display a QR code. User can scan this QR code from the Wallet mobile app to connect to your dapp.
   *
   * @example
   * ```typescript
   * wallet.connectWithQrCode({
   *  chainId: 1,
   *  onQrCodeUri(qrCodeUri) {
   *    // render the QR code with `qrCodeUri`
   *  },
   *  onConnected(accountAddress)  {
   *    // update UI to show connected state
   *  },
   * })
   * ```
   *
   * @param options -
   * The options object contains the following properties/method:
   *
   * ### chainId (optional)
   * If provided, wallet will prompt the user to switch to the network with the given `chainId` after connecting.
   *
   * ### onQrCodeUri
   * A callback to get the QR code URI to display to the user.
   *
   * ### onConnected
   * A callback that is called when the user has connected their wallet using the QR code.
   */
  async connectWithQrCode(options) {
    await this.getConnector();
    const wcConnector = this._walletConnectConnector;
    if (!wcConnector) {
      throw new Error("WalletConnect connector not found");
    }
    wcConnector.showWalletConnectModal = false;
    const wcProvider = await wcConnector.getProvider();
    wcProvider.on("display_uri", uri => {
      options.onQrCodeUri(uri);
    });

    // trigger connect flow
    this.connect({
      chainId: options.chainId
    }).then(options.onConnected);
  }

  /**
   * @internal
   */
  async connectWithModal(options) {
    await this.getConnector();
    const wcConnector = this._walletConnectConnector;
    if (!wcConnector) {
      throw new Error("WalletConnect connector not found");
    }
    wcConnector.showWalletConnectModal = true;
    await wcConnector.initProvider();
    await this.connect({
      chainId: options?.chainId
    });
  }
}
_defineProperty(WalletConnect, "id", walletIds.walletConnect);
_defineProperty(WalletConnect, "meta", {
  name: "WalletConnect",
  iconURL: "ipfs://QmX58KPRaTC9JYZ7KriuBzeoEaV2P9eZcA3qbFnTHZazKw/wallet-connect.svg"
});

export { WalletConnect };
