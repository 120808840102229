import { useState, useEffect } from 'react';

const useFetchPolygonPrice = () => {
  const [maticPrice, setMaticPrice] = useState('Loading...');

  useEffect(() => {
    const fetchMaticPrice = async () => {
      const url = 'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd';

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data && data['matic-network'] && data['matic-network'].usd) {
          setMaticPrice(data['matic-network'].usd);
        } else {
          setMaticPrice('Unavailable');
        }
      } catch (error) {
        console.error('Error fetching MATIC price:', error);
        setMaticPrice('Error');
      }
    };

    fetchMaticPrice();
  }, []);

  return maticPrice;
};

export default useFetchPolygonPrice;
