import { useEffect, useState } from 'react';
import { useBalance, useAddress } from "@thirdweb-dev/react";

const useTokenBalance = () => {
  const address = useAddress();
  const { data, isLoading } = useBalance();
  const [balance, setBalance] = useState('');

  useEffect(() => {
    if (data) {
      // Format balance to show at least 6 decimals
      const formattedBalance = parseFloat(data.displayValue).toFixed(6);
      setBalance(formattedBalance);
    }
  }, [data]);

  return { balance, isLoading, address };
};

export default useTokenBalance;
