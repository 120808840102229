const axios = require('axios');

module.exports = async (req, res) => {
    if (req.method !== 'POST') {
        return res.status(405).end();
    }

    const { transactionHash, toAmount, chainId } = req.body;

    // Log the data being processed for verification
    console.log("Received for Zapier:", { transactionHash, toAmount, chainId });

    if (!transactionHash || !toAmount || !chainId) {
        return res.status(400).send('Missing data');
    }

    try {
        const zapierHookUrl = "https://hooks.zapier.com/hooks/catch/992787/30y4nn6/";
        const response = await axios.post(zapierHookUrl, { transactionHash, toAmount, chainId });
        res.status(200).send(response.data);
    } catch (error) {
        console.error("Error sending transaction data to Zapier:", error.message);
        res.status(500).send('Error sending data to Zapier');
    }
};

