import { ethers } from 'ethers';
import { useAddress } from '@thirdweb-dev/react';
import { useEffect, useState } from 'react';

// Your smart contract ABI and addresses
const contractAbi = [
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "owner",
        type: "address",
      },
      {
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];
const contractAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
const xgWalletAddress = "0x57A56BEaD1D0B65Ab5E3AcF528ECced8FbEb9378";

// API endpoint and your API key
const apiUrl = "https://prodp-api.xion.app/api/v2/single/payment";
const apiKey = process.env.APIKEY; 

export const useXionAPI = () => {
  const address = useAddress();
  const [contract, setContract] = useState(null);
  const [showProcessingPopup, setShowProcessingPopup] = useState(false);
  const [showFailedPopup, setShowFailedPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [transactionData, setTransactionData] = useState({ transactionHash: '', address: '' });


  useEffect(() => {
    if (address && typeof window.ethereum !== 'undefined') {
      // Dynamically import ethers to ensure it's loaded when needed
      import('ethers').then(({ ethers }) => {
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = web3Provider.getSigner();
        setContract(new ethers.Contract(contractAddress, contractAbi, signer));
      }).catch(error => console.error('Failed to load ethers:', error));
    }
  }, [address]);

  
  const products = [
    { name: "Mystery-Box", price: "199.99", currency: "usdt" },
  ];

  const approveAndPay = async (productIndex) => {
    if (!address || !contract) {
      console.error("Wallet not connected or contract not loaded");
      alert("Please connect your wallet.");
      return;
    }

    setShowProcessingPopup(true); // Show the processing popup
    try {
      const product = products[productIndex];
      const amount = ethers.utils.parseUnits(product.price, "ether");

      const allowance = await contract.allowance(address, xgWalletAddress);

      if (allowance.lt(amount)) {
        console.log("Approving USDT...");
        const tx = await contract.approve(xgWalletAddress, amount);
        await tx.wait();
        console.log("USDT approved");
      } else {
        console.log("USDT already approved");
      }

      console.log("Sending payment...");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
          Accept: "application/json",
        },
        body: JSON.stringify({
          referenceId: "Mystery-Box",
          productName: product.name,
          amount: parseFloat(product.price),
          currency: "usdt",
          buyerAddress: address,
        }),
      });

      const responseData = await response.json();

      setShowProcessingPopup(false); // Hide the processing popup

      if (responseData.status === "successful") {
        console.log("Payment successful:", responseData);
        setShowSuccessPopup(true); // Show the success popup
        setTransactionData({ transactionHash: responseData.transactionHash, address }); // Save transaction data
      } else {
        console.error("Payment failed:", responseData);
        setShowFailedPopup(true); // Show the failed popup
      }
    } catch (error) {
      setShowProcessingPopup(false); // Hide the processing popup
      setShowFailedPopup(true); // Show the failed popup
      console.error("Error in approveAndPay:", error);
    }
  };

  return { approveAndPay, showProcessingPopup, setShowProcessingPopup, showFailedPopup, setShowFailedPopup, showSuccessPopup, setShowSuccessPopup, transactionData };
};
