import React from "react";
import { useState } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import "./IPad.css";
import { BurgerMenu } from "../../../components/Burger/BurgerMenu";
import Footer from "../../../components/Footer";
import { useHistory } from 'react-router-dom'; // Import useHistory

function PrivacyPolicyI(props) {
  const {
    logo,
    line34,
    copyright,
  } = props;

  const history = useHistory();

  const handleMenuOptionSelect = (boxName) => {
    history.push('/', { selectedBox: boxName });
  };

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  return (
    <div className="privacy-i">
      <div className="privacy-policy screen" style={{ backgroundImage: `url("img/BlackBG.png")` }}>
        <div className="frame-3416-2">
          <div className="nav-3">
            <a href="/">
              <img className="logo-3" src={logo} alt="LOGO" />
            </a>
            <img className="img-wrapper" alt="Burger" src="/img/Burger.png" onClick={handleBurgerMenuClick} />
            <div className="my-custom-wrapper">
              <div className="my-custom-class">
                <ConnectWallet btnTitle="Connect Wallet" theme={"dark"} />
              </div>
            </div>
            <BurgerMenu isOpen={isBurgerMenuOpen} onClose={handleBurgerMenuClick} />
          </div>

          <div className="terms-content">
            <h1>Xion Finance Privacy Policy and Cookie Policy</h1>
            <p>Last Updated: 08 October 2023</p>

            <p>Introduction</p>
            <p>Welcome to Xion Finance. This Privacy Policy outlines how we collect, use, and protect your personal information while you use our decentralized application ("dApp"). By using our services, you agree to the terms of this Privacy Policy.</p>

            <p>Data Collection</p>
            <p>We may collect non-sensitive information such as IP addresses and web3 wallet addresses for the purpose of analytics and improving our services. We do not collect sensitive personal information like names, email addresses, or financial data unless explicitly provided by the user.</p>

            <p>Use of Data</p>
            <p>The data collected is used to improve the functionality of our dApp, analyze user behavior, and for security purposes. We do not sell or share your data with third parties for marketing purposes.</p>

            <p>Cookie Policy</p>
            <p>We use cookies to enhance your experience on our dApp. Cookies are small files that are stored on your device to help the dApp function properly and to understand how you interact with our service.</p>

            <p>Security</p>
            <p>We implement various security measures to protect your data. However, no method of data transmission or storage is 100% secure. Therefore, while we strive to protect your data, we cannot guarantee its absolute security.</p>

            <p>Third-Party Services</p>
            <p>Our dApp may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites.</p>

            <p>User Rights</p>
            <p>You have the right to access, correct, or delete your personal data stored by us. You can exercise these rights by contacting us at hello@xion.finance.</p>

            <p>Changes to Privacy Policy</p>
            <p>We reserve the right to update this Privacy Policy at any time. Changes will be effective immediately upon posting. Your continued use of the dApp signifies your acceptance of the updated Privacy Policy.</p>

            <p>Governing Law</p>
            <p>This Privacy Policy is governed by the laws of Singapore. Any disputes arising from this policy will be resolved in the jurisdiction of Singapore.</p>

            <p>Contact</p>
            <p>If you have any questions regarding this Privacy Policy, please contact us at hello@xion.finance.</p>
          </div>
          <Footer
            onMenuOptionSelect={handleMenuOptionSelect} />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyI;
